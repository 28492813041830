import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Slider from 'ui/slider/SliderPartners';
import './PartnersReviews.styl';

export default function PartnersReviews(props) {
	const {
		title,
		subtitle,
		slidesData,
	} = props;
	return (
		<section className="partners-reviews">
			<div className="partners-reviews__inner container">
				<Heading level="title-medium" className="partners-reviews__title" isCentered>
					{title}
				</Heading>
				<p className="partners-reviews__text">
					{subtitle}
				</p>
				<Slider slides={slidesData} />
			</div>
		</section>
	);
}
