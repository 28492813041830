import React from 'react';
import cx from 'classnames';
import 'modules/layouts/components/Pricing.styl';
import './Description.styl';

export default function Description(props) {
	const {description} = props;
	return (
		<div className="pricing-page-description">
			{description.paragraphs.map((paragraph) => (
				<React.Fragment key={paragraph.id}>
					<p
						className={cx('pricing-page-description__paragraph', {
							'pricing-page-description__paragraph_bold': paragraph.isBold,
						})}
					>
						{paragraph.text}
					</p>
					{paragraph.items && (
						<ul
							className={cx('pricing-page-description__list', {
								'pricing-page-description__list_with-two-column': paragraph.withTwoColumn,
							})}
						>
							{paragraph.items.map((item) => (
								<li className="pricing-page-description__item" key={item.id}>
									{item.text}
								</li>
							))}
						</ul>
					)}
				</React.Fragment>
			))}
		</div>
	);
}
