import React, {memo} from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import IconArrowLeft from './inline/arrow-left.svg';
import IconArrowRight from './inline/arrow-right.svg';
import {handlerPagination} from './helpers/base';
import {useSlider} from './hooks';
import './Slider.styl';
import './SliderReviews.styl';

const parentClasses = ['slider-pagination', 'slider-pagination_reviews'];
const itemClasses = ['slider-pagination-item', 'slider-pagination-item_reviews'];

function SliderReviews(props) {
	const {
		title,
		slidesData,
	} = props;
	const options = {
		selector: '.siema-reviews',
		loop: true,
		onChange: handlerPagination(itemClasses[0]),
	};
	const {
		onNext,
		onPrev,
	} = useSlider(options, [parentClasses, itemClasses]);

	return (
		<section className="slider-reviews">
			<Container>
				<Heading level="title-medium" isCentered>
					{title}
				</Heading>
				<div className="slider-wrap slider-wrap_reviews">
					<div className="slider slider_reviews">
						<div className="siema-reviews">
							{slidesData.map((item) => (
								<div className="slider-item-reviews" key={item.id}>
									<p className="slider-description slider-description_reviews">
										{item.text}
									</p>
									{item.name && (
										<Heading>
											{item.name}
										</Heading>
									)}
									{item.position && (
										<p className="slider-position">{item.position}</p>
									)}
								</div>
							))}
						</div>
						<button
							key="0"
							className="slider-button-reviews slider-button-reviews-prev"
							onClick={onPrev}
							type="button"
						>
							<IconArrowLeft />
						</button>
						<button
							key="1"
							className="slider-button-reviews slider-button-reviews-next"
							onClick={onNext}
							type="button"
						>
							<IconArrowRight />
						</button>
					</div>
				</div>
			</Container>
		</section>
	);
}

export default memo(SliderReviews);
