import React from 'react';
import {Link as NavLink} from 'gatsby';
import cx from 'classnames';
import 'modules/layouts/components/Pricing.styl';
import './Navigation.styl';

export default class Navigation extends React.Component {
	state = {
		active: false,
	};

	handleClick = () => {
		this.setState((prevState) => ({active: !prevState.active }));
	}

	render() {
		const {links} = this.props;
		return (
			<div className={cx(
				'pricing-page-menu-wrapper',
				{'pricing-page-menu-wrapper_active': this.state.active},
			)}
			>
				<nav className={cx(
					'pricing-page-menu',
					{'pricing-page-menu_active': this.state.active},
				)}
				>
					{links.map((link) => (
						<NavLink
							className={cx(
								'pricing-page-menu__link',
								{'pricing-page-menu__link_show': this.state.active},
							)}
							onClick={this.handleClick}
							key={link.id}
							to={link.src}
							activeClassName="pricing-page-menu__link_active"
						>
							{link.text}
						</NavLink>
					))}
				</nav>
			</div>
		);
	}
}
