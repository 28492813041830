import React from 'react';
import IconLineSecondLeft from '../inline/line-second-left.svg';
import IconLineSecondCenter from '../inline/line-second-center.svg';
import IconLineSecondRight from '../inline/line-second-right.svg';
import IconLineSecondMobile from '../inline/line-second-mobile.svg';
import './Common.styl';
import './LinesSecond.styl';

export default function LinesSecond(props) {
	const {activeClass} = props;
	return (
		<div className="line">
			<div className="line__second">
				<IconLineSecondLeft className={`line__second-svg ${activeClass}`} />
			</div>
			<div className="line__second line__second_center">
				<div className="line__text">Please scroll down</div>
				<IconLineSecondCenter className={`line__second-svg line__second-svg_center ${activeClass}`} />
			</div>
			<div className="line__second">
				<IconLineSecondRight className={`line__second-svg ${activeClass}`} />
			</div>
			<IconLineSecondMobile className={`hidden-md line__mobile ${activeClass}`} />
		</div>
	);
}
