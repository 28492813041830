import React from 'react';
import Link from 'ui/controls/Link/Link';
import srcIcon from '../../assets/signup-success.svg';

export default function MessageSuccessSignup(props) {
	const {firstName} = props;
	const {
		welcome,
		bodyMessages,
		footerMessages,
		aboutMessage,
	} = props.message;

	return (
		<div className="message-signup">
			<div className="message-signup-inner">
				<p className="message-signup__text">{welcome} {firstName},</p>
				<div className="message-body">
					{bodyMessages.map((item) => (
						<p className="message-signup__text" key={item.id}> {item.text}</p>
					))}
				</div>
				<div className="message-footer">
					{footerMessages.map((item) => (
						<p key={item.id}><i>{item.text}</i></p>
					))}
				</div>
				<p className="message-signup__about">
					{aboutMessage.text} <Link to={aboutMessage.linkTo} className="message-signup__link">{aboutMessage.link}</Link>
				</p>
			</div>
			<figure>
				<img src={srcIcon} className="message-signup__image_desktop" alt="Success message" />
			</figure>
			<div className="message-signup__image_mobile" />
		</div>
	);
}
