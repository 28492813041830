import React from 'react';
import Link from 'ui/controls/Link/Link';
import {CONTACT} from 'constants/url.constants';
import 'modules/layouts/components/Pricing.styl';

export default function Buttons() {
	const renderLink = (
		<div className="pricing-page-prices__box">
			<Link
				to={CONTACT}
				appearance="button"
				className="pricing-page-plans__link"
			>
				Free trial
			</Link>
		</div>
	);

	return (
		<div className="pricing-page-prices__row">
			<div className="pricing-page-prices__box" />
			{renderLink}
			{renderLink}
			{renderLink}
		</div>
	);
}
