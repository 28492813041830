import React from 'react';
import Box from 'ui/box/Box';
import Heading from 'ui/typography/Heading/Heading';
import './Tiles.styl';

export default function Tiles(props) {
	const {
		title,
		logos,
	} = props;
	return (
		<Box>
			<div className="tiles container">
				<Heading level="title-medium" isCentered>
					{title}
				</Heading>
				<ul className="tiles-list">
					{logos.map(({id, logo: Logo}) => (
						<li className="tiles-list__item" key={id}>
							<Logo className="tiles-list__logo" />
						</li>
					))}
				</ul>
			</div>
		</Box>
	);
}
