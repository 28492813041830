import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';

export default function ShowcaseList(props) {
	const {
		items,
		marksColor,
		withTopHeading,
		isInversed,
	} = props.data;
	return (
		<ul className="showcase-list">
			{items.map((item) => (
				<li
					className={cx('showcase-list__item', `showcase-list__item_${marksColor}`, {
						'showcase-list__item_indented': withTopHeading,
					})}
					key={item.id}
				>
					{!withTopHeading
						? (<Heading level="title-small" color={isInversed ? 'white' : ''}>{item.text}</Heading>)
						: (item.text)}
				</li>
			))}
		</ul>
	);
}
