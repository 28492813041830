import React from 'react';
import Link from 'ui/controls/Link/Link';
import * as URL from 'constants/url.constants';
import IconDownload from '../inline/common/download.svg';
import './ComicsFooter.styl';

function ComicsFooter(props) {
	return (
		<>
			<Link isExternal to={props.pdf} download className="comics-download-link">
				<IconDownload />
			</Link>
			<Link to={URL.CONTACT} appearance="button-primary">
				Contact us
			</Link>
		</>
	);
}

export default ComicsFooter;
