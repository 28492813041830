import {isBrowser} from 'helpers/isBrowser';

export const createFacebookScripts = () => {
	if (!isBrowser() || window.location.hostname === 'localhost') return;
	const scriptInline = document.createElement('script');
	const noscript = document.createElement('noscript');
	const id = process.env.GATSBY_FACEBOOK_PIXEL_ID;

	document.head.prepend(scriptInline);
	scriptInline.innerHTML = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${id}'); fbq('track', 'PageView');`;
	document.body.prepend(noscript);
	noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"/>`;
};
