import React from 'react';
import {Field} from 'formik';
import {useStaticQuery, graphql} from 'gatsby';
import cx from 'classnames';
import Button from 'ui/controls/Button/Button';
import {LazyLoadRecaptcha} from './LazyLoadRecaptcha';
import {LabelCheckboxCustom} from './Fields/LabelCheckboxCustom';
import FormGroup from './Fields/FormGroupSignUp';
import FieldCaptcha from './Fields/FieldCaptcha';
import FieldText from './Fields/FieldText';
import FieldSelect from './Fields/FieldSelect';
import FieldCheckbox from './Fields/FieldCheckbox';
import {recaptchaConfig} from '../constants/recaptcha';
import {options} from '../mocks/industries';
import './FormSignUp.styl';

export default function FormSignUp(props) {
	const {label} = useStaticQuery(graphql`
		query {
			label: labelCheckboxSignupJson{
				linkPrivacy {
					to
					text
				}
				linkTerms {
					to
					text
				}
			}
		}
	`);
	const {
		handleSubmit,
		isSubmitting,
		isValid,
		isValidating,
		dirty,
	} = props;

	return (
		<form onSubmit={handleSubmit} className="form-signup__container">
			<FormGroup>
				<Field
					type="text"
					name="first_name"
					component={FieldText}
					label="First name"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					type="text"
					name="last_name"
					component={FieldText}
					label="Last name"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					type="email"
					name="email"
					component={FieldText}
					label="Email"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					type="text"
					name="company"
					component={FieldText}
					label="Company"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					type="text"
					name="website"
					component={FieldText}
					label="Website"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					name="industry"
					component={FieldSelect}
					options={options}
					label="Industry"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					type="password"
					name="password"
					component={FieldText}
					label="Password"
				/>
			</FormGroup>
			<FormGroup>
				<Field
					type="password"
					name="confirmPassword"
					component={FieldText}
					label="Confirm password"
				/>
			</FormGroup>
			<FormGroup appearance="checkbox">
				<Field
					type="checkbox"
					name="checkbox"
					component={FieldCheckbox}
					label={<LabelCheckboxCustom {...label} />}
				/>
			</FormGroup>
			<FormGroup appearance="captcha">
				<LazyLoadRecaptcha>
					<Field
						name="g-recaptcha-response"
						component={FieldCaptcha}
						config={recaptchaConfig}
					/>
				</LazyLoadRecaptcha>
			</FormGroup>
			<FormGroup appearance="button">
				<Button
					type="submit"
					className={cx({'has-loading': isSubmitting})}
					isDisabled={isSubmitting || isValidating || !isValid || !dirty}
					isSmall
				>
					Create account
				</Button>
			</FormGroup>
		</form>
	);
}
