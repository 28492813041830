import React from 'react';
import ReactScroll from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.min.css';

export const CustomScroll = (props) => {
	const {
		children,
		...rest
	} = props;

	return (
		<ReactScroll {...rest}>
			{children}
		</ReactScroll>
	);
};
