/* eslint-disable */
import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import 'ui/stylus/base/hidden.styl';
import PaymentCardImage from '../inline/covery-payment.svg';
import PaymentCardImageMobile from '../inline/covery-payment-mobile.svg';
import CubeLineFirst from '../inline/cube-line_first.svg';
import CubeLineSecond from '../inline/cube-line_second.svg';
import CubeLineForth from '../inline/cube-line_forth.svg';
import MobileLines from '../inline/mobile-lines.svg';
import {paymentData} from '../trustchain.fixtures';
import './PaymentScheme.styl';

export default class PaymentScheme extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chainAnimation: false,
			activeClass: 'active',
			prevProps: props,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({nextProps});
	}

	showAnimation = () => {
		if (!this.state.chainAnimation) {
			this.setState({chainAnimation: true});
		}

		if (this.state.nextProps && this.state.nextProps !== this.state.prevProps) {
			this.setState({activeClass: ''}, () => {
				this.setState((prevState) => ({
					activeClass: 'active',
					prevProps: prevState.nextProps,
				}));
			});
		}
	}

	render() {
		const {selectedItem} = this.props;
		const {chainAnimation, activeClass} = this.state;

		return (
			<section className={`payment-scheme-wrapper ${chainAnimation ? activeClass : ''}`}>
				<div className="payment-scheme">
					<Waypoint onEnter={this.showAnimation} />
					<div className="payment-scheme__title">
						covery
					</div>
					<div className={`card-image-wrapper ${chainAnimation ? activeClass : ''}`}>
						<span className={`card-text ${chainAnimation ? activeClass : ''}`}>
							Payment for classical music subscription
						</span>
						<PaymentCardImage className="card-image" />
						<PaymentCardImageMobile className="hidden-lg card-image-mobile" />
					</div>
					{paymentData.map((e) => (
						<div className={`cube cube_${e.class} ${chainAnimation ? activeClass : ''} `} key={e.id}>
							<div className={`cube_${e.class}-img cube-img ${selectedItem}`} />
							<div
								className={`
									scheme-text-container ${e.description === '' ? 'scheme-text-container_vertical-align' : ''}
								`}
							>
								<div className="scheme-text scheme-text_bold">{e.title}</div>
								<div className="scheme-text">{e.description}</div>
							</div>
						</div>
					))}
					<div className="mobile-lines-wrapper hidden-lg">
						<MobileLines />
					</div>
					<CubeLineFirst className="cube-line_first" />
					<CubeLineSecond className="cube-line_second" />
					<CubeLineFirst className="cube-line_third" />
				</div>
				<CubeLineForth className="cube-line_forth" />
			</section>
		);
	}
}
