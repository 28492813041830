import React, {useState, useCallback} from 'react';
import {Field} from 'formik';
import Button from 'ui/controls/Button/Button';
import cx from 'classnames';
import {LazyLoadRecaptcha} from './LazyLoadRecaptcha';
import FieldCaptcha from './Fields/FieldCaptcha';
import FieldTextarea from './Fields/FieldTextarea';
import FieldText from './Fields/FieldText';
import FormGroup from './Fields/FormGroupConsult';
import FieldCheckboxCustom from './Fields/FieldCheckboxCustom';
import LabelCheckbox from './Fields/LabelCheckbox';
import {recaptchaConfig} from '../constants/recaptcha';
import './FormConsult.styl';

export default function FormConsult(props) {
	const [shouldDisplayInput, setShouldDisplayInput] = useState(false);
	const {
		handleSubmit,
		dirty,
		isSubmitting,
		isValid,
		isValidating,
	} = props;

	const onChangeHandler = useCallback(() => {
		setShouldDisplayInput(!shouldDisplayInput);
	}, [shouldDisplayInput]);

	return (
		<form onSubmit={handleSubmit} className="form-consult">
			<div className="form-inner-consult">
				<FormGroup>
					<Field
						type="text"
						name="name"
						component={FieldText}
						label="Your name"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="email"
						name="email"
						component={FieldText}
						label="Your email"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="text"
						name="phone"
						component={FieldText}
						label="Your phone"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="text"
						name="website"
						component={FieldText}
						label="Product website"
					/>
				</FormGroup>
				<FormGroup appearance="wide">
					<Field
						type="textarea"
						name="message"
						component={FieldTextarea}
						label="How can we help you?"
					/>
				</FormGroup>
				<FormGroup appearance="wide">
					<Field
						type="checkbox"
						name="checkbox"
						component={FieldCheckboxCustom}
						label={<LabelCheckbox text="I have a promo code" />}
						onChange={onChangeHandler}
						checked={shouldDisplayInput}
					/>
				</FormGroup>
				{shouldDisplayInput && (
					<FormGroup appearance="wide">
						<Field
							type="text"
							name="promoCode"
							placeholder="Enter your promo code"
							component={FieldText}
						/>
					</FormGroup>
				)}
				<FormGroup appearance="captcha">
					<LazyLoadRecaptcha>
						<Field
							name="g-recaptcha-response"
							component={FieldCaptcha}
							config={recaptchaConfig}
						/>
					</LazyLoadRecaptcha>
				</FormGroup>
				<FormGroup appearance="button">
					<Button
						type="submit"
						className={cx({'has-loading': isSubmitting})}
						isDisabled={isSubmitting || isValidating || !isValid || !dirty}
					>
						Get the details
					</Button>
				</FormGroup>
			</div>
		</form>
	);
}
