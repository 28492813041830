import React, { PureComponent } from 'react';
import IconMessageBubble from '../../inline/icon-text-cloud.svg';
import './message.styl';

export default class MessageBubble extends PureComponent {
	render() {
		const {name, error, message} = this.props;
		return (
			<div className="message message-bubble">
				<IconMessageBubble className={`message-bubble-cloud ${error ? 'has-error' : ''}`} />
				{error
					? (
						<span className="message-bubble-error">
							{message}
						</span>
					)
					: (
						<span className="message-bubble-greeting">
							Hi {`${name}, `}
						</span>
					)
				}
			</div>
		);
	}
}
