import React from 'react';
import './List.styl';

export function List({isSettingsOpen, cookieList}) {
	const {
		list,
		listSecond,
	} = cookieList;

	const items = isSettingsOpen ? listSecond : list;
	return (
		<ul className="popup-cookie-list">
			{items.map(({id, text}) => (
				<li
					className="popup-cookie-list__item"
					key={id}
					dangerouslySetInnerHTML={{__html: text}}
				/>
			))}
		</ul>
	);
}
