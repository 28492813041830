import React from 'react';
import cx from 'classnames';
import Link from 'ui/controls/Link/Link';
import IconLogoBig from './inline/covery-logo-big.svg';
import './Logo.styl';

export default function Logo(props) {
	const {url, isExternal, isBlank, size} = props;
	return (
		<Link
			className="logo"
			to={url}
			isExternal={isExternal}
			isBlank={isBlank}
		>
			<IconLogoBig className={cx('logo__icon', {logo__icon_large: size === 'large'})} />
		</Link>
	);
}
