import React from 'react';
import './Burger.styl';

export default function Burger(props) {
	const {eventHandler, toggle} = props;
	return (
		<>
			<button
				onClick={eventHandler}
				className={toggle ? 'burger' : 'burger burger_active'}
				type="button"
			>
				<span className="burger__line" />
				<span className="burger__line" />
				<span className="burger__line" />
			</button>
		</>
	);
}
