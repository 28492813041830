import React from 'react';
import cx from 'classnames';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import ComicsQuestion from './ComicsQuestion';
import IconLogo from '../inline/common/covery-logo.svg';
import IconLogoBig from '../inline/common/covery-logo-big.svg';
import './ComicsContact.styl';

export default function ComicsContact(props) {
	const {texts, url, qr: QrCode, type} = props;
	return (
		<div
			className={cx('comics-contacts', {
				'comics-contacts_type_zamazon': type === 'zamazon',
				'comics-contacts_type_villain': type === 'villain',
				'comics-contacts_type_paytio': type === 'paytio',
				'comics-contacts_type_casino': type === 'casino',
			})}
		>
			{type === 'casino' && (
				<figure className="comics-contacts__logo">
					<IconLogo className="comics-contacts__icon" />
				</figure>
			)}
			<div
				className={cx('comics-contacts__heading', {
					'comics-contacts__heading_blue': type === 'casino',
				})}
			>
				<Heading
					level="title-medium"
					color={type === 'casino' ? 'blue' : 'white'}
				>
					Covery
				</Heading>
				{texts.map((item) => (
					<p key={item.id} className="comics-contacts__text">
						{item.text}
					</p>
				))}
				<ComicsQuestion />
			</div>
			<div className="comics-contacts__footer">
				<figure className="comics-contacts__figure-qr">
					<QrCode className="comics-contacts__icon-qr" />
				</figure>
				<figure className="comics-contacts__figure-logo">
					<IconLogoBig className="comics-contacts__icon-logo" />
				</figure>
				<Link className="comics-contacts__link" to={url}>covery.ai</Link>
			</div>
		</div>
	);
}
