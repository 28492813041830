import React from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import {PRICING} from 'constants/anchor.constants';
import './Pricing.styl';

export default function Pricing(props) {
	const {
		title,
		subtitle,
	} = props;
	return (
		<div
			className="pricing"
			id={PRICING}
		>
			<Container className="pricing__inner">
				<Heading
					level="title-medium"
					withIndented
				>
					{title}
				</Heading>
				{subtitle && (
					<p className="pricing__subtitle">
						{subtitle}
					</p>
				)}
			</Container>
		</div>
	);
}
