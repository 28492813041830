import React, {memo} from 'react';
import Button from 'ui/controls/Button/Button';
import Heading from 'ui/typography/Heading/Heading';
import IconArrowLeft from './inline/arrow-left.svg';
import IconArrowRight from './inline/arrow-right.svg';
import {handlerPagination} from './helpers/base';
import {useSlider} from './hooks';
import './Slider.styl';
import './SliderPartners.styl';

const parentClasses = ['main-slider-pagination', 'main-slider-pagination_partners'];
const itemClasses = ['main-slider-pagination__item', 'main-slider-pagination__item_partners'];

function SliderPartners({slides}) {
	const options = {
		selector: '.siema-partners',
		loop: true,
		onChange: handlerPagination(itemClasses[0]),
	};
	const {
		onNext,
		onPrev,
	} = useSlider(options, [parentClasses, itemClasses]);

	return (
		<div className="main-slider main-slider_partners">
			<div className="siema-partners">
				{slides.map((slide) => (
					<div className="main-slider-item main-slider-item_partners" key={slide.id}>
						<p className="main-slider-item__description main-slider-item__description_with-quotes">
							{slide.text}
						</p>
						<Heading level="title-small">
							{slide.name}
						</Heading>
						<p>{slide.position}</p>
					</div>
				))}
			</div>
			<Button
				className="main-slider-button main-slider-button-prev main-slider-button_partners"
				onClick={onPrev}
				type="button"
			>
				<IconArrowLeft className="arrow-left arrow-left_partners" />
			</Button>
			<Button
				className="main-slider-button main-slider-button-next main-slider-button_partners"
				onClick={onNext}
				type="button"
			>
				<IconArrowRight className="arrow-right arrow-right_partners" />
			</Button>
		</div>
	);
}

export default memo(SliderPartners);
