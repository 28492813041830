import React from 'react';
import cx from 'classnames';
import './Box.styl';

export default function Box(props) {
	const {modificator} = props;
	return (
		<section className={cx('box', {[`box_${modificator}`]: modificator})}>
			{props.children}
		</section>
	);
}
