import AboutFraud from './inline/about-fraud.svg';
import BusinessWire from './inline/business-wire.svg';
import Gdi from './inline/gdi.svg';
import Head from './inline/head.svg';
import IbsIntelligence from './inline/ibs-intelligence.svg';
import IgamingBusiness from './inline/igaming-business.svg';
import Paypers from './inline/paypers.svg';
import Telegraph from './inline/the-telegraph.svg';

export const homePageTiles = {
	title: 'Featured in',
	logos: [
		{
			id: 0,
			logo: Telegraph,
		}, {
			id: 1,
			logo: IgamingBusiness,
		}, {
			id: 2,
			logo: AboutFraud,
		}, {
			id: 3,
			logo: Gdi,
		}, {
			id: 4,
			logo: BusinessWire,
		}, {
			id: 5,
			logo: Paypers,
		}, {
			id: 6,
			logo: IbsIntelligence,
		}, {
			id: 7,
			logo: Head,
		},
	],
};
