import {isBrowser} from 'helpers/isBrowser';

export function handleCoveryFpKey(key) {
	if (!isBrowser()) return;

	window.deviceFingerprint = key;

	const {sessionStorage} = window;
	if (!sessionStorage.getItem('deviceFingerprint') && window.deviceFingerprint) {
		sessionStorage.setItem('deviceFingerprint', key);
	}
}

export function loadFingerprint() {
	if (!isBrowser()) return;

	const coveryId = 'covery';
	const coveryScript = document.getElementById(coveryId);
	const {body} = document;
	const script = document.createElement('script');
	script.setAttribute('src', 'https://covery.ai/js/fp.js');
	script.setAttribute('id', coveryId);

	if (coveryScript) {
		body.removeChild(coveryScript);
	}
	body.appendChild(script);
}
