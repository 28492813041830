import React from 'react';
import './pageUp.styl';

export default function Button(props) {
	const {className, text, onClick} = props;
	return (
		<button type="button" className={className} onClick={onClick}>
			{text}
		</button>
	);
}
