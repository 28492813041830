import React from 'react';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {FooterMenu} from 'modules/menu/components/FooterMenu';
import {SocialMenu} from 'modules/menu/components/SocialMenu';
import {Container} from 'ui/container/Container';
import Link from 'ui/controls/Link/Link';
import Copyright from 'ui/common/Copyright/Copyright';
import Logo from 'ui/common/Logo/Logo';
import './Footer.styl';

export function Footer({menu}) {
	const {
		homeLink,
		link,
		socialLinks,
		menuItems,
	} = menu;

	return (
		<footer className="footer">
			<Container className="footer__inner">
				<div className="footer__item">
					<div className="footer__logo">
						<ErrorBoundary>
							<Logo url={homeLink} size="large" />
						</ErrorBoundary>
					</div>
					<Link className="footer__link" appearance={link.appearance} to={link.to}>
						{link.text}
					</Link>
					<div className="footer__social">
						<ErrorBoundary>
							<SocialMenu links={socialLinks} />
						</ErrorBoundary>
					</div>
					<div className="footer__copyright">
						<ErrorBoundary>
							<Copyright />
						</ErrorBoundary>
					</div>
				</div>
				<div className="footer__item">
					<FooterMenu item={menuItems} />
				</div>
			</Container>
		</footer>
	);
}
