import * as URL from 'constants/url.constants';
import Casino01 from '../inline/casino/casino-01.svg';
import Casino02 from '../inline/casino/casino-02.svg';
import Casino03 from '../inline/casino/casino-03.svg';
import Casino04 from '../inline/casino/casino-04.svg';
import Casino05 from '../inline/casino/casino-05.svg';
import Casino06 from '../inline/casino/casino-06.svg';
import Casino07 from '../inline/casino/casino-07.svg';
import Casino08 from '../inline/casino/casino-08.svg';
import Casino09 from '../inline/casino/casino-09.svg';
import Casino10 from '../inline/casino/casino-10.svg';
import Casino11 from '../inline/casino/casino-11.svg';
import CasinoQr from '../inline/casino/casino-qr.svg';

export const pages = [
	{id: 1, page: Casino01},
	{id: 2, page: Casino02},
	{id: 3, page: Casino03},
	{id: 4, page: Casino04},
	{id: 5, page: Casino05},
	{id: 6, page: Casino06},
	{id: 7, page: Casino07},
	{id: 8, page: Casino08},
	{id: 9, page: Casino09},
	{id: 10, page: Casino10},
	{id: 11, page: Casino11},
];

export const contact = {
	qr: CasinoQr,
	url: URL.HOME,
	type: 'casino',
	texts: [
		{id: 0,
			text: 'Prevent Fraud and Ensure Responsible Gaming Increase your revenue and implement '
				+ 'the best practices of responsible gaming with our extensive functionality.',
		},
	],
};

export const introContent = {
	title: 'iGaming Edition - King`s Casino',
	subtitle: {
		text: 'King`s Casino is breaking bad. It seems that the old methods of risk assessment and'
			+ 'fraud prevention no longer work. iGaming world definitely seeks a hero to stop Frodus'
			+ 'and protect casino clients. Here comes he...',
		color: 'blue',
	},
	isCentered: true,
};
