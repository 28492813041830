import React, {cloneElement} from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import './solutions.styl';

export default function Solutions(props) {
	const {
		title,
		subtitle,
		items,
		children,
	} = props;
	return (
		<section className="solutions">
			<Container className="solutions__inner">
				<div className="solutions__heading">
					<Heading level="title-medium" color="white">
						{title}
					</Heading>
					<p className="solutions__subtitle">{subtitle}</p>
				</div>
				<div className="solutions__content">
					<ul className="solutions-list">
						{items.map(({id, description, image}) => (
							<li className="solutions-list__item" key={id}>
								<div className="solutions__box">
									<img
										src={image.source.publicURL}
										alt={description}
										className="solutions__icon"
									/>
									<p className="solutions-list__text">{description}</p>
								</div>
							</li>
						))}
					</ul>
				</div>
			</Container>
			{cloneElement(children, {className: 'solutions__image'})}
		</section>
	);
}
