import IconLineLeft from './inline/line-left.svg';
import IconLineLeftMobile from './inline/line-left-mobile.svg';
import IconLineCenter from './inline/line-center.svg';
import IconLineCenterMobile from './inline/line-center-mobile.svg';
import IconLineRight from './inline/line-right.svg';
import IconLineRightMobile from './inline/line-right-mobile.svg';

export const trustData = {
	subtitle: 'The collaboration inspired by blockchain for the age of growing data breaches',
	text: 'Hi! Please choose one of the reputations',
	email: 'pirs1923@armyspy.com',
	question: 'What do you think of {{email}} paying for classical music subscription?',
	buttons: [{
		id: 0,
		name: 'Trusted',
		class: 'green',
		value: 'trusted',
		position: 'left',
	}, {
		id: 1,
		name: 'Suspicious',
		class: 'yellow',
		value: 'suspicious',
		position: 'center',
	}, {
		id: 2,
		name: 'Untrusted',
		class: 'red',
		value: 'untrusted',
		position: 'right',
	}],
};

const baseClassVisible = 'line__first visible-md';
const baseClassHidden = 'line__first hidden-md';

export const linesData = [
	{
		id: 0,
		icon: IconLineLeft,
		type: 'trusted',
		height: 382,
		width: 91,
		className: `${baseClassVisible} line__first_desktop_left`,
	},
	{
		id: 1,
		icon: IconLineLeftMobile,
		type: 'trusted',
		className: `${baseClassHidden} line__first_mobile_left`,
	},
	{
		id: 2,
		icon: IconLineCenter,
		type: 'suspicious',
		height: 382,
		width: 255,
		className: `${baseClassVisible} line__first_desktop_center`,
	},
	{
		id: 3,
		icon: IconLineCenterMobile,
		type: 'suspicious',
		className: `${baseClassHidden} line__first_mobile_center`,
	},
	{
		id: 4,
		icon: IconLineRight,
		type: 'untrusted',
		height: 382,
		width: 424,
		className: `${baseClassVisible} line__first_desktop_right`,
	},
	{
		id: 5,
		icon: IconLineRightMobile,
		type: 'untrusted',
		className: `${baseClassHidden} line__first_mobile_right`,
	},
];

export const paymentData = [
	{
		id: 0,
		title: 'Your, industry and global reputations of pirs1923@armyspy.com',
		description: 'Smart leverage of reputation knowledge collected from all the parties of Covery for more precise decisions',
		class: 'first',
	}, {
		id: 1,
		title: 'Implemented risk logic',
		description: 'Flexible integration of Trustchain with other components does not require additional developments and is ready for new achievements',
		class: 'second',
	}, {
		id: 2,
		title: 'Machine learning',
		description: '',
		class: 'third',
	}, {
		id: 3,
		title: 'Decision-making agent',
		description: '',
		class: 'forth',
	},
];
