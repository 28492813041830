import React from 'react';
import IconSignupHero from '../inline/signup-desktop-hero.svg';
import './LayoutSignUp.styl';

export default function LayoutSignUp(props) {
	const {
		title,
		text,
		link,
	} = props.form;

	return (
		<div className="layout-signup">
			<div className="layout-signup__image-mobile" />
			<IconSignupHero className="layout-signup__image-desktop" />
			<div className="layout-signup__inner">
				<h2 className="layout-signup__title">
					{title}
				</h2>
				{props.children}
				<p className="layout-signup__login">
					{text} &nbsp;
					<a
						href={link.to}
						className="layout-signup__link"
						target="_blank"
						rel="noopener noreferrer"
					>
						{link.text}
					</a>
				</p>
			</div>
		</div>
	);
}
