import React from 'react';
import {Field} from 'formik';
import cx from 'classnames';
import Button from 'ui/controls/Button/Button';
import FormGroup from './Fields/FormGroup';
import FieldCaptcha from './Fields/FieldCaptcha';
import {recaptchaConfig} from '../constants/recaptcha';
import './FormDeviceScreening.styl';
import {LoadRecaptcha} from './LoadRecaptcha';

export default function FormDeviceScreening(props) {
	const {
		handleSubmit,
		isSubmitting,
		isValid,
		isValidating,
		dirty,
	} = props;

	return (
		<form
			onSubmit={handleSubmit}
			className="form-device-screening-container"
		>
			<FormGroup appearance="captcha-device-screening">
				<LoadRecaptcha>
					<Field
						name="reCaptcha"
						component={FieldCaptcha}
						config={recaptchaConfig}
					/>
				</LoadRecaptcha>
			</FormGroup>
			<FormGroup appearance="device-screening-button">
				<Button
					type="submit"
					className={cx({'has-loading': isSubmitting})}
					isDisabled={isSubmitting || isValidating || !isValid || !dirty}
					isSmall
				>
					Check device screening
				</Button>
			</FormGroup>
		</form>
	);
}
