import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import EvilMan from '../inline/evil-man.svg';
import KindMan from '../inline/kind-man.svg';
import './Scale.styl';

export default function Scale({title, description, subtitle, type, items}) {
	return (
		<div className="scale">
			<div className="scale__inner container">
				<Heading
					level="title-medium"
					className={`scale__title scale__title_${type}`}
				>
					{title}
				</Heading>
				<p className={`scale__subtitle scale__subtitle_${type}`}>
					{subtitle}
				</p>
				<figure className="scale-image-box">
					<KindMan className={`scale-image-box__icon scale-image-box__icon_${type}`} />
					<EvilMan className={`scale-image-box__icon scale-image-box__icon_${type}`} />
				</figure>
				<div className={`scale-line scale-line_${type}`}>
					{items.map(({id, number}) => (
						<span className="scale-line__number" key={id}>{number}</span>
					))}
				</div>
				<p className={`scale__description scale__description_${type}`}>
					{description}
				</p>
			</div>
		</div>
	);
}
