import React from 'react';
import {Container} from 'ui/container/Container';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import {pricingIntro} from 'modules/intro/intro.mocks';
import {BaseLayout} from './Base';
import './Pricing.styl';

export default function PricingLayout(props) {
	return (
		<BaseLayout>
			<IntroWrapper {...pricingIntro} />
			<main className="pricing-page pricing-page_grey">
				<Container className="pricing-page__inner pricing-page__inner_blue">
					{props.children}
				</Container>
			</main>
		</BaseLayout>
	);
}
