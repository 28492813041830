import React from 'react';
import cx from 'classnames';
import './ResponseDescription.styl';

export default function ResponseDescription({children, isWithIndented}) {
	return (
		<p className={cx('form-trustchain__description', {
			'form-trustchain__description_indented': isWithIndented,
			})}
		>
			<b>{children}</b>
		</p>
	);
}
