import React, {memo} from 'react';
import Link from 'ui/controls/Link/Link';
import IconArrowLeft from './inline/arrow-left.svg';
import IconArrowRight from './inline/arrow-right.svg';
import IconSmartphone from './inline/smartphone.svg';
import {handlerPagination} from './helpers/kyc';
import {useSlider} from './hooks';
import './Slider.styl';
import './SliderDF.styl';

const parentClasses = ['slider-pagination'];
const itemClasses = ['slider-pagination-item'];

function SliderDF({slides, header}) {
	const options = {
		selector: '.siema-manual-kyc',
		loop: true,
		onChange: handlerPagination(
			'.slider-pagination-item',
			'.smartphone',
			'.smartphone .svg-text',
			'.svg-item-animate',
		),
	};
	const {
		onNext,
		onPrev,
	} = useSlider(options, [parentClasses, itemClasses]);

	return (
		<div className="slider-wrap slider-wrap-manual-kyc">
			<div className="slider slider-manual-kyc">
				<h3 className="slider-header">{header}</h3>
				<div className="siema-manual-kyc">
					{slides.map((item) => (
						<div className="slider-item" key={item.id}>
							<h3 className="slider-title">{item.title}</h3>
							<h4 className="slider-subtitle">{item.subtitle}</h4>
							<p className="slider-footnote">{item.footnote}</p>
						</div>
					))}
				</div>
				<button
					type="button"
					className="slider-button slider-button-prev"
					onClick={onPrev}
				>
					<IconArrowLeft />
				</button>
				<button
					type="button"
					className="slider-button slider-button-next"
					onClick={onNext}
				>
					<IconArrowRight />
				</button>
			</div>
			<IconSmartphone className="smartphone" />
		</div>
	);
}

export default memo(SliderDF);
