import React from 'react';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import VillainContainer from 'modules/comics/containers/VillainContainer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {introContent} from 'modules/comics/mocks/villain';
import {Page} from 'modules/layouts/components/Page';

export default function VillainEdition() {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo title="Comic Book - Villain Edition" />
			</ErrorBoundary>
			<Page>
				<ErrorBoundary>
					<ScrollToTop />
				</ErrorBoundary>
				<ErrorBoundary>
					<IntroWrapper {...introContent} />
				</ErrorBoundary>
				<ErrorBoundary>
					<VillainContainer />
				</ErrorBoundary>
			</Page>
		</BaseLayout>
	);
}
