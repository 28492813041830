import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Cards from 'modules/cards/components/Cards';
import Pricing from 'modules/pricing/components/Pricing';
import Box from 'ui/box/Box';
import BoxDarkBlue from 'ui/box/color/DarkBlue';
import {Form} from 'modules/forms';
import FormDemo from 'modules/forms/containers/FormDemo';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Faq from 'modules/faq/components/Faq';
import {useDeviceFingerprint} from 'hooks/useDeviceFingerprint';
import {ipScreening} from 'modules/forms/constants/demoFlow';

export default function IpScreening({data, location}) {
	useDeviceFingerprint();

	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.seo.title}
					description={data?.seo.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<ErrorBoundary>
				<IntroWrapper {...data?.intro} />
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<FormDemo
						type={ipScreening}
						heading={data?.formHeading}
						{...data?.check}
					/>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.features} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.featuresSecond} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.featuresThird} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Cards {...data?.cards} />
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.pricing} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
			<BoxDarkBlue>
				<Faq {...data?.faq} />
			</BoxDarkBlue>
		</BaseLayout>
	);
}

export const emiDetails = graphql`
	query {
		intro: ipScreeningIntroJson {
			title
			linkTo
			linkAppearance
			link
			isCentered
			subtitle {
				color
				text
			}
		}
		formHeading: ipScreeningFormJson {
			title
		}
		features: ipScreeningFeaturesJson {
			title
			postfix
			boxModificator
			items {
				image {
					source {
						publicURL
					}
					alt
				}
				id
				title
				description
			}
		}
		check: ipScreeningCheckJson {
			infoMessage
			limitMessage
		}
		featuresSecond: ipScreeningFeaturesSecondJson {
			title
			link
			linkTo
			linkAppearance
		}
		featuresThird: ipScreeningFeaturesThirdJson {
			title
			boxModificator
			isInversed
			items {
				image {
					source {
						publicURL
					}
					alt
				}
				id
				title
				description
			}
		}
		cards: ipScreeningCardsJson {
			title
			type
			items {
				id
				description
				title
				iconSrc {
					publicURL
				}
				link {
					text
					to
					appearance
				}
			}
		}
		pricing: ipScreeningPricingJson {
			title
		}
		seo: ipScreeningSeoJson {
			title
			description
		}
		faq: ipScreeningFaqJson {
			title
			items {
				title
				paragraphs {
					description
					priority
				}
			}
		}
	}
`;
