import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import FeaturesItems from './FeaturesItems';
import FeaturesTitles from './FeaturesTitles';
import Buttons from './Buttons';
import 'modules/layouts/components/Pricing.styl';
import './Features.styl';

export function Features(props) {
	const breakpoints = useBreakpoint();
	const {features} = props;

	return (
		<>
			{breakpoints.md && (
				<section className="pricing-page-container">
					<Heading color="blue" className="pricing-page-container__title">
						{features.title}
					</Heading>
					<div className="pricing-page-prices">
						{features.options.map((option) => (
							<div className="pricing-page-prices__row" key={option.id}>
								<FeaturesTitles title={option} />
								<FeaturesItems item={option} />
							</div>
						))}
						<Buttons />
					</div>
				</section>
			)}
		</>
	);
}
