import React, {cloneElement, Children} from 'react';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import cx from 'classnames';
import Intro from './Intro';
import './IntroWrapper.styl';

export const IntroWrapper = (props) => {
	const {
		type,
		children,
		...data
	} = props;

	return (
		<section className={cx('intro-wrapper', {[`intro-wrapper_${type}`]: type})}>
			<ErrorBoundary>
				<Intro data={data} type={type}>
					{Children.count(children) === 1
						? cloneElement(children, {className: 'intro__image'})
						: children
					}
				</Intro>
			</ErrorBoundary>
		</section>
	);
};
