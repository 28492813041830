import React from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import CaseStudyCard from './CaseStudyCard';

export default function CaseStudyCardsWrapper(props) {
	const {
		title,
		items,
	} = props;

	return (
		<Container className="case-study-cards">
			<Heading level="title-medium">
				{title}
			</Heading>
			<div className="case-study-cards__content">
				{items.map((item) => (
					<React.Fragment key={item.id}>
						{item.href
							? (
								<Link
									className="case-study-cards__link"
									to={item.href}
									isExternal
									isBlank
								>
									<CaseStudyCard item={item} />
								</Link>
							)
							: <CaseStudyCard item={item} />
						}
					</React.Fragment>
				))}
			</div>
		</Container>
	);
}
