import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Heading from 'ui/typography/Heading/Heading';
import IconPspFraud from './inline/psp-fraud.svg';
import './psp-fraud.styl';

export default function PspFraud() {
	const {pspFraudJson} = useStaticQuery(graphql`
		query {
			pspFraudJson {
				title
				subtitle
				content {
					id
					item
				}
			}
		}
	`);

	return (
		<section className="fraud-psp">
			<div className="fraud-psp__inner container">
				<div className="fraud-psp__heading">
					<Heading level="title-medium">
						{pspFraudJson.title}
					</Heading>
					<p className="fraud-psp__subtitle">
						{pspFraudJson.subtitle}
					</p>
				</div>
				<div className="fraud-psp__content">
					<IconPspFraud className="fraud-psp__image" />
					<ul className="fraud-psp-list">
						{pspFraudJson.content.map(({item, id}) => (
							<li className="fraud-psp-list__item" key={id}>
								<Heading level="title-small">{item}</Heading>
							</li>
						))}
					</ul>
				</div>
			</div>
		</section>
	);
}
