import React, {PureComponent} from 'react';
import cx from 'classnames';
import {linesData} from '../trustchain.fixtures';
import './Common.styl';
import './LinesFirst.styl';

export default class LinesFirst extends PureComponent {
	render() {
		const {selected} = this.props;
		return (
			<div className="line__wrapper">
				{linesData.map(({id, icon: IconLinesFirst, type, className, ...rest}) => (
					<IconLinesFirst
						key={id}
						className={cx({active: type === selected}, className)}
						{...rest}
					/>
				))}
			</div>
		);
	}
}
