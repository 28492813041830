import React, {cloneElement} from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';
import CardsList from './CardsList';
import './Cards.styl';

export default function Cards(props) {
	const {
		title,
		subtitle,
		type,
		isInversed,
		items,
		children,
	} = props;
	return (
		<section className={`cards cards_${type}`}>
			<Container className="cards__inner">
				<div className="cards__heading-wrapper">
					<div className="cards__heading">
						<Heading color={isInversed ? 'white' : ''} level="title-medium">
							{title}
						</Heading>
						{subtitle && (
							<p
								className={cx('cards__subtitle', {
									cards__subtitle_inversed: isInversed,
								})}
							>
								{subtitle}
							</p>
						)}
					</div>
					{children && cloneElement(children, {className: `cards__image cards__image_${type}`})}
				</div>
				<div className={`cards__content cards__content_${type}`}>
					<CardsList
						items={items}
						type={type}
					/>
				</div>
			</Container>
		</section>
	);
}
