import React from 'react';
import 'modules/layouts/components/Pricing.styl';

export default function FeaturesItems(props) {
	const {item} = props;
	return (
		<>
			<div className="pricing-page-prices__box">
				{item.startupPrice === '+'
					? <span className="pricing-page-features__mark" />
					: <p className="pricing-page-prices__price">{item.startupPrice}</p>
				}
			</div>
			<div className="pricing-page-prices__box">
				{item.smallPrice === '+'
					? <span className="pricing-page-features__mark" />
					: <p className="pricing-page-prices__price">{item.smallPrice}</p>
				}
			</div>
			<div className="pricing-page-prices__box">
				{item.mediumPrice === '+'
					? <span className="pricing-page-features__mark" />
					: <p className="pricing-page-prices__price">{item.mediumPrice}</p>
				}
			</div>
		</>
	);
}
