import React, {PureComponent} from 'react';
import cx from 'classnames';
import './StatsList.styl';

export default class StatsList extends PureComponent {
	render() {
		const {
			items,
			color,
		} = this.props;
		return (
			<ul className="stats-list">
				{items.map((item) => (
					<li
						className={cx('stats-list__item', {
							'stats-list__item_smaller': !item.description,
						})}
						key={item.id}
					>
						<p
							className={cx('stats-list__heading', {
								[`stats-list__heading_${color}`]: color,
								'stats-list__heading_with-margin': item.description,
							})}
						>
							{item.amount && <span className="stats-list__amount">{item.amount}&nbsp;</span>}
							{item.title}
						</p>
						{item.description && <p>{item.description}</p>}
					</li>
				))}
			</ul>
		);
	}
}
