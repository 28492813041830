import React, {useMemo} from 'react';
import Sidebar from './Sidebar';
import {prepareContent} from '../helpers/prepare';
import './Legal.styl';

export default function Legal(props) {
	const {
		content,
		headings,
	} = props;
	const memoContent = useMemo(() => prepareContent(content), [content]);

	return (
		<main className="legal-wrapper container">
			<aside className="legal-sidebar">
				<Sidebar headings={headings} />
			</aside>
			<div
				className="legal"
				dangerouslySetInnerHTML={{__html: memoContent}}
			/>
		</main>
	);
}
