import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';
import {loadRecaptcha} from '../helpers/recaptcha';
import RecaptchaIcon from '../inline/recaptcha.svg';
import './LazyLoadRecaptcha.styl';

export function LazyLoadRecaptcha({children, shouldUsePlaceholder}) {
	const initialState = (typeof window !== 'undefined' && window.hasOwnProperty('grecaptcha')) || false;
	const [isLoad, setIsLoad] = useState(initialState);
	const {touched} = useFormikContext();
	const count = Object.keys(touched).length;
	useEffect(() => {
		let isMounted = false;
		if (count > 0 && !isLoad) {
			loadRecaptcha();
			if (!isMounted) setIsLoad(true);
		}
		return () => { isMounted = true; };
	}, [count, isLoad]);

	if (isLoad) {
		return children;
	}
	if (shouldUsePlaceholder) {
		return <RecaptchaIcon className="recaptcha-placeholder" />;
	}
	return null;
}
