import React from 'react';
import Tooltip from './Tooltip';
import 'modules/layouts/components/Pricing.styl';

export default function PlanPrices(props) {
	const {prices} = props;
	return (
		<>
			{prices.prices.map((price) => (
				<div className="pricing-page-plans__row" key={price.id}>
					<div className="pricing-page-plans__subtitle">
						{price.subtitle}
						{price.subtitleDescription && (
							<Tooltip description={price.subtitleDescription} />
						)}
					</div>
					<p className="pricing-page-plans__number">{price.subtitleNumber}</p>
				</div>
			))}
		</>
	);
}
