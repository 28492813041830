import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Heading from 'ui/typography/Heading/Heading';
import {Container} from 'ui/container/Container';
import IconEcommerceFraudman from './inline/ecommerce-fraudman.svg';
import './fraud.styl';

export default function EcommerceFraud() {
	const {ecommerceFraudJson} = useStaticQuery(graphql`
		query {
			ecommerceFraudJson {
				title
				subtitle
				items {
					id
					item
				}
			}
		}
	`);

	return (
			<Container>
				<section className="fraud fraud_ecommerce">
					<figure className="fraud__image">
						<IconEcommerceFraudman className="fraud__icon" />
					</figure>
					<div className="fraud__content fraud__content_ecommerce">
						<div className="tooltip tooltip_ecommerce">
							<Heading level="title-medium" color="blue">
								{ecommerceFraudJson.title}
							</Heading>
							<p className="tooltip__subtext">
								{ecommerceFraudJson.subtitle}
							</p>
						</div>
						<ul className="fraud-list fraud-list_ecommerce">
							{ecommerceFraudJson.items.map(({id, item}) => (
								<li className="fraud-list__item fraud-list__item_ecommerce" key={id}>
									{item}
								</li>
							))}
						</ul>
					</div>
				</section>
			</Container>
	);
}
