import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import cx from 'classnames';
import './Intro.styl';

export default function Intro(props) {
	const {
		title,
		titleLogo: Logo,
		subtitle,
		link,
		linkTo,
		linkAppearance,
		isWide,
		isReversed,
		isCentered,
		withAttention,
		withoutTopIndent,
	} = props.data;
	const hasData = Boolean(Object.keys(props.data).length > 2);

	return (
		<section
			className={cx('intro', {
				intro_wide: isWide,
				'intro_with-large-top-indent': !isReversed,
				'intro_without-indent': withoutTopIndent,
			})}
		>
			<div className={cx('container intro__inner', {'intro__inner_flex-direction': hasData})}>
				{props.children}
				{hasData && (
					<div className={cx('intro__container', {'intro__container_align-center': isCentered})}>
						<div
							className={cx('intro__title-wrapper', {
								'intro__title-wrapper_reversed': isReversed,
								'intro__title-wrapper_without-margin': !link && isReversed,
							})}
						>
							{Logo && <Logo className="intro__title-icon" />}
							<h1 className="intro__title">
								{title}
							</h1>
						</div>
						{subtitle && (
							<Heading
								level="title-small"
								className={
									cx(
										{[`intro__subtitle intro__subtitle_${subtitle.color}`]: subtitle.color},
										{intro__subtitle_reversed: isReversed},
										{'intro__subtitle_without-margin': !link && !isReversed},
									)
								}
							>
								{subtitle.text}
							</Heading>
						)}
						{withAttention && (
							<div className="intro__attention">
								<Heading
									level="title-small"
									color="coral"
									className="intro__title_attention"
								>
									Prices are secured till
								</Heading>
								<Heading
									level="title-huge"
									color="coral"
									className="intro__title_attention"
								>
									Jan 31
								</Heading>
							</div>
						)}
						<Link
							to={linkTo}
							appearance={linkAppearance}
						>
							{link}
						</Link>
					</div>
				)}
			</div>
		</section>
	);
}
