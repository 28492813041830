import React, {Component} from 'react';
import Link from 'ui/controls/Link/Link';
import cx from 'classnames';
import './MenuList.styl';

export default class MenuList extends Component {
	renderItemsWithChildren = (item, modificator) => (
		<>
			<p
				className={cx('menu-list__title', {
					[`menu-list__title_${modificator}`]: modificator,
				})}
			>
				{item.title}
			</p>
			<ul
				className={cx('menu-sublist', {
					[`menu-sublist_${modificator}`]: modificator,
				})}
			>
				{item.children.map((subItem) => (
					<li
						className={cx('menu-sublist__item', {
							[`menu-sublist__item_${modificator}`]: modificator,
						})}
						key={subItem.id}
					>
						<Link
							to={subItem.url}
							className={cx('menu-sublist__link', {
								[`menu-sublist__link_${modificator}`]: modificator,
							})}
							isBlank={subItem.isBlank}
							isExternal={subItem.isExternal}
						>
							{subItem.title}
						</Link>
					</li>
				))}
			</ul>
		</>
	);

	renderItems = (item, modificator) => (
		<Link
			to={item.url}
			isBlank={item.isBlank}
			isExternal={item.isExternal}
			className={cx('menu-list__link', {
				[`menu-list__link_${modificator}`]: modificator,
			})}
		>
			{item.title}
		</Link>
	);

	render() {
		const {
			items,
			modificator,
		} = this.props;
		return (
			<ul
				className={cx('menu-list', {
					[`menu-list_${modificator}`]: modificator,
				})}
			>
				{items.map((item) => (
					<li
						className={cx('menu-list__item', {
							[`menu-list__item_${modificator}`]: modificator,
						})}
						key={item.id}
					>
						{item.hasOwnProperty('children')
							? this.renderItemsWithChildren(item, modificator)
							: this.renderItems(item, modificator)
						}
					</li>
				))}
			</ul>
		);
	}
}
