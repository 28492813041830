import React from 'react';
import Heading from 'ui/typography/Heading/Heading';

export default function VipRadarList(props) {
	const {
		items,
		isInversed,
	} = props.data;
	return (
		<ul className="vip-radar-list">
			{items.map((item) => (
				<li
					className="vip-radar-list__item vip-radar-list__item_blue"
					key={item.id}
				>
				<Heading level="title-small" color={isInversed ? 'white' : ''}>{item.text}</Heading>
				</li>
			))}
		</ul>
	);
}
