import {
	ipScreening,
	trustchain,
	deviceScreening,
} from '../constants/demoFlow';
import FormTrustchain from '../components/FormTrustchain';
import FormIpScreening from '../components/FormIpScreening';
import FormDeviceScreening from '../components/FormDeviceScreening';

export const getFormComponentByType = (type) => {
	switch (type) {
		case trustchain:
			return FormTrustchain;
		case ipScreening:
			return FormIpScreening;
		case deviceScreening:
			return FormDeviceScreening;
	}
};
