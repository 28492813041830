import React, {useCallback, cloneElement} from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import cx from 'classnames';
import ShowcaseList from './ShowcaseList';
import './Showcase.styl';

export default function Showcase(props) {
	const {
		title,
		subtitle,
		withTopHeading,
		isInversed,
		isReversed,
		link,
		linkTo,
		linkAppearance,
		children,
	} = props;

	const renderHeading = useCallback(() => (
		<div className={cx('showcase__heading', {showcase__heading_centered: withTopHeading})}>
			<Heading color={isInversed ? 'white' : ''} level="title-medium">{title}</Heading>
			<p className="showcase__subtitle">{subtitle}</p>
		</div>
	), [withTopHeading, isInversed, title, subtitle]);

	return (
		<div className={cx('showcase container', {showcase_inversed: isInversed})}>
			{withTopHeading && renderHeading()}
			<div className="showcase__content">
				<div
					className={cx('showcase__inner', {
						showcase__inner_reversed: isReversed,
					})}
				>
					{!withTopHeading && renderHeading()}
					<ShowcaseList data={props} />
					{link && (
						<Link
							to={linkTo}
							appearance={linkAppearance}
							className="showcase__link"
						>
							{link}
						</Link>
					)}
				</div>
				{cloneElement(children, {className: 'showcase__icon'})}
			</div>
		</div>
	);
}
