import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import Slider from 'ui/slider/SliderDating';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Pricing from 'modules/pricing/components/Pricing';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {Form} from 'modules/forms';
import {Stats} from 'modules/industries/components/Stats';
import Fraud from 'components/Fraud/Fraud';
import Trustchain from 'components/Trustchain/Trustchain';
import DatingWrapper from 'components/Dating/DatingWrapper';
import IconStats from 'modules/industries/inline/stats-phone-dating.svg';
import IconIntro from 'modules/intro/inline/intro-dating-illustation.svg';

export default function Dating({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.datingSeoJson.title}
					description={data?.datingSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.datingIntroJson}>
				<IconIntro className="intro__image" />
			</IntroWrapper>
			<ErrorBoundary>
				<Fraud />
			</ErrorBoundary>
			<DatingWrapper>
				<ErrorBoundary>
					<Slider type="dating" slides={data?.allDatingSlidesJson.nodes} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Trustchain />
				</ErrorBoundary>
				<ErrorBoundary>
					<Stats type="dating" {...data?.datingStatsJson}>
						<IconStats />
					</Stats>
				</ErrorBoundary>
			</DatingWrapper>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.datingPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const datingDetails = graphql`
	query {
		allDatingSlidesJson {
			nodes {
				id
				descriptions
				title
			}
		}
		datingIntroJson {
			icon
			link
			linkAppearance
			linkTo
			title
			subtitle {
				color
				text
			}
			isWide
			isReversed
		}
		datingStatsJson {
			icon
			isInversed
			itemsTitleColor
			leftItems {
				amount
				description
				id
				title
			}
			rightItems {
				amount
				description
				id
				title
			}
		}
		datingPricingJson {
			title
			subtitle
		}
		datingSeoJson {
			title
			description
		}
	}
`;
