import React from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import FraudIcon from '../inline/partners-fraud.svg';
import './PartnersFraud.styl';

export default function PartnersFraud(props) {
	const {
		title,
		subtitle,
		items,
	} = props;
	return (
		<section className="partners-fraud">
			<Container className="partners-fraud__inner">
				<Heading level="title-medium" isCentered>
					{title}
				</Heading>
				<p className="partners-fraud__text">
					{subtitle}
				</p>
				<div className="partners-fraud__content">
					<FraudIcon className="partners-fraud__icon" />
					<ul className="partners-fraud-list">
						{items.map((item) => (
							<li className="partners-fraud-list__item" key={item.id}>
								{item.text}
							</li>
						))}
					</ul>
				</div>
			</Container>
		</section>
	);
}
