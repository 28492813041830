import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import {Stats} from 'modules/industries/components/Stats';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Cards from 'modules/cards/components/Cards';
import Pricing from 'modules/pricing/components/Pricing';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import PspFraud from 'components/Psp/PspFraud';
import Solutions from 'components/Solutions/Solutions';
import {Form} from 'modules/forms';
import IconStats from 'modules/industries/inline/stats-man.svg';
import IconCards from 'modules/cards/inline/card-psp.svg';
import IconPadlock from 'components/Solutions/inline/psp-padlock.svg';

export default function PaymentProviders({data, location}) {
	const type = 'psp';
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.pspSeoJson.title}
					description={data?.pspSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.pspIntroJson} />
			<ErrorBoundary>
				<PspFraud />
			</ErrorBoundary>
			<ErrorBoundary>
				<Solutions {...data?.pspSolutionsJson}>
					<IconPadlock />
				</Solutions>
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.pspFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Cards {...data?.pspCardsJson} type={type}>
					<IconCards />
				</Cards>
			</ErrorBoundary>
			<ErrorBoundary>
				<Stats {...data?.pspStatsJson} type={type} boxModificator="darker-blue">
					<IconStats />
				</Stats>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.pspPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const paymentProvidersDetails = graphql`
	query {
		pspIntroJson {
			title
			linkTo
			linkAppearance
			link
			isReversed
			isCentered
			subtitle {
				color
				text
			}
		}
		pspSolutionsJson {
			title
			subtitle
			items {
				id
				description
				image {
					source {
						publicURL
					}
				}
			}
		}
		pspFeaturesJson {
			title
			subtitle
			marksColor
			linkTo
			linkAppearance
			link
			items {
				description
				title
				id
			}
		}
		pspPricingJson {
			title
			subtitle
		}
		pspCardsJson {
			title
			subtitle
			items {
				id
				description
				title
			}
			isInversed
		}
		pspStatsJson {
			isInversed
			title
			subtitle
			leftItems {
				amount
				description
				id
				title
			}
			rightItems {
				amount
				description
				id
				title
			}
		}
		pspSeoJson {
			title
			description
		}
	}
`;
