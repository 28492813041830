import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import AnalyzeList from './AnalyzeList';
import './analyze.styl';

export default function AnalyzeEcommerce({title, subtitle, items}) {
	return (
		<Box modificator="ecommerce-analyze">
			<div className="analyze analyze_ecommerce">
				<div className="analyze-container">
					<Heading level="title-medium" color="blue">
						{title}
					</Heading>
					<p className="analyze__subheader analyze__subheader_ecommerce">
						{subtitle}
					</p>
					<AnalyzeList items={items} />
				</div>
			</div>
		</Box>
	);
}
