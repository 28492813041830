import {
	ipScreening,
	trustchain,
	deviceScreening,
} from '../constants/demoFlow';

const trustchainInitialValues = {
	reCaptcha: '',
	itemType: 'email',
	itemValue: '',
};

const ipScreeningInitialValues = {
	reCaptcha: '',
	itemValue: '',
};

const deviceScreeningInitialValues = {
	reCaptcha: '',
};

export const getInitialValuesByType = (type) => {
	switch (type) {
		case trustchain:
			return trustchainInitialValues;
		case ipScreening:
			return ipScreeningInitialValues;
		case deviceScreening:
			return deviceScreeningInitialValues;
	}
};
