const getPathArray = (path) => path.split('/').filter(Boolean);

const getPage = (path) => {
	let name = 'home';
	const [parent, child] = getPathArray(path);
	if (child) {
		name = child;
	} else if (parent) {
		name = parent;
	}
	return name;
};

const getCategory = (path) => {
	const [category, page] = getPathArray(path);
	return page ? category : 'uncategorized';
};

export const sendToAnalytics = (path, isError = false) => {
	const type = isError ? 'error' : 'success';
	window.dataLayer = window.dataLayer || [];
	function gtag() { dataLayer.push(arguments); }
	gtag('js', new Date());
	gtag('config', 'G-6HK1KHLJ3Z');
	const formName = type + `-{\`${getPathArray(path)}\`}-formsend`;
	const formData = {
		formName,
		formURL: path,
	};

	gtag('event', 'formsend', {
		...formData,
		status: type,
	});
};
