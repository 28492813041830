import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import IconSuccess from '../../inline/success-icon.svg';
import './message.styl';

export default function MessageSuccess() {
	const {messageSuccessJson} = useStaticQuery(graphql`
		query {
			messageSuccessJson{
				title
				description
			}
		}
	`);

	return (
		<div className="message message-success">
			<IconSuccess />
			<h2 className="message-success-title">
				{messageSuccessJson.title}
			</h2>
			<p className="message-success-text">
				{messageSuccessJson.description}
			</p>
		</div>
	);
}
