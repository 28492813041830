import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {BaseLayout} from 'modules/layouts/components/Base';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Compact from 'modules/layouts/components/Compact';
import Legal from 'modules/legal/components/Legal';

export default function Privacy({data}) {
	return (
		<BaseLayout withoutCookie>
			<ErrorBoundary>
				<Seo
					title={data?.privacySeoJson.title}
					description={data?.privacySeoJson.description}
				/>
			</ErrorBoundary>
			<IntroWrapper
				isWide
				withoutTopIndent
				type="transparent"
			>
				<Compact>
					<Legal
						content={data?.markdownRemark.html}
						headings={data?.markdownRemark.headings}
					/>
				</Compact>
			</IntroWrapper>
		</BaseLayout>
	);
}

export const privacyDetails = graphql`
	query {
		privacySeoJson {
			title
			description
		}
		markdownRemark(frontmatter: {title: {regex: "/Privacy policy/g"}}) {
			html
			headings {
				id
				value
			}
		}
	}
`;
