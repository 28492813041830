export const getUrlWithParams = (fields, domain) => {
	const query = Object.entries(fields).map(([key, value]) => (
		`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
	)).join('&');
	return `${domain}?${query}`;
};

export const submitForm = (fields, url, error, success) => {
	let scriptOk = false;
	const callbackName = `cb_${Math.round(100000 * Math.random())}`;
	const script = document.createElement('script');

	window[callbackName] = (data) => {
		scriptOk = true;
		delete window[callbackName];
		document.body.removeChild(script);
		success(data);
	};

	function checkCallback(data) {
		if (scriptOk) return;
		delete window[callbackName];
		document.body.removeChild(script);
		error(data);
	}

	script.onreadystatechange = () => {
		if (this.readyState === 'complete' || this.readyState === 'loaded') {
			this.onreadystatechange = null;
			setTimeout(checkCallback, 0);
		}
	};
	script.onload = checkCallback;
	script.onerror = checkCallback;
	script.src = getUrlWithParams({...fields, callback: callbackName}, url);
	document.body.appendChild(script);
};
