import Intro01 from '../inline/intro/intro-01.svg';
import Intro02 from '../inline/intro/intro-02.svg';
import Intro03 from '../inline/intro/intro-03.svg';
import Intro04 from '../inline/intro/intro-04.svg';
import Intro05 from '../inline/intro/intro-05.svg';
import Intro06 from '../inline/intro/intro-06.svg';
import Intro07 from '../inline/intro/intro-07.svg';
import Intro08 from '../inline/intro/intro-08.svg';
import Intro09 from '../inline/intro/intro-09.svg';
import Intro10 from '../inline/intro/intro-10.svg';
import Intro11 from '../inline/intro/intro-11.svg';

export const pages = [
	{id: 1, page: Intro01},
	{id: 2, page: Intro02},
	{id: 3, page: Intro03},
	{id: 4, page: Intro04},
	{id: 5, page: Intro05},
	{id: 6, page: Intro06},
	{id: 7, page: Intro07},
	{id: 8, page: Intro08},
	{id: 9, page: Intro09},
	{id: 10, page: Intro10},
	{id: 11, page: Intro11},
];

export const introContent = {
	title: 'Very serious stories - Intro',
	subtitle: {
		text: 'The Christmas Eve is the perfect time to enjoy a festive'
			+ 'variety of carols, massive fireworks, shopping, and ...fraud. No one wants to miss this'
			+ 'unique opportunity to celebrate the magic of Christmas. Especially the one who wants'
			+ 'to steal it.',
		color: 'blue',
	},
	isCentered: true,
};

export const comicsIntroData = {
	title: 'Useful comics with Covery',
	subtitle: {
		text: 'Comic book series about the anti-fraud superhero Ben. Check out how he fights against the Frodus attempts to invade the world.',
		color: 'white',
	},
	isCentered: true,
};
