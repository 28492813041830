import React from 'react';
import './Page.styl';

export function Page({children}) {
	return (
		<div className="page-size">
			{children}
		</div>
	);
}
