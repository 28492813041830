import React, {useState} from 'react';
import {Header} from 'modules/header/components/Header';
import cx from 'classnames';
import './HeaderWrapper.styl';

export const HeaderWrapper = (props) => {
	const {
		menu,
		news,
	} = props;

	const [toggle, setToggle] = useState(true);

	const eventHandler = () => {
		setToggle(!toggle);
	};

	return (
		<div
			className={cx('header-wrapper', {
				'header-wrapper_scroll': !toggle,
			})}
		>
			{news}
			<Header menu={menu} eventHandler={eventHandler} toggle={toggle} />
		</div>
	);
};
