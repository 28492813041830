import React from 'react';
import './Tooltip.styl';

export default function Tooltip(props) {
	const {description} = props;
	const getDescription = (list) => list.map((item) => (
		<p key={item}>{item}</p>
	));

	return (
		<div className="pricing-tooltip">
			<span className="pricing-tooltip__text">?</span>
			<div className="pricing-tooltip__description">
				{Array.isArray(description) ? getDescription(description) : description}
			</div>
		</div>
	);
}
