import React, {PureComponent} from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import './style/triggers.styl';

export default function Triggers(props) {
	const {
		title,
		description,
		list,
	} = props;
	return (
		<Box>
			<div className="triggers container">
				<Heading level="title-medium" color="blue" isCentered>
					{title}
				</Heading>
				<p className="triggers__description">
					{description}
				</p>
				<ul className="triggers__list">
					{list.map(({item, id}) => (
						<li className="triggers__list-item" key={id}>
							{item}
						</li>
					))}
				</ul>
			</div>
		</Box>
	);
}
