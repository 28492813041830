import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import ManualKyc from 'components/ManualKyc/ManualKyc';
import Infographic from 'components/Infographic/Infographic';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import Box from 'ui/box/Box';

export default function RiskAssessment() {
	const {manual, infographic} = useStaticQuery(graphql`
	query {
		manual: deviceFingerprintingManualKycJson {
			title
			subtitle
			slides {
				id
				title
				subtitle
				footnote
			}
		}
		infographic: deviceFingerprintingInfographicJson {
			title
			list {
				id
				title
				description
			}
			link {
				text
				to
				appearance
			}
		}
	}
`);
	return (
		<Box modificator="risk-assessment">
			<ErrorBoundary>
				<ManualKyc {...manual} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Infographic {...infographic} />
			</ErrorBoundary>
		</Box>
	);
}
