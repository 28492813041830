import {useCallback, useEffect, useRef} from 'react';
import Siema from 'siema';
import {pagination} from '../helpers/pagination';

export function useSlider(options, [parent, item]) {
	const ref = useRef();

	useEffect(() => {
		Siema.prototype.addPagination = pagination(parent, item);
		ref.current = new Siema(options);
		const handler = () => ref.current.addPagination();
		handler();
		window.addEventListener('resize', handler);
		return () => {
			ref.current.destroy();
			window.removeEventListener('resize', handler);
		};
	}, [ref, options, parent, item]);

	const onPrev = useCallback(() => {
		ref.current.prev(ref.current.perPage);
	}, [ref]);

	const onNext = useCallback(() => {
		ref.current.next(ref.current.perPage);
	}, [ref]);

	const goTo = useCallback((n) => {
		ref.current.goTo(n);
	}, [ref]);

	return {
		onPrev,
		onNext,
		goTo,
	};
}
