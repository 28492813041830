import {isBrowser} from 'helpers/isBrowser';

export const setCookie = (name, value, expirationDate) => {
	if (!isBrowser) return;
	let cookie = `${name}=${value}; path=/;`;

	if (expirationDate) {
		cookie += ` expires=${expirationDate.toUTCString()};`;
	}

	window.document.cookie = cookie;
};
