import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import CollaborationList from './CollaborationList';
import './Collaboration.styl';

export default function Collaboration(props) {
	const {
		boxModificator,
		title,
		subtitle,
		textContent,
		partners,
	} = props;
	return (
		<Box modificator={boxModificator}>
			<div className="collaboration container">
				<Heading level="title-medium" isCentered>{title}</Heading>
				<p className="collaboration__description">{subtitle}</p>
				<div className="collaboration__content">
					<div className="collaboration__inner">
						<Heading
							className="collaboration__subtitle"
							level="title-small"
							withIndented
						>
							{textContent.subtitle}
						</Heading>
						{textContent.paragraphs.map(({description, id}) => (
							<p
								className="collaboration__paragraph"
								key={id}
							>
								{description}
							</p>
						))}
					</div>
					<div className="collaboration__inner">
						<div className="collaboration__box">
							<Heading
								className="collaboration__subtitle"
								level="title-small"
								withIndented
							>
								{partners?.subtitle}
							</Heading>
							<CollaborationList images={partners?.images} />
						</div>
					</div>
				</div>
			</div>
		</Box>
	);
}
