import React from 'react';
import cx from 'classnames';
import './AccordionItem.styl';

export default function AccordionItem(props) {
	const {
		children,
		itemId,
		onClick,
		isActiveItem,
		modificator,
	} = props;

	const [header, content] = children;

	const handleClick = () => onClick(itemId);

	return (
		<div
			className={cx('accordion__item', {
				[`accordion__item_${modificator}`]: modificator,
			})}
		>
			<div
				className={cx('accordion__header', {
					accordion__header_open: isActiveItem,
					[`accordion__header_${modificator}`]: modificator,
				})}
				role="tab"
				tabIndex={0}
				onClick={handleClick}
				onKeyUp={handleClick}
			>
				{header}
			</div>
			{isActiveItem && (
				<div className="accordion__content">
					{content}
				</div>
			)}
		</div>
	);
}
