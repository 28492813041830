import React from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from '@reach/router';
import {useStaticQuery, graphql} from 'gatsby';

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        favicon
        defaultImage: image
        twitterUsername
      }
    }
  }
`;

const SeoContainer = ({title, description, image, meta}) => {
	const {pathname} = useLocation();
	const {site} = useStaticQuery(query);
	const {
		defaultTitle,
		titleTemplate,
		defaultDescription,
		siteUrl,
		favicon,
		defaultImage,
		twitterUsername,
	} = site.siteMetadata;
	const seo = {
		meta: [],
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: `${siteUrl}${image || defaultImage}`,
		url: `${siteUrl}${pathname}`,
	};
	const schema = {
		'@context': 'http://www.schema.org',
		'@type': 'Organization',
		name: 'Covery',
		url: 'https://covery.ai',
		logo: 'https://covery.ai/images/logo-covery.png',
		sameAs: [
			'https://www.facebook.com/coveryai/',
			'https://twitter.com/CoveryAi/',
			'https://www.linkedin.com/company/covery-ai/',
		],
		contactPoint: {
			'@type': 'PostalAddress',
			email: ['support@covery.ai'],
		},
	};

	return (
		<Helmet
			title={seo.title}
			titleTemplate={titleTemplate}
			link={[
				{
					rel: 'shortcut icon',
					href: favicon,
				},
			]}
			meta={[
				{
					name: 'description',
					content: seo.description,
				},
				{
					name: 'image',
					content: seo.image,
				},
				{
					property: 'og:title',
					content: seo.title,
				},
				{
					property: 'og:description',
					content: seo.description,
				},
				{
					property: 'og:url',
					content: seo.url,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					property: 'og:image',
					content: seo.image,
				},
				{
					name: 'twitter:card',
					content: 'summary_large_image',
				},
				{
					name: 'twitter:creator',
					content: twitterUsername,
				},
				{
					name: 'twitter:title',
					content: seo.title,
				},
				{
					name: 'twitter:description',
					content: seo.description,
				},
				{
					name: 'twitter:image',
					content: seo.image,
				},
				{
					name: 'og:locale',
					content: 'en_US',
				},
				meta || {},
			].concat(seo.meta)}
		>
			<script type="application/ld+json">{JSON.stringify(schema)}</script>
		</Helmet>
	);
};

export default SeoContainer;
