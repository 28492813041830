import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import './OurPartners.styl';

export default function OurPartners(props) {
	const {
		title,
		subtitle,
		logos,
	} = props;
	return (
		<section className="our-partners container">
			<Heading level="title-medium" isCentered>
				{title}
			</Heading>
			<p className="our-partners__text">{subtitle}</p>
			<div className="our-partners__content">
				{logos.map(({image, id, websiteUrl}) => (
					<a href={websiteUrl} target="_blank" className="our-partners__icon-wrapper" key={id} rel="noreferrer">
						<img alt={image.description} className="our-partners__icon" src={image.source.publicURL} />
					</a>
				))}
			</div>
		</section>
	);
}
