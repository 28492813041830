import React from 'react';
import cx from 'classnames';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import Background from '../inline/how-it-works-bg.svg';
import BackgroundMobile from '../inline/how-it-works-mobile-bg.svg';
import './HowItWorks.styl';

export default function HowItWorks(props) {
	const {
		title,
		link,
		linkHref,
		linkAppearance,
		items,
	} = props;

	return (
		<section className="how-it-works-main">
			<div className="how-it-works-main__inner container">
				<Background className="how-it-works-main__background" />
				<BackgroundMobile className="how-it-works-main__background-mobile" />
				<Heading className="how-it-works-main__title" level="title-medium" isCentered>
					{title}
				</Heading>
				<ul
					className={cx('how-it-works-main-list', {
						'how-it-works-main-list_indented': link,
					})}
				>
					{items.map(({id, description, image}) => (
						<li className="how-it-works-main-list__item" key={id}>
							<figure className="how-it-works-main-list__icon">
								<img alt={image.alt} src={image.source.publicURL} />
							</figure>
							<p className="how-it-works-main-list__text">{description}</p>
						</li>
					))}
				</ul>
				{link && linkHref && (
					<Link
						to={linkHref}
						appearance={linkAppearance}
						alignCenter
					>
						{link}
					</Link>
				)}
			</div>
		</section>
	);
}
