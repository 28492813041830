import React from 'react';
import './CollaborationList.styl';

export default function Collaboration({images}) {
	return (
		<ul className="collaboration-list">
			{images.map(({image, id}) => (
				<li className="collaboration-list__item" key={id}>
					<img
						src={image.source.publicURL}
						className="collaboration-list__icon"
						alt="collaboration icon"
					/>
				</li>
			))}
		</ul>
	);
}
