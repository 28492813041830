import * as React from 'react';
import {Link as RouterLink} from 'gatsby';

export default function LinkWrapper(props) {
	const {
		to,
		className,
		isExternal,
		disabled,
		children,
		isBlank,
		download,
	} = props;

	if (to) {
		if (isExternal) {
			return (
				<a
					href={to}
					className={className}
					download={download}
					{...(isBlank ? { target: '_blank', rel: 'nofollow noopener noreferrer' } : {})}
				>
					{children}
				</a>
			);
		}

		return (
			<RouterLink
				to={to}
				className={className}
				disabled={disabled}
			>
				{children}
			</RouterLink>
		);
	}

	return null;
}
