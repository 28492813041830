import {createContext} from 'react';
import {getCookie} from './helpers/getCookie';
import {
	ADVERTISING_SCRIPTS_ADDED,
	STATISTIC_SCRIPTS_ADDED,
} from './cookie.constants';

const initialValues = {
	isStatistics: getCookie(STATISTIC_SCRIPTS_ADDED),
	isAdvertising: getCookie(ADVERTISING_SCRIPTS_ADDED),
};

export const CookieContext = createContext(initialValues);
