import React from 'react';
import Recaptcha from 'react-recaptcha';
import {generateUniqKey} from 'helpers/uniq';
import HelpBlock from './HelpBlock';
import {recaptchaFunctionName} from '../../constants/recaptcha';

export default function FieldCaptcha(props) {
	const {
		form,
		field,
		config,
		errors,
	} = props;

	return (
		<>
			<Recaptcha
				elementID={`g-recaptcha${generateUniqKey()}`}
				sitekey={config.key}
				size={config.size}
				render={config.render}
				expiredCallback={(response) => { form.setFieldValue(field.name, response); }}
				verifyCallback={(response) => { form.setFieldValue(field.name, response); }}
				onloadCallback={typeof window !== 'undefined' ? window[recaptchaFunctionName] : null}
			/>
			{errors !== undefined && errors[field.name] && (
				<HelpBlock>
					{errors[field.name]}
				</HelpBlock>
			)}
		</>
	);
}
