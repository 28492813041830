import React from 'react';
import cx from 'classnames';
import './FormGroupSignUp.styl';

function FormGroup({children, appearance}) {
	return (
		<div
			className={cx('form-group-signup', {
				[`form-group-signup_${appearance}`]: appearance,
			})}
		>
			{children}
		</div>
	);
}

export default FormGroup;
