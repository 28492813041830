import {isBrowser} from 'helpers/isBrowser';
import {recaptchaFunctionName} from '../constants/recaptcha';

export function loadRecaptcha() {
	if (!isBrowser()) return;
	window[recaptchaFunctionName] = () => {
		// console.log('recaptcha onload');
	};
	const head = document.getElementsByTagName('head')[0];
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = `https://www.google.com/recaptcha/api.js?onload=${recaptchaFunctionName}&render=explicit`;
	head.appendChild(script);
}
