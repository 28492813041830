import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import IconDatingFraudman from './inline/dating-fraud-man.svg';
import './fraud.styl';

export default function DatingFraud() {
	const {datingFraudJson} = useStaticQuery(graphql`
		query {
			datingFraudJson {
				title
				content {
					id
					item
				}
			}
		}
	`);

	return (
		<Container>
			<section className="fraud">
				<figure className="fraud__image">
					<IconDatingFraudman className="fraud__icon" />
				</figure>
				<div className="fraud__content">
					<div className="tooltip">
						<Heading color="white" modificator="margin">
							{datingFraudJson.title}
						</Heading>
					</div>
					<ul className="fraud-list">
						{datingFraudJson.content.map(({id, item}) => (
							<li className="fraud-list__item" key={id}>
								{item}
							</li>
						))}
					</ul>
				</div>
			</section>
		</Container>
	);
}
