import {setActive} from './active';

export function handlerPagination(itemClass, iconClass, textClass, pointClass) {
	return function handlerInner() {
		const items = this.selector.querySelectorAll(itemClass);
		const icon = document.querySelector(iconClass);
		const iconTexts = icon.querySelectorAll(textClass);
		const iconPoints = icon.querySelectorAll(pointClass);

		setActive(items, this.currentSlide, this.perPage);
		setActive(iconTexts, this.currentSlide, this.perPage);
		setActive(iconPoints, this.currentSlide, this.perPage);
	};
}
