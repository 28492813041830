import React from 'react';
import './HelpBlock.styl';

export default function HelpBlock(props) {
	return (
		<div className="help-block help-block_active">
			{props.children}
		</div>
	);
}
