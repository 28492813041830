import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import Showcase from 'modules/solutions/components/VipRadar';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import Pricing from 'modules/pricing/components/Pricing';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {StaticImage} from 'gatsby-plugin-image';

export default function VipRadar({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.vipRadarSeoJson.title}
					description={data?.vipRadarSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<Box modificator="very-dark-blue">
				<ErrorBoundary>
					<Showcase {...data?.vipRadarShowcaseJson}>
						<StaticImage
							src="../../img/vip-radar.png"
						/>
					</Showcase>
				</ErrorBoundary>
			</Box>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.vipRadarPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const vipRadarDetails = graphql`
	query {
		vipRadarSeoJson {
			title
			description
		}
		vipRadarShowcaseJson {
			title
			subtitle
			isInversed
			items {
				id
				text
			}
		}
		vipRadarPricingJson {
			title
			subtitle
		}
	}
`;
