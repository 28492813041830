import React from 'react';
import Box from 'ui/box/Box';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import IconFraud from '../inline/integrations-fraud.svg';
import IconArrowDown from '../inline/arrow-down.svg';
import './Fraud.styl';

export default function Fraud({title, subtitle, items}) {
	return (
		<section className="integrations-fraud">
			<Container className="integrations-fraud__inner">
				<Box modificator="integrations-fraud">
					<div className="integrations-fraud__heading">
						<Heading level="title-medium" color="white">
							{title}
						</Heading>
						<p className="integrations-fraud__subtitle">
							{subtitle}
						</p>
					</div>
					<div className="integrations-fraud__content">
						<IconFraud className="integrations-fraud__image" />
						<ul className="integrations-fraud-list">
							{items.map((item) => (
								<li className="integrations-fraud-list__item" key={item.id}>
									<IconArrowDown />
									<Heading
										level="title-small"
										color="white"
										className="integrations-fraud-list__title"
									>
										{item.text}
									</Heading>
								</li>
							))}
						</ul>
					</div>
				</Box>
			</Container>
		</section>
	);
}
