import React from 'react';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {HeaderMenu} from 'modules/menu/components/HeaderMenu';
import {Container} from 'ui/container/Container';
import Logo from 'ui/common/Logo/Logo';
import Link from 'ui/controls/Link/Link';
import './Header.styl';

export const Header = (props) => {
	const {
		menu,
		eventHandler,
		toggle,
	} = props;

	return (
			<header className="header">
				<Container className="header__inner">
					<ErrorBoundary>
						<Logo url={menu.homeLink} />
					</ErrorBoundary>
					<ErrorBoundary>
						<div className="header__nav">
							<HeaderMenu
								menuItems={menu.headerMenuItems}
								eventHandler={eventHandler}
								links={menu.links}
								toggle={toggle}
							/>
						</div>
					</ErrorBoundary>
					<div className="header__button-wrapper">
						{menu.links.map(({id, responsive, to, text}) => (
							<Link
								key={id}
								className="header__button"
								appearance={responsive.desktop.appearance}
								to={to}
								isSmall
							>
								{text}
							</Link>
						))}
					</div>
				</Container>
			</header>
	);
};
