import {createFacebookScripts} from './createFacebookScripts';
import {createLinkedinScripts} from './createLinkedinScripts';
import {createGtagScripts} from './createGtagScripts';
import {createAnalyticScripts} from './createAnalyticScripts';

export const loadAdvertisingScripts = () => {
	createGtagScripts();
	createFacebookScripts();
	createLinkedinScripts();
};

export const loadStatisticsScripts = () => {
	createAnalyticScripts();
};
