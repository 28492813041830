import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import {BaseLayout} from 'modules/layouts/components/Base';
import Showcase from 'modules/solutions/components/Showcase';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Pricing from 'modules/pricing/components/Pricing';
import Features from 'modules/features/components/Features';
import RiskAssessment from 'components/RiskAssessment/RiskAssessment';
import Triggers from 'components/Triggers/Triggers';
import ChooseUs from 'components/ChooseUs/ChooseUs';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {Form} from 'modules/forms';
import IconShowcase from 'modules/solutions/inline/showcase-laptop.svg';

export default function DeviceFingerprinting({data, location}) {
	return (
		<BaseLayout>
			<Seo
				title={data?.seo.title}
				description={data?.seo.description}
			/>
			<ScrollToTop />
			<IntroWrapper {...data?.intro} />
			<Box>
				<ErrorBoundary>
					<Showcase {...data?.showcase}>
						<IconShowcase />
					</Showcase>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.features} />
			</ErrorBoundary>
			<RiskAssessment />
			<ErrorBoundary>
				<Triggers {...data?.triggers} />
			</ErrorBoundary>
			<ErrorBoundary>
				<ChooseUs {...data?.chooseUs} />
			</ErrorBoundary>
			<Box>
				<Pricing {...data?.pricing} />
				<Form path={location.pathname} />
			</Box>
		</BaseLayout>
	);
}

export const deviceFingerprintingDetails = graphql`
	query {
		seo: deviceFingerprintingSeoJson {
			title
			description
		}
		intro: deviceFingerprintingIntroJson {
			title
			subtitle{ 
				text
				color
			}
			isCentered
		}
		showcase: deviceFingerprintingShowcaseJson {
			title
			subtitle
			marksColor
			isReversed
			items {
				text
				id
			}
		}
		features: deviceFingerprintingFeaturesJson {
			title
			link 
			linkTo
			linkAppearance
			items {
				id
				title
				description
				image {
					source {
						publicURL
					}
					alt
				}
			}
		}
		triggers: deviceFingerprintingTriggersJson {
			title
			description
			list {
				item
				id
			}
		}
		chooseUs: deviceFingerprintingChooseUsJson {
			title
			list {
				id
				title
				image {
					source {
						publicURL
					}
					alt
				}
			}
		}
		pricing: deviceFingerprintingPricingJson {
			title
			subtitle
		}
	}
`;
