import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Showcase from 'modules/solutions/components/Showcase';
import Pricing from 'modules/pricing/components/Pricing';
import Collaboration from 'modules/industries/components/Collaboration';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {Form} from 'modules/forms';
import IconShowcase from 'modules/solutions/inline/showcase-emi.svg';

export default function EmiLicense({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data.emiSeoJson.title}
					description={data?.emiSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.emiIntroJson} />
			<ErrorBoundary>
				<Features {...data?.emiFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.emiFeaturesSecondJson} />
			</ErrorBoundary>
			<Box modificator="darker-blue">
				<ErrorBoundary>
					<Showcase {...data?.emiShowcaseJson}>
						<IconShowcase />
					</Showcase>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Collaboration
					{...data?.emiCollaborationJson}
				/>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.emiPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const emiDetails = graphql`
	query {
		emiIntroJson {
			title
			linkTo
			linkAppearance
			link
			isCentered
			subtitle {
				color
				text
			}
		}
		emiFeaturesJson {
			title
			subtitle
			items {
				image {
					source {
						publicURL
					}
					alt
				}
				id
				title
				description
			}
		}
		emiFeaturesSecondJson {
			title
			subtitle
			linkTo
			linkAppearance
			link
			boxModificator
			marksColor
			items {
				id
				description
				title
			}
		}
		emiShowcaseJson {
			title
			subtitle
			link
			linkTo
			linkAppearance
			marksColor
			isInversed
			withTopHeading
			items {
				id
				text
			}
		}
		emiCollaborationJson {
			title
			subtitle
			boxModificator
			textContent {
				subtitle
				paragraphs {
					id
					description
				}
			}
			partners {
				subtitle
				images {
					id
					image {
						source {
							publicURL
						}
					}
				}
			}
		}
		emiPricingJson {
			title
			subtitle
		}
		emiSeoJson {
			title
			description
		}
	}
`;
