import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import MainSection from 'modules/comics/containers/MainSection';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

export default function Main({data}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.comicsSeoJson.title}
					description={data?.comicsSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<ErrorBoundary>
				<MainSection />
			</ErrorBoundary>
		</BaseLayout>
	);
}

export const comicsDetails = graphql`
	query {
		comicsSeoJson {
			title
			description
		}
	}
`;
