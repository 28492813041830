import React, {PureComponent} from 'react';
import cx from 'classnames';
import './ChainTitle.styl';

export default class ChainTitle extends PureComponent {
	static defaultProps = {
		className: '',
		isBigFont: false,
		align: 'center',
	};

	render() {
		const {
			align,
			className,
			description,
			isActive,
			isBigFont,
		} = this.props;
		const innerClassName = cx('chain-title', {
			'chain-title_big-font': isBigFont,
			'chain-title_align_left': align === 'left',
			'chain-title_align_right': align === 'right',
			'chain-title_active': isActive,
		}, className);

		return (
			<div className={innerClassName}>
				<span className="chain-title__bold">
					{description}
				</span>
				{' '}reputation chain
			</div>
		);
	}
}
