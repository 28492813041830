/* eslint-disable */
import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import 'ui/stylus/base/hidden.styl';
import QuestionLines from '../inline/question-lines.svg';
import QuestionLinesMobile from '../inline/question-lines-mobile.svg';
import ReduceMobileImg from '../inline/reduce-mobile-img.svg';
import RejectMobileImg from '../inline/reject-mobile-img.svg';
import './Text.styl';
import './SubscriptionQuestion.styl';

export default class SubscriptionQuestion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chainAnimation: false,
			activeClass: 'active',
			isNewProps: false,
		};
	}

	componentWillReceiveProps() {
		this.setState({isNewProps: true});
		this.setState({activeClass: ''});
	}

	showAnimation = () => {
		if (!this.state.chainAnimation) {
			this.setState({chainAnimation: true});
		}

		if (this.state.isNewProps) {
			this.setState({activeClass: 'active'});
		}
	}

	render() {
		const {chainAnimation, activeClass} = this.state;
		return (
			<section className={`questions  ${chainAnimation ? activeClass : ''}`}>
				<Waypoint onEnter={this.showAnimation} />
				<h2 className="questions__title text-title_big">What to do with
					<span className="text-title_big-bold"> pirs1923@armyspy.com </span>
					paying for classical music subscription?
				</h2>
				<QuestionLines className="questions__lines-image" />
				<QuestionLinesMobile className="questions__lines-image_mobile hidden-lg" />
				<div className="questions__wrapper">
					<div className="questions__item questions__item_left">
						<div className="questions__text questions__text_left">Reject payment and prevent fraud</div>
						<RejectMobileImg className="question__image-mobile hidden-lg" />
					</div>
					<div className="questions__item questions__item_right">
						<div className="questions__text questions__text_right">
							Reduce your operational overhead using automated flows for instant reputation updates
						</div>
						<ReduceMobileImg className="question__image-mobile hidden-lg" />
					</div>
				</div>
			</section>
		);
	}
}
