import React from 'react';
import Link from 'ui/controls/Link/Link';
import {Container} from 'ui/container/Container';
import IconAstronaut from './inline/icon-astronaut.svg';
import './notfound.styl';

export function Notfound({data}) {
	const {
		title,
		description,
		link,
	} = data;
	return (
		<div className="notfound">
			<Container className="notfound__container">
				<h2 className="notfound-number">{title}</h2>
				<h3 className="notfound-text">{description}</h3>
				<Link to={link.to} appearance={link.appearance}>{link.text}</Link>
				<IconAstronaut className="notfound-astronaut" />
			</Container>
		</div>
	);
}
