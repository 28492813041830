import React from 'react';
import './FieldCheckbox.styl';

export default function FieldCheckbox(props) {
	const {
		field,
		label,
		type,
	} = props;

	return (
		<>
			<input
				id={field.name}
				className="field-checkbox"
				type={type}
				{...field}
				required
			/>
			<label className="field-checkbox-content" htmlFor={field.name}>{label}</label>
		</>
	);
}
