import React from 'react';
import {Backdrop} from './Backdrop';
import './PopupWrapper.styl';

export const PopupWrapper = ({shouldDisplayBackdrop, children}) => (
	<section className="popup-cookie-wrapper">
		{shouldDisplayBackdrop && <Backdrop />}
		<div className="popup-cookie-wrapper__inner">
			{children}
		</div>
	</section>
);
