import React, {useRef} from 'react';
import Button from 'ui/controls/Button/Button';
import cx from 'classnames';
import {trustData} from '../trustchain.fixtures';
import './Text.styl';
import './TrustchainSelection.styl';

export default function TrustchainSelection(props) {
	const {selectedItem, handleSelectItem, children} = props;
	const buttonsWrapperRef = useRef();

	return (
		<div className="intro-trustchain bg-base">
			<div className="intro-trustchain__selection">
				<div className="text-title_small mb-30">
					{trustData.text}
				</div>
				<h2 className="text-title_big mb-45">
					What do you think of
					<span className="text-title_big-bold"> {trustData.email} </span>
					paying for classical music subscription?
				</h2>
				<div className="button-tab-group" ref={buttonsWrapperRef}>
					{trustData.buttons.map((item) => (
						<Button
							className={cx('button-tab', {
								[`button_${item.class}`]: item.class,
								[`button_align-${item.position}`]: item.position,
								button_active: selectedItem === item.value,
							})}
							type="button"
							key={item.id}
							onClick={() => {
								handleSelectItem(item.value, buttonsWrapperRef.current.offsetTop);
							}}
						>
							{item.name}
						</Button>
					))}
				</div>
				{children}
			</div>
		</div>
	);
}
