import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Button from 'ui/controls/Button/Button';
import {CustomScroll as Scroll} from 'ui/controls/CustomScroll/CustomScroll';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {Counter} from 'ui/common/Counter/Counter';
import {List} from './List';
import './PopupCookie.styl';

export const PopupCookie = (props) => {
	const {
		children,
		isSettingsOpen,
		isCounterExpired,
		handleToggleSettings,
		handleCounterExpired,
		list,
	} = props;
	return (
		<>
			<div className="popup-cookie-heading">
				<Heading level="title-small" modificator="margin">
					Cookie Settings
				</Heading>
				{isSettingsOpen && <Button onClick={handleToggleSettings} appearance="close" />}
			</div>
			<div className="countdown__text">
				<ErrorBoundary>
					{isCounterExpired
						? 'Website is enabled'
						: (
							<Counter seconds={20} callback={handleCounterExpired}>
								{(counter) => `Website will be enabled in ${counter} seconds`}
							</Counter>
						)
					}
				</ErrorBoundary>
			</div>
			<Scroll className="popup-cookie-list-wrapper">
				<ErrorBoundary>
					<List isSettingsOpen={isSettingsOpen} cookieList={list} />
					{children}
				</ErrorBoundary>
			</Scroll>
		</>
	);
};
