import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import './style/infographic.styl';

export default function Infographic(props) {
	const {
		title,
		list,
		link,
	} = props;
	return (
		<section className="infographic container">
			<Heading level="title-medium" color="white" withIndented isCentered>
				{title}
			</Heading>
			<ol className="infographic-list">
				{list.map(({id, title, description}) => (
					<li className="infographic-list__item" key={id}>
						<Heading color="bright-cyan" className="infographic__heading">
							{title}
						</Heading>
						<p className="infographic__description">
							{description}
						</p>
					</li>
				))}
			</ol>
			<Link
				to={link.to}
				appearance={link.appearance}
			>
				{link.text}
			</Link>
		</section>
	);
}
