import React, {PureComponent} from 'react';
import Box from 'ui/box/Box';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import AboutCoveryBox from './AboutCoveryBox';
import './AboutCovery.styl';

export default function AboutCovery(props) {
	const {
		title,
		description,
		link,
		linkTo,
		linkAppearance,
		items,
	} = props;
	return (
		<>
			<Box>
				<div className="about-covery container">
					<div className="about-covery__heading">
						<Heading level="title-huge">
							{title}
						</Heading>
						<p className="about-covery__description">{description}</p>
						<Link
							to={linkTo}
							appearance={linkAppearance}
							alignCenter
						>
							{link}
						</Link>
					</div>
				</div>
			</Box>
			{items.map(({id, ...rest}) => (
				<AboutCoveryBox key={id} {...rest} />
			))}
		</>
	);
}
