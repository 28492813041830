import React from 'react';
import cx from 'classnames';
import './FormGroup.styl';

function FormGroup({children, appearance}) {
	return (
		<div
			className={cx('form-group', {
				[`form-group_${appearance}`]: appearance,
			})}
		>
			{children}
		</div>
	);
}

export default FormGroup;
