import React from 'react';
import {Field} from 'formik';
import cx from 'classnames';
import {PRIVACY, TERMS} from 'constants/url.constants';
import Link from 'ui/controls/Link/Link';
import Button from 'ui/controls/Button/Button';
import {LazyLoadRecaptcha} from './LazyLoadRecaptcha';
import MessageBubble from './Message/MessageBubble';
import FieldCaptcha from './Fields/FieldCaptcha';
import FieldTextarea from './Fields/FieldTextarea';
import FieldText from './Fields/FieldText';
import FormGroup from './Fields/FormGroup';
import {recaptchaConfig} from '../constants/recaptcha';
import 'ui/stylus/base/text.styl';
import './FormContact.styl';

export default function FormContact(props) {
	const {
		handleSubmit,
		isSubmitting,
		isValid,
		isValidating,
		values,
		dirty,
	} = props;

	return (
		<form onSubmit={handleSubmit} className="form-contact">
			<MessageBubble
				error={false}
				name={values.name}
			/>
			<div className="form-inner-contact">
				<FormGroup>
					<Field
						type="text"
						name="name"
						component={FieldText}
						label="Your name*"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="email"
						name="email"
						component={FieldText}
						label="Your email*"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="tel"
						name="phone"
						component={FieldText}
						label="Your phone*"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="text"
						name="website"
						component={FieldText}
						label="Product website*"
					/>
				</FormGroup>
				<FormGroup>
					<Field
						type="textarea"
						name="message"
						component={FieldTextarea}
						label="How can we help you?*"
					/>
				</FormGroup>
				<p className="form__footnote">*required fields</p>
				<FormGroup appearance="captcha">
					<LazyLoadRecaptcha>
						<Field
							name="g-recaptcha-response"
							component={FieldCaptcha}
							config={recaptchaConfig}
							appearance="captcha"
						/>
					</LazyLoadRecaptcha>
				</FormGroup>
				<FormGroup appearance="align-center">
					<Button
						type="submit"
						className={cx({'has-loading': isSubmitting})}
						isDisabled={isSubmitting || isValidating || !isValid || !dirty}
						isSmall
					>
						Send
					</Button>
				</FormGroup>
				<div className="form__text">
					By clicking Send button you agree to and accept our &nbsp;
					<Link className="text-blue" to={TERMS}>Terms of use</Link>
					&nbsp;and&nbsp;
					<Link className="text-blue" to={PRIVACY}>Privacy policy</Link>
				</div>
			</div>
		</form>
	);
}
