import React from 'react';
import Seo from 'modules/seo/containers/SeoContainer';
import PricingLayout from 'modules/layouts/components/Pricing';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import Navigation from 'modules/pricing/components/Navigation';
import {Plans} from 'modules/pricing/components/Plans';
import {Prices} from 'modules/pricing/components/Prices';
import Description from 'modules/pricing/components/Description';
import {
	navigationLinks,
	kycAutomationPlans,
	kycAutomationPrices,
	kycAutomationDescription,
} from 'modules/pricing/pricing.mocks';
import {pricingMetaData} from 'mocks/meta.mocks';

export default function KycAutomation() {
	return (
		<PricingLayout>
			<ErrorBoundary>
				<Seo title="Pricing - KYC Automation" meta={pricingMetaData} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Navigation links={navigationLinks} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Plans plans={kycAutomationPlans} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Prices prices={kycAutomationPrices} withButtons />
			</ErrorBoundary>
			<ErrorBoundary>
				<Description description={kycAutomationDescription} />
			</ErrorBoundary>
		</PricingLayout>
	);
}
