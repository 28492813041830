import React, {cloneElement} from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import cx from 'classnames';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import StatsList from './StatsList';
import './Stats.styl';

export function Stats(props) {
	const breakpoints = useBreakpoint();
	const {
		title,
		subtitle,
		leftItems,
		children,
		rightItems,
		type,
		itemsTitleColor,
		boxModificator,
		isInversed,
	} = props;
	return (
		<Box modificator={boxModificator}>
			<div
				className={cx('stats container', {
					stats_inversed: isInversed,
				})}
			>
				{title && (
					<Heading level="title-medium" isCentered color={isInversed ? 'white' : ''}>{title}</Heading>
				)}
				{subtitle && (
					<p className="stats__subtitle">{subtitle}</p>
				)}
				<div className="stats__content">
					<StatsList color={itemsTitleColor} items={leftItems} />
					{breakpoints.md && (
						<figure className={`stats__icon-wrapper stats__icon-wrapper_${type}`}>
							{cloneElement(children, {className: 'stats__icon'})}
						</figure>
					)}
					<StatsList color={itemsTitleColor} items={rightItems} />
				</div>
			</div>
		</Box>
	);
}
