import * as URL from 'constants/url.constants';
import Paytio01 from '../inline/paytio/paytio-01.svg';
import Paytio02 from '../inline/paytio/paytio-02.svg';
import Paytio03 from '../inline/paytio/paytio-03.svg';
import Paytio04 from '../inline/paytio/paytio-04.svg';
import Paytio05 from '../inline/paytio/paytio-05.svg';
import Paytio06 from '../inline/paytio/paytio-06.svg';
import Paytio07 from '../inline/paytio/paytio-07.svg';
import Paytio08 from '../inline/paytio/paytio-08.svg';
import Paytio09 from '../inline/paytio/paytio-09.svg';
import Paytio10 from '../inline/paytio/paytio-10.svg';
import Paytio11 from '../inline/paytio/paytio-11.svg';
import Paytio12 from '../inline/paytio/paytio-12.svg';
import PaytioQr from '../inline/paytio/paytio-qr.svg';
import PaytioContent from '../components/PaytioContent';

export const pages = [
	{id: 1, page: Paytio01},
	{id: 2, page: Paytio02},
	{id: 3, page: PaytioContent},
	{id: 4, page: Paytio03},
	{id: 5, page: Paytio04},
	{id: 6, page: Paytio05},
	{id: 7, page: Paytio06},
	{id: 8, page: Paytio07},
	{id: 9, page: Paytio08},
	{id: 10, page: Paytio09},
	{id: 11, page: Paytio10},
	{id: 12, page: Paytio11},
	{id: 13, page: Paytio12},
];

export const introContent = {
	title: 'Saving Paytio',
	subtitle: {
		text: 'Mobile banking platform Paytio is struggling to obtain an EMI license. Because,'
			+ 'as it turns out, not all service providers can meet every need and demand of'
			+ 'fast-growing businesses.',
		color: 'blue',
	},
	isCentered: true,
};

export const contact = {
	qr: PaytioQr,
	url: URL.HOME,
	type: 'paytio',
	texts: [
		{id: 0, text: 'Secure transactions and eliminate chargeback risks to grow revenue.'},
		{id: 1, text: 'Spot all types of payment fraud, protect your business from reputation loss and exclude risk liability with our complex risk assessment platform.'},
	],
};
