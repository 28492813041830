import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Cards from 'modules/cards/components/Cards';
import Box from 'ui/box/Box';
import Showcase from 'modules/solutions/components/Showcase';
import Pricing from 'modules/pricing/components/Pricing';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconShowcase from 'modules/solutions/inline/showcase-card-not-present.svg';
import IconCards from 'modules/cards/inline/card-not-present.svg';

export default function CardNotPresentFraudProtection({data, location}) {
	const type = 'card-not-present';
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.cardNotPresentFraudProtectionSeoJson.title}
					description={data?.cardNotPresentFraudProtectionSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.cardNotPresentFraudProtectionIntroJson} />
			<Box modificator="very-dark-desaturated-blue">
				<ErrorBoundary>
					<Showcase {...data?.cardNotPresentFraudProtectionShowcaseJson}>
						<IconShowcase />
					</Showcase>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.cardNotPresentFraudProtectionFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Cards {...data?.accountTakeoverProtectionCardsJson} type={type}>
					<IconCards />
				</Cards>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.cardNotPresentFraudProtectionPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const cardNotPresentFraudProtectionDetails = graphql`
	query {
		cardNotPresentFraudProtectionIntroJson {
			title
			subtitle {
				color
				text
			}
			link
			linkTo
			linkAppearance
			isCentered
		}
		cardNotPresentFraudProtectionShowcaseJson {
			title
			subtitle
			marksColor
			isInversed
			isReversed
			items {
				id
				text
			}
		}
		cardNotPresentFraudProtectionFeaturesJson {
			title
			subtitle
			link
			linkTo
			linkAppearance
			marksColor
			items {
				title
				description
				id
			}
		}
		accountTakeoverProtectionCardsJson {
			title
			isInversed
			items {
				id
				description
				title
			}
		}
		cardNotPresentFraudProtectionPricingJson {
			title
			subtitle
		}
		cardNotPresentFraudProtectionSeoJson {
			title
			description
		}
	}
`;
