import React, {PureComponent} from 'react';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';
import './AboutCoveryBox.styl';

export default function AboutCoveryBox(props) {
	const {
		title,
		paragraphs,
		image,
		backgroundColor,
		isReversed,
	} = props;
	return (
			<div
				className={cx(`about-covery-box about-covery-box_${backgroundColor}`, {
					'about-covery-box_reversed': isReversed,
				})}
			>
				<div className="about-covery-box__inner">
					<img alt={image.description} className="about-covery-box__image" src={image.source.publicURL} />
					<div
						className={cx('about-covery-box__content', {
							'about-covery-box__content_reversed': isReversed,
						})}
					>
						<Heading level="title-medium">
							{title}
						</Heading>
						{paragraphs.map((paragraph) => (
							<p key={paragraph.id} className="about-covery-box__paragraph">
								{paragraph.text}
							</p>
						))}
					</div>
				</div>
			</div>
	);
}
