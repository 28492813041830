import React from 'react';
import Link from 'ui/controls/Link/Link';
import * as EMAIL from 'constants/email.constants';
import './ComicsQuestion.styl';

export default function ComicsQuestion() {
	return (
		<p className="comics-page__text">
			Have questions?
			<span className="comics-page__email-text">
				Email to us&nbsp;
				<Link
					className="comics-page__email-link comics-page__email-link_white"
					to={EMAIL.SALES_COVERY_EMAIL}
					isExternal
				>
					sales@covery.ai
				</Link>
			</span>
		</p>
	);
}
