import React, {useEffect, useState} from 'react';
import {loadRecaptcha} from '../helpers/recaptcha';
import './LazyLoadRecaptcha.styl';

export function LoadRecaptcha({children}) {
	const initialState = (typeof window !== 'undefined' && window.hasOwnProperty('grecaptcha')) || false;
	const [isLoad, setIsLoad] = useState(initialState);

	useEffect(() => {
		let isMounted = false;
		if (isLoad === false) {
			loadRecaptcha();
			if (isMounted === false) {
				setIsLoad(true);
			}
		}
		return () => { isMounted = true; };
	}, [isLoad]);

	if (isLoad) {
		return children;
	}
	return null;
}
