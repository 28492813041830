import React from 'react';
import './Copyright.styl';

export default function Copyright() {
	return (
		<p className="copyright">
			Copyright &#169; {new Date().getFullYear()} Covery AI Limited <br />
			38 Antrea Kariolou, Agios Athanasios, Limassol, 4102, CYPRUS
		</p>
	);
}
