export const pagination = (parentClasses, itemClasses) => function addPagination() {
	const slidesLength = this.innerElements?.length / this.perPage;
	const paginationEL = document.createElement('ul');

	paginationEL.classList.add(...parentClasses);
	paginationEL.addEventListener('click', (e) => {
		if (e.target.dataset.hasOwnProperty('index')) {
			this.goTo(e.target.dataset.index * this.perPage);
		}
	});

	for (let i = 0; i < slidesLength; i++) {
		const li = document.createElement('li');
		li.classList.add(
			...itemClasses,
			`${itemClasses[itemClasses.length - 1]}-${i}`,
		);
		li.classList.toggle('active', i === 0);
		li.dataset.index = `${i}`;
		paginationEL.appendChild(li);
	}
	this.selector.appendChild(paginationEL);
};
