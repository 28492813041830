import React from 'react';
import Seo from 'modules/seo/containers/SeoContainer';
import PricingLayout from 'modules/layouts/components/Pricing';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {Features} from 'modules/pricing/components/Features';
import Navigation from 'modules/pricing/components/Navigation';
import {Plans} from 'modules/pricing/components/Plans';
import {Prices} from 'modules/pricing/components/Prices';
import {
	enterpriseFeatures,
	enterprisePlans,
	enterprisePrices,
	navigationLinks,
} from 'modules/pricing/pricing.mocks';
import {pricingMetaData} from 'mocks/meta.mocks';

export default function AntiFraudEnterprises() {
	return (
		<PricingLayout>
			<ErrorBoundary>
				<Seo title="Pricing - Enterprise" meta={pricingMetaData} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Navigation links={navigationLinks} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Plans plans={enterprisePlans} features={enterpriseFeatures} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Prices prices={enterprisePrices} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features features={enterpriseFeatures} />
			</ErrorBoundary>
		</PricingLayout>
	);
}
