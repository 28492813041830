import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import Scale from 'modules/industries/components/Scale';
import {Stats} from 'modules/industries/components/Stats';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Pricing from 'modules/pricing/components/Pricing';
import FraudEcommerce from 'components/Fraud/FraudEcommerce';
import Analyze from 'components/Analyze/AnalyzeEcommerce';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconStats from 'modules/industries/inline/stats-phone-ecommerce.svg';

export default function Ecommerce({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.ecommerceSeoJson.title}
					description={data?.ecommerceSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper type="ecommerce" {...data?.ecommerceIntroJson} />
			<ErrorBoundary>
				<FraudEcommerce />
			</ErrorBoundary>
			<Box modificator="lifetime">
				<ErrorBoundary>
					<Scale type="ecommerce" {...data?.ecommerceScaleJson} />
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.ecommerceFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Analyze {...data?.ecommerceAnalyzeJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Stats {...data?.ecommerceStatsJson} type="ecommerce" boxModificator="darker-blue">
					<IconStats />
				</Stats>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.ecommercePricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const ecommerceDetails = graphql`
	query {
		ecommerceIntroJson {
			title
			linkTo
			linkAppearance
			link
			isReversed
			isCentered
			subtitle {
				color
				text
			}
		}
		ecommerceScaleJson {
			title
			subtitle
			description
			items {
				id
				number
			}
		}
		ecommerceFeaturesJson {
			title
			subtitle
			marksColor
			linkTo
			linkAppearance
			link
			items {
				description
				title
				id
			}
		}
		ecommerceAnalyzeJson {
			title
			subtitle
			items {
				id
				item
			}
		}
		ecommerceStatsJson {
			title
			subtitle
			isInversed
			itemsTitleColor
			leftItems {
				amount
				description
				id
				title
			}
			rightItems {
				amount
				description
				id
				title
			}
		}
		ecommercePricingJson {
			title
			subtitle
		}
		ecommerceSeoJson {
			title
			description
		}
	}
`;
