import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import Tooltip from './Tooltip';
import Buttons from './Buttons';
import 'modules/layouts/components/Pricing.styl';
import './Prices.styl';

export function Prices(props) {
	const breakpoints = useBreakpoint();
	const {prices, withButtons} = props;
	return (
		<>
			{breakpoints.md && (
				<section className="pricing-page-container pricing-page-container_prices">
					<Heading color="blue" className="pricing-page-container__title">
						{prices.title}
					</Heading>
					<div className="pricing-page-prices">
						{prices.options.map((option) => (
							<div className="pricing-page-prices__row" key={option.id}>
								<div className="pricing-page-prices__box">
									<div className="pricing-page-prices__inner">
										<p className="pricing-page-prices__title">{option.title}</p>
										{option.titleDescription && (
											<Tooltip description={option.titleDescription} />
										)}
									</div>
								</div>
								<div className="pricing-page-prices__box">
									<p className="pricing-page-prices__price">{option.startupPrice}</p>
								</div>
								<div className="pricing-page-prices__box">
									<p className="pricing-page-prices__price">{option.smallPrice}</p>
								</div>
								<div className="pricing-page-prices__box">
									<p className="pricing-page-prices__price">{option.mediumPrice}</p>
								</div>
							</div>
						))}
						{withButtons && <Buttons />}
					</div>
				</section>
			)}
		</>
	);
}
