import * as URL from 'constants/url.constants';
import Zamazon01 from '../inline/zamazon/zamazon-01.svg';
import Zamazon02 from '../inline/zamazon/zamazon-02.svg';
import Zamazon03 from '../inline/zamazon/zamazon-03.svg';
import Zamazon04 from '../inline/zamazon/zamazon-04.svg';
import Zamazon05 from '../inline/zamazon/zamazon-05.svg';
import Zamazon06 from '../inline/zamazon/zamazon-06.svg';
import Zamazon07 from '../inline/zamazon/zamazon-07.svg';
import Zamazon08 from '../inline/zamazon/zamazon-08.svg';
import Zamazon09 from '../inline/zamazon/zamazon-09.svg';
import Zamazon10 from '../inline/zamazon/zamazon-10.svg';
import Zamazon11 from '../inline/zamazon/zamazon-11.svg';
import ZamazonQr from '../inline/zamazon/zamazon-qr.svg';

export const pages = [
	{id: 1, page: Zamazon01},
	{id: 2, page: Zamazon02},
	{id: 3, page: Zamazon03},
	{id: 4, page: Zamazon04},
	{id: 5, page: Zamazon05},
	{id: 6, page: Zamazon06},
	{id: 7, page: Zamazon07},
	{id: 8, page: Zamazon08},
	{id: 9, page: Zamazon09},
	{id: 10, page: Zamazon10},
	{id: 11, page: Zamazon11},
];

export const introContent = {
	title: 'Once upon a time in Zamazon',
	subtitle: {
		text: 'Zamazon platform faces the biggest fraud attack ever. E-commerce industry becomes a'
			+ 'new victim of already known villain - Frodus. He seems to be unbeatable. Nevertheless,'
			+ 'Ben is always on his toes and ready to fight Frodus again.',
		color: 'blue',
	},
	isCentered: true,
};

export const contact = {
	qr: ZamazonQr,
	url: URL.HOME,
	type: 'zamazon',
	texts: [
		{id: 0, text: 'Prevent Fraud and get the highest convention from the very start.'},
		{id: 1, text: 'Spot all types of e-commerce fraud, protect business from chargebacks, exclude risk liability with our complex risk assessment platform.'},
	],
};
