import React from 'react';
import Link from 'ui/controls/Link/Link';
import 'modules/layouts/components/Pricing.styl';

export default class LinkFinder extends React.Component {
	renderTitle() {
		const {options} = this.props;
		const title = options.title;
		if (title.includes('{link}')) {
			const textBeforeLink = title.split('{')[0];
			const textAfterLink = title.split('}')[1];
			return (
				<>
					{textBeforeLink}
					{options.isExternalLink
						? (
							<a
								className="pricing-page__link"
								href={options.linkTo}
								target="_blank"
								rel="noopener noreferrer"
							>
								{options.linkText}
							</a>
						)
						: (
							<Link className="pricing-page__link" to={options.linkTo}>
								{options.linkText}
							</Link>
						)
					}
					{textAfterLink}
				</>
			);
		}
		return title;
	}

	render() {
		return (
			<>
				{this.renderTitle()}
			</>
		);
	}
}
