import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import IconTrustchainLogo from './inline/trustchain-header.svg';
import './trustchain.styl';

export default function Trustchain(props) {
	const {igamingTrustchainJson} = useStaticQuery(graphql`
		query {
			igamingTrustchainJson {
				subtitle
				items {
					title
					description
					id
					image {
						source {
							publicURL
						}
					}
				}
			}
		}
	`);

	const {
		wrapperModificator,
		containerModificator,
	} = props;
	return (
		<Box modificator={containerModificator}>
			<div className={`trustchain-wrapper ${wrapperModificator}`}>
				<Container>
					<section className={`trustchain container ${containerModificator}`}>
						<figure className="trustchain__logo">
							<IconTrustchainLogo className="trustchain-image" />
						</figure>
						<h2 className="trustchain__subheader">
							{igamingTrustchainJson.subtitle}
						</h2>
						<article className="trustchain__content">
							{igamingTrustchainJson.items.map(({title, description, id, image}) => (
								<section className="trustchain-card" key={id}>
									<figure className="trustchain-card__icon">
										<img src={image.source.publicURL} alt={title} />
									</figure>
									<Heading color="white" className="trustchain__title">
										{title}
									</Heading>
									<p className="trustchain-card__text">
										{description}
									</p>
								</section>
							))}
						</article>
					</section>
				</Container>
			</div>
		</Box>
	);
}
