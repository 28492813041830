import React, {Component} from 'react';
import {Formik} from 'formik';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import {MY_COVERY_CONTACT_US} from 'constants/url.constants';
import {validator} from '../validators/consult';
import MessageSuccess from '../components/Message/MessageSuccess';
import FormComponent from '../components/FormConsult';
import {submitForm} from '../helpers/submit';
import {sendToAnalytics} from '../helpers/analytics';

export default class Form extends Component {
	state = {
		shouldDisplayForm: true,
		hasError: false,
		errorMessage: undefined,
	};

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	handleSuccess = (data) => {
		const {path} = this.props;
		if (data.hasOwnProperty('error')) {
			this.setState({errorMessage: data.error});
			sendToAnalytics(path, true);
		} else {
			this.setState({shouldDisplayForm: false});
			sendToAnalytics(path);
		}
	}

	handleError = () => {
		const {path} = this.props;
		this.setState({shouldDisplayForm: true});
		sendToAnalytics(path, true);
	}

	handleSubmit = (values, { setSubmitting }) => {
		submitForm(
			values,
			MY_COVERY_CONTACT_US,
			this.handleError,
			this.handleSuccess,
		);
		setSubmitting(false);
	};

	render() {
		const {
			hasError,
			errorMessage,
			shouldDisplayForm,
		} = this.state;
		const initialValues = {
			name: '',
			email: '',
			phone: '',
			website: '',
			message: '',
			'g-recaptcha-response': '',
		};
		if (hasError) {
			return (
				<Container>
					<h1>Something went wrong.</h1>
				</Container>
			);
		}
		return (
			<>
				{shouldDisplayForm
					? (
						<>
							<Formik
								initialValues={initialValues}
								onSubmit={this.handleSubmit}
								component={FormComponent}
								validate={validator}
							/>
							{errorMessage && (
							<Container>
								<Heading isCentered color="coral">{errorMessage}</Heading>
							</Container>
							)}
						</>
					)
					: <MessageSuccess />
				}
			</>
		);
	}
}
