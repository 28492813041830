import React from 'react';
import cx from 'classnames';
import './FormGroupConsult.styl';

function FormGroupConsult({children, appearance}) {
	return (
		<div
			className={cx('form-group-consult', {
				[`form-group-consult_${appearance}`]: appearance,
			})}
		>
			{children}
		</div>
	);
}

export default FormGroupConsult;
