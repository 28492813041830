import React from 'react';

export default function PaytioContent() {
	return (
		<div className="comics-page__content">
			<p className="comics-page__text">
				In any industry, fraud directly leads to revenue, license, and
				customer losses. Thus, it is crucial to ensure safety, risk assessment,
				and fraud prevention for business and their users with efficient and not
				standing-still solutions.
			</p>
			<p className="comics-page__text">
				With this in mind, the team of talented risk managers and data scientists
				with 10+ years of experience of risk mitigation in low- and high-risk
				industries created Covery in 2016. The main idea was to develop a simple
				tool that can cover all business needs starting from
				chargebacks prevention and to AML/PEPs screening.
			</p>
			<p className="comics-page__text">
				As a complement to the platform, we wanted to find a comprehensive and
				straightforward way of how we could explain to anybody what risk
				management and fraud prevention are. Therefore, we
				came to an idea of comic book creation.
			</p>
			<p className="comics-page__text">
				And here we are with our 4th edition!
			</p>
		</div>
	);
}
