import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Cards from 'modules/cards/components/Cards';
import Pricing from 'modules/pricing/components/Pricing';
import Box from 'ui/box/Box';
import BoxDarkBlue from 'ui/box/color/DarkBlue';
import {Form} from 'modules/forms';
import FormDemo from 'modules/forms/containers/FormDemo';
import Showcase from 'modules/solutions/components/Showcase';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Faq from 'modules/faq/components/Faq';
import {useDeviceFingerprint} from 'hooks/useDeviceFingerprint';
import {deviceScreening} from 'modules/forms/constants/demoFlow';
import IconShowCase from 'modules/products/inline/showcase-device-screening.svg';

export default function DeviceScreening({data, location}) {
	useDeviceFingerprint();

	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.seo.title}
					description={data?.seo.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<ErrorBoundary>
				<IntroWrapper {...data?.intro} />
			</ErrorBoundary>
			<Box modificator="lighten-grey">
				<ErrorBoundary>
					<Showcase {...data?.showcase}>
						<IconShowCase />
					</Showcase>
				</ErrorBoundary>
			</Box>
			<Box>
				<ErrorBoundary>
					<FormDemo
						type={deviceScreening}
						heading={data?.formHeading}
						{...data?.check}
					/>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.features} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.featuresSecond} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.featuresThird} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Cards {...data?.cards} />
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.pricing} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
			<BoxDarkBlue>
				<Faq {...data?.faq} />
			</BoxDarkBlue>
		</BaseLayout>
	);
}

export const emiDetails = graphql`
	query {
		intro: deviceScreeningIntroJson {
			title
			isCentered
			subtitle {
				color
				text
			}
		}
		showcase: deviceScreeningShowcaseJson {
			title
			marksColor
			isReversed
			items {
				id
				text
			}
		}
		formHeading: deviceScreeningFormJson {
			title
			description
		}
		features: deviceScreeningFeaturesJson {
			title
			postfix
			boxModificator
			items {
				image {
					source {
						publicURL
					}
					alt
				}
				id
				title
				description
			}
		}
		check: deviceScreeningCheckJson {
			infoMessage
			limitMessage
		}
		featuresSecond: deviceScreeningFeaturesSecondJson {
			title
			link
			linkTo
			linkAppearance
		}
		featuresThird: deviceScreeningFeaturesThirdJson {
			title
			postfix
			boxModificator
			isInversed
			items {
				image {
					source {
						publicURL
					}
					alt
				}
				id
				title
				description
			}
		}
		cards: deviceScreeningCardsJson {
			title
			subtitle
			type
			items {
				id
				description
				title
				iconSrc {
					publicURL
				}
				link {
					text
					to
					appearance
				}
			}
		}
		pricing: deviceScreeningPricingJson {
			title
		}
		seo: deviceScreeningSeoJson {
			title
			description
		}
		faq: deviceScreeningFaqJson {
			title
			items {
				title
				paragraphs {
					description
					priority
				}
			}
		}
	}
`;
