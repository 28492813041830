import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import './style/chooseUs.styl';

export default function ChooseUs(props) {
	const {
		title,
		list,
	} = props;
	return (
		<Box modificator="choose-us">
			<div className="choose-us container">
				<Heading level="title-medium" color="white" isCentered withIndented>
					{title}
				</Heading>
				<ul className="choose-us__list">
					{list.map(({id, title, image}) => (
						<li className="choose-us__list-item" key={id}>
							<figure className="choose-us__icon">
								<img src={image.source.publicURL} alt={image.alt} />
							</figure>
							<Heading color="white" level="title-small">
								{title}
							</Heading>
						</li>
					))}
				</ul>
			</div>
		</Box>
	);
}
