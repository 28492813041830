import React, {cloneElement} from 'react';
import {convertTime} from '../../helpers/convertTime';
import {toSentenceCase} from '../../helpers/toSentenceCase';
import './ResponseTable.styl';

export default function ResponseTable({caption, items, children}) {
	const renderValueByType = (type, value) => {
		switch (type) {
			case 'firstSeenDate':
			case 'reputationСhangeDate':
				return convertTime(value);
			case 'sources':
				return value.join(', ');
			default:
				return value;
		}
	};

	return (
		<table className="trustchain-table">
			<div className="trustchain-table__heading">
				<caption className="trustchain-table__caption">{caption}</caption>
				{children && cloneElement(children)}
			</div>
			{Object.entries(items).map(([type, value]) => (
				(type !== 'name') && (
					<tr className="trustchain-table__tr">
						<td className="trustchain-table__td">{toSentenceCase(type)}</td>
						<td className="trustchain-table__td">
							{renderValueByType(type, value)}
						</td>
					</tr>
				)
			))}
		</table>
	);
}
