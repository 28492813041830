import React from 'react';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import Link from 'ui/controls/Link/Link';
import cx from 'classnames';
import MenuList from './MenuList';
import Burger from './Burger';
import './HeaderMenu.styl';

export const HeaderMenu = (props) => {
	const {
		toggle,
		eventHandler,
		links,
		menuItems,
	} = props;

	return (
		<>
			<nav className={cx('header-menu', {'header-menu_active': !toggle})}>
				<div className="header-menu__button-wrapper">
				{links.map(({id, responsive, to, text}) => (
					<Link
						key={id}
						className="header-menu__button"
						appearance={responsive.mobile.appearance}
						to={to}
						isSmall
					>
						{text}
					</Link>
				))}
				</div>
				<ErrorBoundary>
					<MenuList items={menuItems} modificator="header" />
				</ErrorBoundary>
			</nav>
			<ErrorBoundary>
				<Burger eventHandler={eventHandler} toggle={toggle} />
			</ErrorBoundary>
		</>
	);
};
