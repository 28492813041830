import React, {useEffect, useState} from 'react';
import {CookieContext} from '../cookie.context';
import {getCookie} from '../helpers/getCookie';
import {loadStatisticsScripts, loadAdvertisingScripts} from '../helpers/loadScripts';
import {
	STATISTIC_SCRIPTS_ADDED,
	ADVERTISING_SCRIPTS_ADDED,
} from '../cookie.constants';
import {setCookie} from '../helpers/setCookie';

export function CookieProvider({children}) {
	const [isStatistics, setStatistics] = useState(false);
	const [isAdvertising, setAdvertising] = useState(false);

	useEffect(() => {
		if (getCookie(STATISTIC_SCRIPTS_ADDED) || isStatistics) {
			loadStatisticsScripts();
		}
		if (getCookie(ADVERTISING_SCRIPTS_ADDED) || isAdvertising) {
			loadAdvertisingScripts();
		}
	}, [isStatistics, isAdvertising]);

	const handlerStatistics = () => {
		setStatistics(true);
		setCookie(STATISTIC_SCRIPTS_ADDED, JSON.stringify(true));
	};
	const handlerAdvertising = () => {
		setAdvertising(true);
		setCookie(ADVERTISING_SCRIPTS_ADDED, JSON.stringify(true));
	};

	return (
		<CookieContext.Provider
			value={{
				isStatistics,
				isAdvertising,
				setStatistics: handlerStatistics,
				setAdvertising: handlerAdvertising,
			}}
		>
			{children}
		</CookieContext.Provider>
	);
}
