import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Pricing from 'modules/pricing/components/Pricing';
import FraudIgaming from 'components/Fraud/FraudIgaming';
import {Analyze} from 'components/Analyze/Analyze';
import IgamingTrigger from 'components/Igaming/IgamingTrigger';
import Trustchain from 'components/Trustchain/Trustchain';
import {Stats} from 'modules/industries/components/Stats';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconIntro from 'modules/intro/inline/intro-igaming-illustation.svg';
import IconStats from 'modules/industries/inline/stats-phone-igaming.svg';

export default function Igaming({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.igamingSeoJson.title}
					description={data?.igamingSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper type="igaming" {...data?.igamingIntroJson}>
				<IconIntro />
			</IntroWrapper>
			<ErrorBoundary>
				<FraudIgaming />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.igamingFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Analyze {...data?.igamingAnalyzeJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<IgamingTrigger {...data?.igamingTriggerJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Trustchain
					wrapperModificator="trustchain-wrapper_igaming"
					containerModificator="trustchain_igaming"
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<Stats {...data?.igamingStatsJson} type="igaming" boxModificator="statistics-igaming">
					<IconStats />
				</Stats>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.igamingPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const igamingDetails = graphql`
	query {
		igamingIntroJson {
			icon
			link
			linkAppearance
			linkTo
			title
			subtitle {
				color
				text
			}
			isWide
			isReversed
		}
		igamingFeaturesJson {
			title
			subtitle
			marksColor
			linkTo
			linkAppearance
			link
			items {
				description
				title
				id
			}
		}
		igamingAnalyzeJson {
			items {
				id
				item
			}
			subtitle
			title
		}
		igamingTriggerJson {
			title
			subtitle
			items {
				id
				item
			}
		}
		igamingStatsJson {
			isInversed
			itemsTitleColor
			leftItems {
				amount
				description
				id
				title
			}
			rightItems {
				amount
				description
				id
				title
			}
		}
		igamingPricingJson {
			title
			subtitle
		}
		igamingSeoJson {
			title
			description
		}
	}
`;
