import React from 'react';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import PaytioContainer from 'modules/comics/containers/PaytioContainer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {introContent} from 'modules/comics/mocks/paytio';
import {Page} from 'modules/layouts/components/Page';

export default function Paytio() {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo title="Comic Book - Saving Paytio" />
			</ErrorBoundary>
			<Page>
				<ErrorBoundary>
					<ScrollToTop />
				</ErrorBoundary>
				<div className="comics-page-bg" />
				<ErrorBoundary>
					<IntroWrapper {...introContent} />
				</ErrorBoundary>
				<ErrorBoundary>
					<PaytioContainer />
				</ErrorBoundary>
			</Page>
		</BaseLayout>
	);
}
