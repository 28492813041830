import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import HowItWorks from 'modules/products/components/HowItWorks';
import Showcase from 'modules/solutions/components/Showcase';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Pricing from 'modules/pricing/components/Pricing';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconShowCaseVmpi from 'modules/products/inline/showcase-vmpi.svg';

export default function VmpiChargebackPrevention({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.seo.title}
					description={data?.seo.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.intro} />
			<ErrorBoundary>
				<Features {...data?.features} />
			</ErrorBoundary>
			<ErrorBoundary>
				<HowItWorks {...data?.howItWorks} />
			</ErrorBoundary>
			<Box modificator="grayish-cyan">
				<ErrorBoundary>
					<Showcase {...data?.showcase}>
						<IconShowCaseVmpi />
					</Showcase>
				</ErrorBoundary>
			</Box>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.pricing} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const vmpiChargebackPreventionDetails = graphql`
	query {
		features: vmpiChargebackPreventionFeaturesJson {
			title
			subtitle
			marksColor
			items {
				id
				title
				description
			}
		}
		seo: vmpiChargebackPreventionSeoJson {
			title
			description
		}
		intro: vmpiChargebackPreventionIntroJson {
			title
			subtitle {
				text
				color
			}
			link
			linkTo
			linkAppearance
			isCentered
		}
		howItWorks: vmpiChargebackPreventionHowItWorksJson {
			title
			link
			linkHref
			linkAppearance
			items {
				id
				description
				image {
					source {
						publicURL
					}
					alt
				}
			}
		}
		showcase: vmpiChargebackPreventionShowcaseJson {
			title
			subtitle
			marksColor
			withTopHeading
			items {
				id
				text
			}
		}
		pricing: vmpiChargebackPreventionPricingJson {
			title
			subtitle
		}
	}
`;
