import React from 'react';
import Slider from 'ui/slider/SliderDF';
import Heading from 'ui/typography/Heading/Heading';
import './style/manualKYC.styl';

export default function ManualKyc(props) {
	const {
		title,
		subtitle,
		slides,
	} = props;
	return (
		<section className="manual-kyc container">
			<Heading level="title-medium" color="white" isCentered>
				{title}
			</Heading>
			<p className="manual-kyc__description">
				{subtitle}
			</p>
			<Slider type="manual-kyc" slides={slides} />
		</section>
	);
}
