import React, {useState, useCallback} from 'react';
import ResponseTable from './ResponseTable';
import ResponseSelect from './ResponseSelect';
import './Response.styl';

export default function Response({global, industries}) {
	const filteredIndustries = industries.filter((industry) => industry.firstSeenDate !== undefined);
	const options = filteredIndustries.map(({name}, index) => (
		{value: name, label: name, id: index}
	));

	const [currentIndustry, setCurrentIndustry] = useState(filteredIndustries[0]);
	const [currentOption, setCurrentOption] = useState(options[0]);

	const handleChange = useCallback((currentOption) => {
		setCurrentOption({currentOption});
		setCurrentIndustry(filteredIndustries[currentOption.id]);
	}, [filteredIndustries]);

	return (
		<div className="response">
			{filteredIndustries.length !== 0 && currentOption && (
				<ResponseTable items={currentIndustry} caption="Industry">
					<ResponseSelect
						options={options}
						handleChange={handleChange}
						value={currentOption.label}
					/>
				</ResponseTable>
			)}
			<ResponseTable items={global} caption="Global" />
		</div>
	);
}
