import React, {useState} from 'react';
import {CONTACT} from 'constants/url.constants';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import cx from 'classnames';
import Link from 'ui/controls/Link/Link';
import Button from 'ui/controls/Button/Button';
import Heading from 'ui/typography/Heading/Heading';
import PlanItems from './PlanItems';
import PlanPrices from './PlanPrices';
import 'modules/layouts/components/Pricing.styl';
import './Plans.styl';

export function Plans(props) {
	const breakpoints = useBreakpoint();
	const [activeItem, setActiveItem] = useState(undefined);
	const [isActive, setIsActive] = useState(false);

	const handleClick = (key) => {
		setActiveItem(key);
		setIsActive(!isActive);
	};

	const {plans, features} = props;

	return (
		<>
			<Heading color="blue" className="pricing-page-container__title">
				{plans.title}
			</Heading>
			<div className="pricing-page-plans">
				<div className="pricing-page-plans__box pricing-page-plans__box_with-image" />
				{plans.options.map((plan) => (
					<div className="pricing-page-plans__box" key={plan.id}>
						<Heading>{plan.title}</Heading>
						<p className="pricing-page-plans__description">{plan.description}</p>
						{!breakpoints.md && (
							<PlanPrices prices={plan} />
						)}
						{features && !breakpoints.md && (
							<Button
								className={
									cx('pricing-page-plans__button',
										{'pricing-page-plans__button_active': activeItem === plan.id && isActive})
								}
								type="button"
								aria-label="pricing-plan"
								onClick={() => handleClick(plan.id)}
							/>
						)}
						{activeItem === plan.id && !breakpoints.md && isActive && (
							<PlanItems features={features} items={plan} />
						)}
						{!breakpoints.md && (
							<Link
								to={CONTACT}
								appearance="button"
								className="pricing-page-plans__link"
							>
								{plan.linkText ? plan.linkText : 'Free trial'}
							</Link>
						)}
					</div>
				))}
			</div>
		</>
	);
}
