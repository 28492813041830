import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import './FormDemoHeading.styl';

export default function FormDemoHeading({title, description}) {
	return (
		<div className="form-demo-heading">
			<Heading level="title-medium" className="form-demo-heading__title">
				{title}
			</Heading>
			{description && (
				<p className="form-demo-heading__subtitle">{description}</p>
			)}
		</div>
	);
}
