import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import CaseStudyCardsWrapper from './CaseStudyCardsWrapper';
import './CaseStudyPage.styl';

export default function CaseStudyPage() {
	const {caseIntroWrapperJson, caseStudyJson} = useStaticQuery(graphql`
		query {
			caseIntroWrapperJson {
				title
				subtitle {
					text
					color
				}
				isCentered
				isReversed
			}
			caseStudyJson {
				title
				items {
					id
					title
					text
					href
					modifier
				}
			}
		}
	`);

	return (
		<>
			<IntroWrapper {...caseIntroWrapperJson} />
			<CaseStudyCardsWrapper
				items={caseStudyJson.items}
				title={caseStudyJson.title}
			/>
		</>
	);
}
