import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import AnalyzeList from './AnalyzeList';
import IconIgamingCubeLeft from './inline/igaming-cube-left.svg';
import IconIgamingCubeRight from './inline/igaming-cube-right.svg';
import './analyze.styl';

export function Analyze({title, subtitle, items}) {
	const breakpoints = useBreakpoint();

	return (
		<Box modificator="analyze">
			<div className="analyze">
				<div className="analyze-container">
					{breakpoints.xl && (
						<figure className="analyze-cube analyze-cube_left">
							<IconIgamingCubeLeft />
						</figure>
					)}
					<Heading level="title-medium" color="blue">
						{title}
					</Heading>
					<p className="analyze__subheader">
						{subtitle}
					</p>
					<AnalyzeList items={items} />
					{breakpoints.xl && (
						<figure className="analyze-cube analyze-cube_right">
							<IconIgamingCubeRight />
						</figure>
					)}
				</div>
			</div>
		</Box>
	);
}
