import React, {Component} from 'react';
import LazyLinePainter from 'lazy-line-painter';
import {WOW} from 'wowjs/dist/wow';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import cx from 'classnames';
import IconKycLine from './inline/kyc-line.svg';
import './how-it-works.styl';

export default class HowItWorks extends Component {
	state = {
		isOldBrowser: false,
		iconSelector: 'how-it-works__line',
	};

	componentDidMount() {
		const isOldBrowser = /MSIE 9/i.test(navigator.userAgent)
			|| /rv:11.0/i.test(navigator.userAgent)
			|| /MSIE 10/i.test(navigator.userAgent)
			|| /Edge\/\d./i.test(navigator.userAgent);

		this.setState({isOldBrowser});

		if (!isOldBrowser) {
			const line = document.querySelector(`.${this.state.iconSelector}`);
			const howItWorksAnimation = new LazyLinePainter(line, {
				ease: 'easeInExpo',
				strokeWidth: 12,
				strokeOpacity: 1,
				strokeColor: '#008bf4',
				strokeCap: 'round',
			});

			const wowHowItWorks = new WOW(
				{
					boxClass: 'wow',
					offset: 25,
					mobile: false,
					live: false,
					callback() {
						howItWorksAnimation.paint();
					},
				},
			);
			wowHowItWorks.init();
		}
	}

	render() {
		const {
			title,
			list,
			link,
		} = this.props.steps;
		return (
			<section className={cx('how-it-works container', {'wow bounceInUp': !this.state.isOldBrowser})}>
				<Heading level="title-medium" isCentered withIndented>
					{title}
				</Heading>
				<div className="how-it-works__container">
					<ul className="how-it-works-list">
						{list.map(({id, image, isWide, description}) => (
							<li className="how-it-works-list__item" key={id}>
								<figure className="how-it-works-list__icon">
									<img alt={image.alt} src={image.source.publicURL} />
								</figure>
								<p
									className={cx('how-it-works-list__text', {
										'how-it-works-list__text_wide': isWide,
									})}
								>
									{description}
								</p>
							</li>
						))}
					</ul>
					<div className="how-it-works__image">
						<IconKycLine
							className={`${this.state.iconSelector} lazy-line-painter`}
							data-llp-composed="true"
						/>
					</div>
					<div className="how-it-works__button-wrapper">
						<Link
							to={link.to}
							appearance={link.appearance}
							alignCenter
						>
							{link.text}
						</Link>
					</div>
				</div>
			</section>
		);
	}
}
