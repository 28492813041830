import {isBrowser} from 'helpers/isBrowser';

export function createAnalyticScripts() {
	if (!isBrowser() || window.location.hostname === 'localhost') return;
	const scriptInline = document.createElement('script');
	const scriptExternal = document.createElement('script');
	scriptExternal.classList.add('ga4script');
	const id = process.env.GATSBY_ANALYTICS_ID;

	document.head.prepend(scriptInline);
	scriptInline.innerHTML = `window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date; ga('create', '${id}', 'auto'); ga('send', 'pageview');`;
	document.head.prepend(scriptExternal);
	scriptExternal.type = 'text/javascript';
	scriptExternal.async = true;
	scriptExternal.src = 'https://www.googletagmanager.com/gtag/js?id=G-6HK1KHLJ3Z';
}
