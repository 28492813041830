import React from 'react';
import Button from 'ui/controls/Button/Button';
import './ButtonsContainer.styl';

export function ButtonsContainer(props) {
	const {
		isSettingsOpen,
		onToggleSettings,
		onAcceptAllCookie,
		onAcceptSelected,
	} = props;

	return (
		<div className="popup-cookie-buttons">
			{isSettingsOpen && (
				<Button
					className="popup-cookie-buttons__button"
					onClick={onAcceptSelected}
					isSmall
				>
					Save Settings
				</Button>
			)}
			<Button
				appearance={isSettingsOpen ? 'primary' : ''}
				onClick={onAcceptAllCookie}
				className="popup-cookie-buttons__button"
				isSmall
			>
				Accept all cookies
			</Button>
			{!isSettingsOpen && (
				<Button
					appearance="primary"
					onClick={onToggleSettings}
					isSmall
					className="popup-cookie-buttons__button"
				>
					Cookie Settings
				</Button>
			)}
		</div>
	);
}
