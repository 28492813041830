import React from 'react';
import Box from 'ui/box/Box';
import Heading from 'ui/typography/Heading/Heading';
import './customizable-platform.styl';

export default function CustomizablePlatform(props) {
	const {
		title,
		description,
	} = props;
	return (
		<Box>
			<div className="customizable-platform container">
				<Heading level="title-medium" className="customizable-platform__title">
					{title}
				</Heading>
				<p className="customizable-platform__text">
					{description}
				</p>
			</div>
		</Box>
	);
}
