import * as React from 'react';
import cx from 'classnames';
import LinkWrapper from './LinkWrapper';
import './Link.styl';

export default function Link(props) {
	const {
		to,
		children,
		className,
		isExternal,
		isDisabled,
		isSmall,
		appearance = '',
		alignCenter,
		alignRight,
		download,
		isBlank,
	} = props;

	return (
		<LinkWrapper
			to={to}
			className={cx('link', className, {
				'link_align-center': alignCenter,
				'link_align-right': alignRight,
				[`link_${appearance}`]: appearance === 'button',
				[`link_button link_${appearance}`]: appearance && appearance !== 'button',
				[`link_${appearance}-small`]: appearance === 'button' && isSmall,
				[`link_button-small link_${appearance}-small`]: appearance && appearance !== 'button' && isSmall,
				[`link_${appearance}_disabled`]: appearance && isDisabled,
				link_disabled: isDisabled,
			})}
			isExternal={isExternal}
			disabled={isDisabled}
			download={download}
			isBlank={isBlank}
		>
			{children}
		</LinkWrapper>
	);
}
