import React, {useState} from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import {Stats} from 'modules/industries/components/Stats';
import ChainsFirst from 'modules/trustchain/components/ChainsFirst';
import LinesFirst from 'modules/trustchain/components/LinesFirst';
import SubscriptionQuestion from 'modules/trustchain/components/SubscriptionQuestion';
import PaymentScheme from 'modules/trustchain/components/PaymentScheme';
import TrustchainSelection from 'modules/trustchain/components/TrustchainSelection';
import Pricing from 'modules/pricing/components/Pricing';
import Box from 'ui/box/Box';
import {Form} from 'modules/forms';
import FormDemo from 'modules/forms/containers/FormDemo';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {useDeviceFingerprint} from 'hooks/useDeviceFingerprint';
import {trustchain} from 'modules/forms/constants/demoFlow';
import {SCREEN_TABLET} from 'constants/screen.сonstants';
import IconStats from 'modules/industries/inline/stats-trustchain.svg';

export default function Trustchain({data, location}) {
	useDeviceFingerprint();
	const [selectedItem, setSelectedItem] = useState(null);

	const setChosenMenuItem = (chosenItem, btnsOffsetTop) => {
		const w = window;
		let scrollHeight;

		w.innerWidth < SCREEN_TABLET
			? scrollHeight = btnsOffsetTop
			: scrollHeight = btnsOffsetTop + 300;

		setSelectedItem(chosenItem);

		setTimeout(() => {
			w.scrollTo({top: scrollHeight, behavior: 'smooth'});
		}, 1000);
	};

	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.trustchainSeoJson.title}
					description={data?.trustchainSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<ErrorBoundary>
				<IntroWrapper {...data?.intro} />
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<FormDemo
						type={trustchain}
						heading={data?.formHeading}
						{...data?.check}
					/>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.trustchainFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.featuresSecond} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Stats {...data?.trustchainStatsJson} boxModificator="grayish-cyan">
					<IconStats />
				</Stats>
			</ErrorBoundary>
			<ErrorBoundary>
				<TrustchainSelection
					handleSelectItem={setChosenMenuItem}
					selectedItem={selectedItem}
				>
					<ErrorBoundary>
						<LinesFirst selected={selectedItem} />
					</ErrorBoundary>
				</TrustchainSelection>
			</ErrorBoundary>
			{selectedItem && (
				<div className="content-block">
					<ErrorBoundary>
						<ChainsFirst selectedItem={selectedItem} />
					</ErrorBoundary>
					<ErrorBoundary>
						<PaymentScheme selectedItem={selectedItem} />
					</ErrorBoundary>
					<ErrorBoundary>
						<SubscriptionQuestion />
					</ErrorBoundary>
				</div>
			)}
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.trustchainPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const emiDetails = graphql`
	query {
		formHeading: trustchainFormJson {
			title
			description
		}
		trustchainFeaturesJson {
			title
			boxModificator
			subtitle
			marksColor
			link
			linkTo
			linkAppearance
			items {
				id
				title
				description
			}
		}
		check: trustchainCheckJson {
			infoMessage
			limitMessage
		}
		featuresSecond: trustchainFeaturesSecondJson {
			title
			boxModificator
			link
			linkTo
			linkAppearance
			isInversed
			items {
				id
				image {
					source {
						publicURL
					}
					alt
				}
				description
				title
			}
		}
		trustchainSeoJson {
			title
			description
		}
		intro: trustchainIntroJson {
			title
			subtitle {
				text
				color
			}
			link
			linkTo
			linkAppearance
			isCentered
		}
		trustchainPricingJson {
			title
			subtitle
		}
		trustchainStatsJson {
			title
			subtitle
			icon
			leftItems {
				id
				title
			}
			rightItems {
				id
				title
			}
		}
	}
`;
