import React from 'react';
import './SocialMenu.styl';

export function SocialMenu({links}) {
	return (
		<ul className="social-list">
			{links.map(({id, to, image}) => (
				<li className="social-list__item" key={id}>
					<a
						href={to}
						className="social-list__link"
						target="_blank"
						rel="nofollow noopener noreferrer"
					>
						<img
							className="social-list__icon"
							src={image.source.publicURL}
							alt={image.alt}
						/>
					</a>
				</li>
			))}
		</ul>
	);
}
