import * as ANCHOR from 'constants/anchor.constants';
import * as URL from 'constants/url.constants';

export const homeIntro = {
	title: 'Fraud shall not pass!',
	subtitle: {
		text: 'Don\'t wait until your company will become a victim. Prevent fraud and mitigate risks from the very start.',
		color: 'white',
	},
	link: 'Request a demo',
	linkTo: `#${ANCHOR.PRICING}`,
	linkAppearance: 'button-white',
	isWide: true,
};

export const igamingIntro = {
	title: 'Don\'t gamble with your business - ensure responsible gaming',
	subtitle: {
		text: 'Proven risk & fraud management platform',
		color: 'blue',
	},
	link: 'Talk to us',
	linkTo: `#${ANCHOR.PRICING}`,
	linkAppearance: 'button-white',
	isReversed: true,
	isWide: true,
};

export const pricingIntro = {
	title: 'Pricing',
	subtitle: {
		text: 'Meet our flexible pricing to help you solve fraud and increase revenue',
		color: 'white',
	},
	isCentered: true,
	withAttention: true,
};

export const comicsIntro = {
	title: 'Very serious stories',
	subtitle: {
		text: 'Comic book series about the anti-fraud superhero Ben. Check out how he fights against the Frodus attempts to invade the world.',
		color: 'white',
	},
	isCentered: true,
};

export const caseStudyIntro = {
	title: 'Covery case studies',
	subtitle: {
		text: 'Case studies',
		color: 'coral',
	},
	isCentered: true,
	isReversed: true,
};

export const partnersIntro = {
	title: 'Covery partners',
	subtitle: {
		text: 'Connect with a community that makes fraud prevention more understandable, powerful, and accessible to anybody',
		color: 'blue',
	},
	link: 'Talk to us',
	linkTo: `#${ANCHOR.PRICING}`,
	linkAppearance: 'button-white',
	isCentered: true,
};
