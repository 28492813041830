import React from 'react';
import Comics from '../components/Comics';
import {pages} from '../mocks/intro';
import pdf from '../pdf/intro-comics.pdf';

export default function PaytioContainer() {
	return (
		<Comics pages={pages} pdf={pdf} />
	);
}
