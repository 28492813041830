import React, {Component} from 'react';
import {Formik} from 'formik';
import {Container} from 'ui/container/Container';
import {PRICING} from 'constants/anchor.constants';
import Link from 'ui/controls/Link/Link';
import Heading from 'ui/typography/Heading/Heading';
import FormDemoHeading from '../components/FormDemoHeading';
import Response from '../components/Response/Response';
import ResponseDescription from '../components/Response/ResponseDescription';
import {getFormComponentByType} from '../helpers/getFormComponentByType';
import {getValidatorByType} from '../helpers/getValidatorByType';
import {getInitialValuesByType} from '../helpers/getInitialValuesByType';
import {getResponseDataByType} from '../helpers/getResponseDataByType';
import {getItemTypeByType} from '../helpers/getItemTypeByType';
import {apiPathName} from '../constants/api';
import {deviceScreening} from '../constants/demoFlow';

export default class FormDemo extends Component {
	state = {
		responseShow: false,
		errorMessage: undefined,
		hasError: false,
		responseData: {},
		isLimitExceeded: false,
	};

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	handleSend = (values, {setSubmitting, setFieldValue}) => {
		const {deviceFingerprint} = window.sessionStorage;
		const {type} = this.props;

		const data = {
			itemType: getItemTypeByType(type),
			itemValue: type === deviceScreening ? deviceFingerprint : null,
			...values,
			device_fingerprint: deviceFingerprint,
			demo_flow: this.props.type,
			user_merchant_id: '111',
			sequence_id: '222',
		};

		fetch(process.env.GATSBY_COVERY_API + apiPathName, {
			method: 'POST',
			body: JSON.stringify(data),
		})
			.then(this.fetchStatusHandler)
			.then((data) => {
				const {trustchain, ipScreening, deviceScreening, filtered} = data;
				if (!filtered) {
					this.setState({responseData: getResponseDataByType(data, this.props.type)});
				}
				this.setState({responseShow: trustchain || ipScreening || deviceScreening});
				this.setState({isLimitExceeded: !!filtered});
			})
			.catch((response) => {
				this.setState({errorMessage: response.message});
			})
			.finally(() => {
				setSubmitting(false);
				window.grecaptcha.reset();
				setFieldValue('reCaptcha', '');
			});
	};

	fetchStatusHandler = (response) => {
		if (response.status === 200) {
			return response.json();
		}
		throw new Error(response.statusText);
	};

	render() {
		const {hasError, responseShow, errorMessage, responseData, isLimitExceeded} = this.state;
		const {type, heading, infoMessage, limitMessage} = this.props;
		const description = isLimitExceeded ? limitMessage : infoMessage;

		if (hasError) {
			return (
				<Container>
					<Heading isCentered>Something went wrong...</Heading>
					<p>{errorMessage}</p>
				</Container>
			);
		}
		return (
			<Container>
				<FormDemoHeading {...heading} />
				<Formik
					initialValues={getInitialValuesByType(type)}
					onSubmit={this.handleSend}
					validate={getValidatorByType(type)}
					component={getFormComponentByType(type)}
				/>
				{errorMessage && (
					<Container>
						<Heading isCentered color="coral">{errorMessage}</Heading>
					</Container>
				)}
				<ResponseDescription isWithIndented={isLimitExceeded}>{description}</ResponseDescription>
				{isLimitExceeded && (
					<Link
						to={`#${PRICING}`}
						appearance="button"
						alignCenter
					>
						Contact us
					</Link>
				)}
				{responseShow && <Response {...responseData} />}
			</Container>
		);
	}
}
