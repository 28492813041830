import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import CaseStudyPage from 'modules/caseStudy/components/CaseStudyPage';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

export default function CaseStudy({data}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.caseStudySeoJson.title}
					description={data?.caseStudySeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<ErrorBoundary>
				<CaseStudyPage />
			</ErrorBoundary>
		</BaseLayout>
	);
}

export const caseStudyDetails = graphql`
	query {
		caseStudySeoJson {
			title
			description
		}
	}
`;
