import React from 'react';
import './Dating.styl';

export default function Dating({children}) {
	return (
		<section className="box box_dating">
			{children}
		</section>
	);
}
