import React from 'react';
import './Input.styl';

export default function Input(props) {
	const {
		name,
		value,
		isChecked,
		onChange,
		isDisabled,
	} = props;

	return (
		<>
			<input
				className="popup-cookie__input"
				id={value}
				name={name}
				type="checkbox"
				value={value}
				checked={isChecked}
				onChange={onChange}
				disabled={isDisabled}
			/>
			<label htmlFor={value} className="popup-cookie__label">{value}</label>
		</>
	);
}
