import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {Field} from 'formik';
import cx from 'classnames';
import Button from 'ui/controls/Button/Button';
import {LazyLoadRecaptcha} from './LazyLoadRecaptcha';
import FormGroup from './Fields/FormGroup';
import FieldCaptcha from './Fields/FieldCaptcha';
import FieldText from './Fields/FieldText';
import FieldSelect from './Fields/FieldSelect';
import {recaptchaConfig} from '../constants/recaptcha';
import './FormTrustchain.styl';

export default function FormTrustchain(props) {
	const {allTrustchainSelectOptionsJson} = useStaticQuery(graphql`
		query {
			allTrustchainSelectOptionsJson {
				nodes {
					value
					label
				}
			}
		}
	`);
	const {nodes} = allTrustchainSelectOptionsJson;
	const {
		handleSubmit,
		isSubmitting,
		isValid,
		isValidating,
		dirty,
	} = props;

	return (
		<form onSubmit={handleSubmit} className="form-trustchain__container">
			<FormGroup appearance="trustchain">
				<Field
					name="itemType"
					isSearchable={false}
					component={FieldSelect}
					options={nodes}
					label="What do you want to check?"
				/>
			</FormGroup>
			<FormGroup appearance="trustchain">
				<Field
					placeholder="Type..."
					type="text"
					name="itemValue"
					component={FieldText}
					label="Enter value here"
				/>
			</FormGroup>
			<FormGroup appearance="captcha-trustchain">
				<LazyLoadRecaptcha shouldUsePlaceholder>
					<Field
						name="reCaptcha"
						component={FieldCaptcha}
						config={recaptchaConfig}
					/>
				</LazyLoadRecaptcha>
			</FormGroup>
			<FormGroup appearance="trustchain-button">
				<Button
					type="submit"
					className={cx({'has-loading': isSubmitting})}
					isDisabled={isSubmitting || isValidating || !isValid || !dirty}
					isSmall
				>
					Get reputation
				</Button>
			</FormGroup>
		</form>
	);
}
