import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import LinesSecond from './LinesSecond';
import ChainTitle from './ChainTitle';
import ChainsBlock from './ChainsBlock';
import ChainCenter from '../inline/chain-center.svg';
import ChainRight from '../inline/chain-right.svg';
import BindersRight from '../inline/binders-right.svg';
import './Common.styl';
import './ChainsFirst.styl';

export default class ChainsFirst extends Component {
	state = {
		isAnimate: false,
		activeClass: 'active',
	};

	showAnimation = () => {
		const {isAnimate} = this.state;
		if (isAnimate === false) {
			this.setState({isAnimate: true});
		}
		this.setState({activeClass: ''}, () => {
			this.setState({activeClass: 'active'});
		});
	}

	render() {
		const {isAnimate, activeClass} = this.state;

		return (
			<section className={`chains ${activeClass}`}>
				<Waypoint onEnter={this.showAnimation} />
				<div className="chains__wrapper">
					<div className="chain chain_left">
						<ChainTitle description="Your" isActive={isAnimate} align="left" />
						<div className="chain__container chain__container_left">
							<ChainsBlock type={this.props.selectedItem} />
						</div>
					</div>
					<div className="chain chain_center">
						<ChainTitle description="Global" isActive={isAnimate} className="visible-md" />
						<div className="chain__container chain__container_center">
							<ChainCenter className="chain-img chain-img_center" />
						</div>
					</div>
					<div className="chain chain_right">
						<ChainTitle description="Other" isActive={isAnimate} align="right" />
						<div className="chain__container chain__container_right">
							<ChainRight className="chain-img chain-img_right" />
							<BindersRight className="binders binders_right" />
						</div>
					</div>
				</div>
				<div className="chains__wrapper_mobile hidden-md">
					<ChainTitle description="Global" isActive={isAnimate} isBigFont />
				</div>
				{isAnimate && <LinesSecond activeClass={activeClass} />}
			</section>
		);
	}
}
