import * as URL from 'constants/url.constants';

const subtitleDescription = [
	'Build any kind of custom reports based on the data you need. Covery has 6 types of customizable reports:',
	'- Events report',
	'- Performance report',
	'- Transaction analysis ',
	'- Rule efficiency report',
	'- Rule forecast',
	'- Agent performance',
];

// Pricing Anti-Fraud Enterprises Page Data Start
export const enterprisePlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Basic',
			description: 'The best package for small enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly fee',
					subtitleNumber: '€3,300',
				}, {
					id: 1,
					subtitle: 'Annual fee',
					subtitleNumber: '€31,680',
				}, {
					id: 2,
					subtitle: 'Monthly limit',
					subtitleNumber: '1,5M',
					subtitleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each '
						+ 'extra call would be charged according to your pricing plan.Event API endpoint is mostly used '
						+ 'for the historical data transferring, or sending us real-time user’s data without a need to '
						+ 'get a decision from Covery. There are no monthly limits applied to the Event API calls.',
				},
			],
		},
		{
			id: 1,
			title: 'Standard',
			description: 'Suitable for medium enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly fee',
					subtitleNumber: '€4,500',
				},
				{
					id: 1,
					subtitle: 'Annual fee',
					subtitleNumber: '€43,200',
				},
				{
					id: 2,
					subtitle: 'Monthly limit',
					subtitleNumber: '9M',
					subtitleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each extra '
						+ 'call would be charged according to your pricing plan.Event API endpoint is mostly used for '
						+ 'the historical data transferring, or sending us real-time user’s data without a need to get '
						+ 'a decision from Covery. There are no monthly limits applied to the Event API calls.',
				},
			],
		},
		{
			id: 2,
			title: 'Advanced',
			description: 'Relevant for large enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly fee',
					subtitleNumber: '€10,200',
				},
				{
					id: 1,
					subtitle: 'Annual fee',
					subtitleNumber: '€97,920',
				},
				{
					id: 2,
					subtitle: 'Monthly limit',
					subtitleNumber: '10,000',
					subtitleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each '
						+ 'extra call would be charged according to your pricing plan.Event API endpoint is mostly used '
						+ 'for the historical data transferring, or sending us real-time user’s data without a need to '
						+ 'get a decision from Covery. There are no monthly limits applied to the Event API calls.',
				},
			],
		},
	],
};

export const enterpriseFeatures = {
	title: 'Features',
	options: [
		{
			id: 0,
			title: '{link} technology with the monthly limit of unique users',
			titleDescription: 'Unique user means 1 user which can be detected one or more than one time during the 24 '
				+ 'hours. In case of exceeding the monthly limit, extra fee would be applied according to device '
				+ 'fingerprinting pricing plans.',
			startupPrice: '1M',
			smallPrice: '8M',
			mediumPrice: '24M',
			linkTo: URL.PRODUCTS_DEVICE_FINGERPRINTING,
			linkText: 'Device Fingerprinting',
		}, {
			id: 1,
			title: 'User account/s with multiple access levels',
			startupPrice: '5',
			smallPrice: '5',
			mediumPrice: '5',
		}, {
			id: 2,
			title: '{link} Generation technology with the monthly limit of Unique Card IDs',
			titleDescription: 'Unique Card ID means a unique hash for each PAN number or card that would be stored in '
				+ 'our PCI DSS level 1 secure database. With this technology you can spot already seen cards with '
				+ '100% accuracy.',
			startupPrice: 'no limit',
			smallPrice: 'no limit',
			mediumPrice: 'no limit',
			linkTo: URL.COVERY_DOCS_DEVICE_FINGERPRINTING,
			linkText: 'Card ID',
			isExternalLink: true,
		}, {
			id: 3,
			title: 'Access to the industry/s in a reputation database {link}',
			startupPrice: '5',
			smallPrice: '5',
			mediumPrice: '5',
			linkTo: URL.PRODUCTS_TRUSTCHAIN,
			linkText: 'Trustchain',
		}, {
			id: 4,
			title: 'Free trial for 30 days with unlimited number of  Decision API calls',
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 5,
			title: 'Free integration',
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 6,
			title: 'Dedicated full-time risk analyst',
			titleDescription: 'You will be provided with dedicated risk analyst (40h/week, EET business hours) that '
				+ 'will assist you with the risk logic set up and improvements.',
			startupPrice: '—',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 7,
			title: 'Admin feature controls',
			titleDescription: 'The Manager\'s account would allow to set and change User\'s permission levels',
			startupPrice: '—',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 8,
			title: 'Dedicated full-time ML expert/data scientist',
			titleDescription: 'You will be provided with dedicated Data Scientist (40h/week, EET business hours) '
				+ 'that will assist you with the development of unlimited number of ML models based on your needs.',
			startupPrice: '—',
			smallPrice: '—',
			mediumPrice: '+',
		}, {
			id: 9,
			title: 'Free proof of concept',
			subtitles: [
				{
					id: 1,
					subtitle: 'offline',
					subtitleDescription: 'Does not include API integration based on raw data (CSV, XLS files).',
				}, {
					id: 2,
					subtitle: 'online',
					subtitleDescription: 'Includes direct API integration with Covery.',
				},
			],
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 10,
			title: 'Online support',
			subtitles: [
				{
					id: 1,
					subtitle: 'free educational training',
				}, {
					id: 2,
					subtitle: 'basic risk logic set up',
					subtitleDescription: 'We will help you to set up general risk logic based on your needs.',
				}, {
					id: 3,
					subtitle: '3h/month of risk analyst',
					subtitleDescription: 'Use this time for training sessions, and resolving technical or risk logic '
						+ 'issues.  In case of exceeding the monthly limit of 3 hours, 40 EUR per each extra hour would '
						+ 'be applied to your next monthly invoice.',
				},
			],
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 11,
			title: 'Included modules',
			subtitles: [
				{
					id: 1,
					subtitle: 'Reports module',
					subtitleDescription,
				}, {
					id: 2,
					subtitle: 'Features module',
					subtitleDescription: 'Make any simple and complex calculations and aggregations (velocity check, '
						+ 'dymanic thesholds, etc.)',
				}, {
					id: 3,
					subtitle: 'Scoring module',
					subtitleDescription: 'Create any kind of rules and scenarios per specific business need, product, '
						+ 'GEO, traffic source, MID or any other purpose.',
				},
			],
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		},
	],
};

export const enterprisePrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly fee',
			startupPrice: '€3,300',
			smallPrice: '€4,500',
			mediumPrice: '€10,200',
		}, {
			id: 1,
			title: 'Annual fee (20% discount)',
			startupPrice: '€31,680',
			smallPrice: '€43,200',
			mediumPrice: '€97,920',
		}, {
			id: 2,
			title: 'Monthly limit (decision API calls)',
			titleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each extra call '
				+ 'would be charged according to your pricing plan.Event API endpoint is mostly used for the historical '
				+ 'data transferring, or sending us real-time user’s data without a need to get a decision from Covery. '
				+ 'There are no monthly limits applied to the Event API calls.',
			startupPrice: '1,5M',
			smallPrice: '9M',
			mediumPrice: '30M',
		},
	],
};
// Pricing Anti-Fraud Enterprises Page Data End

// Pricing Anti-Fraud SMB Page Data Start
export const smbPlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Startup',
			description: 'The best package for startups',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly fee',
					subtitleNumber: '€1,000',
				}, {
					id: 1,
					subtitle: 'Annual fee',
					subtitleNumber: '€9,600',
				}, {
					id: 2,
					subtitle: 'Monthly limit',
					subtitleNumber: '15,000',
					subtitleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each extra '
						+ 'call would be charged according to your pricing plan.Event API endpoint is mostly used for '
						+ 'the historical data transferring, or sending us real-time user’s data without a need to get '
						+ 'a decision from Covery. There are no monthly limits applied to the Event API calls.',
				},
			],
		},
		{
			id: 1,
			title: 'Small',
			description: 'Suitable for small size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly fee',
					subtitleNumber: '€1,750',
				}, {
					id: 1,
					subtitle: 'Annual fee',
					subtitleNumber: '€16,800',
				}, {
					id: 2,
					subtitle: 'Monthly limit',
					subtitleNumber: '35,000',
					subtitleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each extra '
						+ 'call would be charged according to your pricing plan.Event API endpoint is mostly used for '
						+ 'the historical data transferring, or sending us real-time user’s data without a need to get '
						+ 'a decision from Covery. There are no monthly limits applied to the Event API calls.',
				},
			],
		},
		{
			id: 2,
			title: 'Medium',
			description: 'Relevant for medium size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly fee',
					subtitleNumber: '€2,250',
				}, {
					id: 1,
					subtitle: 'Annual fee',
					subtitleNumber: '€21,600',
				}, {
					id: 2,
					subtitle: 'Monthly limit',
					subtitleNumber: '150,000',
					subtitleDescription: 'In case of exceeding the monthly limit of the Decision API calls, each extra '
						+ 'call would be charged according to your pricing plan.Event API endpoint is mostly used for '
						+ 'the historical data transferring, or sending us real-time user’s data without a need to get '
						+ 'a decision from Covery. There are no monthly limits applied to the Event API calls.',
				},
			],
		},
	],
};

export const smbFeatures = {
	title: 'Features',
	options: [
		{
			id: 0,
			title: '{link} technology with the monthly limit of unique users',
			titleDescription: 'Unique user means 1 user which can be detected one or more than one time during the 24 '
				+ 'hours. In case of exceeding the monthly limit, extra fee would be applied according to device '
				+ 'fingerprinting pricing plans.',
			startupPrice: '7,500',
			smallPrice: '20,000',
			mediumPrice: '100,000',
			linkTo: URL.PRODUCTS_DEVICE_FINGERPRINTING,
			linkText: 'Device Fingerprinting',
		}, {
			id: 1,
			title: 'User account/s with multiple access levels',
			startupPrice: '1',
			smallPrice: '3',
			mediumPrice: '3',
		}, {
			id: 2,
			title: '{link} Generation technology with the monthly limit of Unique Card IDs',
			titleDescription: 'Unique Card ID means a unique hash for each PAN number or card that would be stored '
				+ 'in our PCI DSS level 1 secure database. With this technology you can spot already seen cards with '
				+ '100% accuracy.',
			startupPrice: 'no limit',
			smallPrice: 'no limit',
			mediumPrice: 'no limit',
			linkTo: URL.COVERY_DOCS_DEVICE_FINGERPRINTING,
			linkText: 'Card ID',
			isExternalLink: true,
		}, {
			id: 3,
			title: 'Access to the industry/s in a reputation database {link}',
			startupPrice: '1',
			smallPrice: '1',
			mediumPrice: '3',
			linkTo: URL.PRODUCTS_TRUSTCHAIN,
			linkText: 'Trustchain',
		}, {
			id: 4,
			title: 'Free trial for 30 days with unlimited number of  Decision API calls',
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 5,
			title: 'Free integration',
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 6,
			title: 'Free proof of concept',
			subtitles: [
				{
					id: 1,
					subtitle: 'offline',
					subtitleDescription: 'Does not include API integration based on raw data (CSV, XLS files).',
				}, {
					id: 2,
					subtitle: 'online',
					subtitleDescription: 'Includes direct API integration with Covery.',
				},
			],
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 7,
			title: 'Online support',
			subtitles: [
				{
					id: 1,
					subtitle: 'free educational training',
				}, {
					id: 2,
					subtitle: 'basic risk logic set up',
					subtitleDescription: 'We will help you to set up general risk logic based on your needs.',
				}, {
					id: 3,
					subtitle: '3h/month of risk analyst',
					subtitleDescription: 'Use this time for training sessions, and resolving technical or risk logic '
						+ 'issues.  In case of exceeding the monthly limit of 3 hours, 40 EUR per each extra hour would '
						+ 'be applied to your next monthly invoice.',
				},
			],
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		}, {
			id: 8,
			title: 'Included modules',
			subtitles: [
				{
					id: 1,
					subtitle: 'Reports module',
					subtitleDescription,
				}, {
					id: 2,
					subtitle: 'Features module',
					subtitleDescription: 'Make any simple and complex calculations and aggregations (velocity check, '
						+ 'dymanic thesholds, etc.)',
				}, {
					id: 3,
					subtitle: 'Scoring module',
					subtitleDescription: 'Create any kind of rules and scenarios per specific business need, product, '
						+ 'GEO, traffic source, MID or any other purpose.',
				},
			],
			startupPrice: '+',
			smallPrice: '+',
			mediumPrice: '+',
		},
	],
};

export const smbPrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly fee',
			startupPrice: '€1,000',
			smallPrice: '€1,750',
			mediumPrice: '€2,250',
		}, {
			id: 1,
			title: 'Annual fee (20% discount)',
			startupPrice: '€9,600',
			smallPrice: '€16,800',
			mediumPrice: '€21,600',
		}, {
			id: 2,
			title: 'Monthly limit (decision API calls)',
			titleDescription:
				'In case of exceeding the monthly limit of the Decision API calls, each extra call would be charged '
				+ 'according to your pricing plan.Event API endpoint is mostly used for the historical data transferring, '
				+ 'or sending us real-time user’s data without a need to get a decision from Covery. There are no monthly '
				+ 'limits applied to the Event API calls.',
			startupPrice: '15,000',
			smallPrice: '35,000',
			mediumPrice: '150,000',
		},
	],
};
// Pricing Anti-Fraud SMB Page Data End

// Pricing Device Fingerprinting Page Data Start
export const fingerprintingPlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Basic',
			description: 'Relevant for small size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€450',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€4,320',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,005',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,004',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '100K UID/month',
				},
			],
		}, {
			id: 1,
			title: 'Standard',
			description: 'Suitable for medium size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€1,350',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€12,960',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0009',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,0007',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '1,5M UID/month',
				},
			],
		}, {
			id: 2,
			title: 'Advanced',
			description: 'Relevant for enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€3,500',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€33,600',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,00035',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,00028',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '10M UID/month',
				},
			],
		},
	],
};

export const fingerprintingPrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly',
			startupPrice: '€450',
			smallPrice: '€1,350',
			mediumPrice: '€3,500',
		}, {
			id: 1,
			title: 'Annual (20% discount)',
			startupPrice: '€4,320',
			smallPrice: '€12,960',
			mediumPrice: '€33,600',
		}, {
			id: 2,
			title: 'Price per overlimit (monthly)',
			startupPrice: '€0,005',
			smallPrice: '€0,0009',
			mediumPrice: '€0,00035',
		}, {
			id: 3,
			title: 'Price per overlimit (annual)',
			startupPrice: '€0,004',
			smallPrice: '€0,0007',
			mediumPrice: '€0,00028',
		}, {
			id: 4,
			title: 'Monthly limit',
			startupPrice: '100K UID/month',
			smallPrice: '1.5M UID/month',
			mediumPrice: '10M UID/month',
		},
	],
};

export const fingerprintingDescription = {
	paragraphs: [
		{
			id: 0,
			text: 'Device Fingerprinting Technology designed by Covery.',
			isBold: true,
		}, {
			id: 1,
			text: 'We use JavaScript script to collect unlimited data points during the user journey and analyze'
				+ ' potential risks to return back a hash each unique device in the real-time. Unique device means'
				+ ' 1 user which can be detected one or more than one time during 24 hours. We count the number'
				+ ' of unique device fingerprints on a daily basis.',
		}, {
			id: 2,
			text: 'Implement this technology and secure yourself from:',
			isBold: true,
			items: [
				{
					id: 0,
					text: 'Application fraud',
				}, {
					id: 1,
					text: 'Payment fraud',
				}, {
					id: 2,
					text: 'Account takeovers',
				}, {
					id: 3,
					text: 'Bot attacks',
				}, {
					id: 4,
					text: 'Compromised accounts',
				}, {
					id: 5,
					text: 'Emulated users and devices',
				},
			],
		},
	],
};
// Pricing Device Fingerprinting Page Data End

// Pricing Device Screening Page Data Start
export const deviceScreeningPlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Basic',
			description: 'Relevant for small size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€1,650',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€15,840',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0033',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,003',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '500K',
				},
			],
		}, {
			id: 1,
			title: 'Standard',
			description: 'Suitable for medium size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€3,150',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€30,240',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0021',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,0017',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '1,5M',
				},
			],
		}, {
			id: 2,
			title: 'Advanced',
			description: 'Relevant for enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€6,500',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€62,400',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0013',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,001',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '5M',
				},
			],
		},
	],
};

export const deviceScreeningPrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly',
			startupPrice: '€1,650',
			smallPrice: '€3,150',
			mediumPrice: '€6,500',
		}, {
			id: 1,
			title: 'Annual (20% discount)',
			startupPrice: '€15,840',
			smallPrice: '€30,240',
			mediumPrice: '€62,400',
		}, {
			id: 2,
			title: 'Price per overlimit (monthly)',
			startupPrice: '€0,0033',
			smallPrice: '€0,0021',
			mediumPrice: '€0,0013',
		}, {
			id: 3,
			title: 'Price per overlimit (annual)',
			startupPrice: '€0,003',
			smallPrice: '€0,0017',
			mediumPrice: '€0,001',
		}, {
			id: 4,
			title: 'Monthly limit',
			startupPrice: '500K',
			smallPrice: '1.5M',
			mediumPrice: '5M',
		},
	],
};

export const deviceScreeningDescription = {
	paragraphs: [
		{
			id: 0,
			text: 'Device Screening is a combination of Device Fingerprinting Technology with AI risk assessment tool.',
			isBold: true,
		}, {
			id: 1,
			text: 'Device Screening gives a reputation of a specific device, its geolocation, and a reason with fraud triggers:',
			items: [
				{
					id: 0,
					text: 'Proxy',
				}, {
					id: 1,
					text: 'Emulation',
				}, {
					id: 2,
					text: 'Tor',
				}, {
					id: 3,
					text: 'AdBlock',
				}, {
					id: 4,
					text: 'Not valid country',
				}, {
					id: 5,
					text: 'and many others',
				},
			],
		}, {
			id: 2,
			text: 'Implement Device Screening to receive:',
			isBold: true,
			items: [
				{
					id: 0,
					text: 'IP and Device Fingerprinting reputation from Trustchain (Trusted, Untrusted, Suspicious, Neutral)',
				}, {
					id: 1,
					text: 'IP properties (country, service provider; type: private, public, anonymus, corporate)',
				}, {
					id: 2,
					text: 'Device properties (OS, language, screen resolution, IP, plugins, etc.)',
				},
			],
		},
	],
};
// Pricing Device Screening Page Data End

// Pricing Ip Screening Page Data Start
export const ipScreeningPlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Basic',
			description: 'Relevant for small size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€1,000',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€9,600',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,002',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,002',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '500K',
				},
			],
		}, {
			id: 1,
			title: 'Standard',
			description: 'Suitable for medium size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€2,250',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€21,600',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0015',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,0012',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '1,5M',
				},
			],
		}, {
			id: 2,
			title: 'Advanced',
			description: 'Relevant for enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€4,500',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€43,200',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0009',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,0007',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '5M',
				},
			],
		},
	],
};

export const ipScreeningPrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly',
			startupPrice: '€1,000',
			smallPrice: '€2,250',
			mediumPrice: '€4,500',
		}, {
			id: 1,
			title: 'Annual (20% discount)',
			startupPrice: '€9,600',
			smallPrice: '€21,600',
			mediumPrice: '€43,200',
		}, {
			id: 2,
			title: 'Price per overlimit (monthly)',
			startupPrice: '€0,002',
			smallPrice: '€0,0015',
			mediumPrice: '€0,0009',
		}, {
			id: 3,
			title: 'Price per overlimit (annual)',
			startupPrice: '€0,002',
			smallPrice: '€0,0012',
			mediumPrice: '€0,0007',
		}, {
			id: 4,
			title: 'Monthly limit',
			startupPrice: '500K',
			smallPrice: '1.5M',
			mediumPrice: '5M',
		},
	],
};

export const ipScreeningDescription = {
	paragraphs: [
		{
			id: 0,
			text: 'Our IP Screening is a combination of AI risk assessment tools and global database Trustchain.',
			isBold: true,
		}, {
			id: 1,
			text: 'IP Screening provides you with a reputation of IP with a specific reason and devices where we\'ve'
				+ ' tracked it before, thus, becomes one of the first steps in ensuring efficient fraud detection.',
		}, {
			id: 2,
			text: 'Implement IP Screening to receive:',
			isBold: true,
			items: [
				{
					id: 0,
					text: 'IP reputation lable from Trustchain (Trusted, Untrusted, Suspicious, Neutral)',
				}, {
					id: 1,
					text: 'IP properties (country, service provider; type: private, public, anonymus, corporate)',
				}, {
					id: 2,
					text: 'fraud flags (Do not track, Proxy, Different timezone, Anonymous, Different language, Bot)',
				},
			],
		},
	],
};
// Pricing Ip Screening Page Data End

// Pricing KYC Automation Page Data Start
export const kycAutomationPlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Basic',
			description: 'Relevant for small size companies',
			linkText: 'Tell me more',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€1,530',
				}, {
					id: 1,
					subtitle: 'Monthly checks limit',
					subtitleNumber: '1,000',
				}, {
					id: 2,
					subtitle: 'Price per check overlimit (monthly)',
					subtitleNumber: '€1,3',
				},
			],
		}, {
			id: 1,
			title: 'Standard',
			description: 'Suitable for medium size companies',
			linkText: 'Tell me more',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€12,750',
				}, {
					id: 1,
					subtitle: 'Monthly checks limit  ',
					subtitleNumber: '10,000',
				}, {
					id: 2,
					subtitle: 'Price per check overlimit (monthly)',
					subtitleNumber: '€1',
				},
			],
		}, {
			id: 2,
			title: 'Advanced',
			description: 'Relevant for enterprises',
			linkText: 'Tell me more',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€23,400',
				}, {
					id: 1,
					subtitle: 'Monthly checks limit',
					subtitleNumber: '25,000',
				}, {
					id: 2,
					subtitle: 'Price per check overlimit (monthly)',
					subtitleNumber: '€0,85',
				},
			],
		},
	],
};

export const kycAutomationPrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly',
			startupPrice: '€1,530',
			smallPrice: '€12,750',
			mediumPrice: '€23,400',
		}, {
			id: 1,
			title: 'Monthly checks limit',
			startupPrice: '1,000',
			smallPrice: '10,000',
			mediumPrice: '25,000',
		}, {
			id: 2,
			title: 'Price per check overlimit (monthly)',
			startupPrice: '€1,3',
			smallPrice: '€1',
			mediumPrice: '€0,85',
		},
	],
};

export const kycAutomationDescription = {
	paragraphs: [
		{
			id: 0,
			text: 'KYC Automation helps businesses to comply with KYC, AML, GDPR, PSD2 regulations, and save '
				+ 'operational resources.',
			isBold: true,
		}, {
			id: 1,
			text: 'Covery automates Know Your Customer and Anti Money Laundering procedures by implementing ongoing '
				+ 'monitoring that analyzes customer journey stages and checks your customers daily through '
				+ 'International databases with PEPs, sanctions and other watch lists updating every day.',
		}, {
			id: 2,
			text: 'Implement this technology and secure yourself from:',
			isBold: true,
			items: [
				{
					id: 0,
					text: 'Money laundering',
				}, {
					id: 1,
					text: 'Payment fraud',
				}, {
					id: 2,
					text: 'Account takeovers',
				}, {
					id: 3,
					text: 'Identity thefts',
				}, {
					id: 4,
					text: 'CNP and credit card fraud',
				}, {
					id: 5,
					text: 'SEPA credit transfer and direct debit fraud',
				},
			],
		},
	],
};
// Pricing KYC Automation Page Data End

// Pricing Trustchain Page Data Start
export const trustchainPlans = {
	title: 'Plans',
	options: [
		{
			id: 0,
			title: 'Basic',
			description: 'Relevant for small size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€750',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€7,200',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0015',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,006',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '500K',
				},
			],
		}, {
			id: 1,
			title: 'Standard',
			description: 'Suitable for medium size companies',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€1,350',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€12,960',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,0009',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,0007',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '1,5M',
				},
			],
		}, {
			id: 2,
			title: 'Advanced',
			description: 'Relevant for enterprises',
			prices: [
				{
					id: 0,
					subtitle: 'Monthly',
					subtitleNumber: '€2,400',
				}, {
					id: 1,
					subtitle: 'Annual(20% discount)',
					subtitleNumber: '€23,040',
				}, {
					id: 2,
					subtitle: 'Price per overlimit (monthly)',
					subtitleNumber: '€0,00048',
				}, {
					id: 3,
					subtitle: 'Price per overlimit (annual)',
					subtitleNumber: '€0,00019',
				}, {
					id: 4,
					subtitle: 'Monthly limit',
					subtitleNumber: '5M',
				},
			],
		},
	],
};

export const trustchainPrices = {
	title: 'Prices',
	options: [
		{
			id: 0,
			title: 'Monthly',
			startupPrice: '€750',
			smallPrice: '€1,350',
			mediumPrice: '€2,400',
		},
		{
			id: 1,
			title: 'Annual (20% discount)',
			startupPrice: '€7,200',
			smallPrice: '€12,960',
			mediumPrice: '€23,040',
		},
		{
			id: 2,
			title: 'Price per overlimit (monthly)',
			startupPrice: '€0,0015',
			smallPrice: '€0,0009',
			mediumPrice: '€0,00048',
		},
		{
			id: 3,
			title: 'Price per overlimit (annual)',
			startupPrice: '€0,006',
			smallPrice: '€0,0007',
			mediumPrice: '€0,00019',
		},
		{
			id: 4,
			title: 'Monthly limit',
			startupPrice: '500K',
			smallPrice: '1.5M',
			mediumPrice: '5M',
		},
	],
};

export const trustchainDescription = {
	paragraphs: [
		{
			id: 0,
			text: 'Trustchain is a global knowledge database with more than 300M reputation records '
				+ ' updating in real-time.',
			isBold: true,
		}, {
			id: 1,
			text: 'Reputations inside Trustchain have 11 identifiers:',
			withTwoColumn: true,
			items: [
				{
					id: 0,
					text: 'email',
				}, {
					id: 1,
					text: 'phone',
				}, {
					id: 2,
					text: 'IP',
				}, {
					id: 3,
					text: 'domain',
				}, {
					id: 4,
					text: 'system account IP',
				}, {
					id: 5,
					text: 'card ID',
				}, {
					id: 6,
					text: 'device ID',
				}, {
					id: 7,
					text: 'device fingerprint',
				}, {
					id: 8,
					text: 'entity ID',
				}, {
					id: 9,
					text: 'person ID',
				}, {
					id: 10,
					text: 'IBAN',
				}, {
					id: 11,
					text: 'SWIFT',
				},
			],
		}, {
			id: 2,
			text: 'Implement Trustchain to receive:',
			isBold: true,
			items: [
				{
					id: 0,
					text: 'user reputation (Trusted, Untrusted, Suspicious, Neutral)',
				}, {
					id: 1,
					text: 'source of the reputation (Payment fraud, Marketing fraud, or other fraud flags)',
				}, {
					id: 2,
					text: 'industry of reputation (we store data from 23+ industries)',
				}, {
					id: 3,
					text: '"first seen" and "change of reputation" dates',
				},
			],
		},
	],
};
// Pricing Trustchain Page Data End

export const navigationLinks = [
	{
		id: 1,
		text: 'Anti-fraud: SMB',
		src: URL.PRICING,
	}, {
		id: 2,
		text: 'Anti-fraud: Enterprise',
		src: URL.PRICING_ENTERPRISES,
	}, {
		id: 3,
		text: 'Device Fingerprinting',
		src: URL.PRICING_DF,
	}, {
		id: 4,
		text: 'Trustchain',
		src: URL.PRICING_TRUSTCHAIN,
	}, {
		id: 5,
		text: 'IP Screening',
		src: URL.PRICING_IP_SCREENING,
	}, {
		id: 6,
		text: 'Device Screening',
		src: URL.PRICING_DEVICE_SCREENING,
	}, {
		id: 7,
		text: 'KYC Automation',
		src: URL.PRICING_KYC_AUTOMATION,
	},
];
