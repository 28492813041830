import React from 'react';
import Comics from '../components/Comics';
import {pages, contact} from '../mocks/zamazon';
import pdf from '../pdf/zamazon-comics.pdf';
import ComicsContact from '../components/ComicsContact';

export default function ZamazonContainer() {
	return (
		<Comics
			type="twenty"
			pages={pages}
			pdf={pdf}
		>
			<ComicsContact {...contact} />
		</Comics>
	);
}
