const joinRules = (rules, allValues) => (value) => (
	rules.map((rule) => rule(value, allValues)).find(Boolean)
);

export const createValidator = (rules) => (values) => {
	const errors = {};
	Object.keys(rules).forEach((key) => {
		const concatRules = [].concat(rules[key]);
		const rule = joinRules(concatRules, values);
		const error = rule(values[key]);
		if (error) {
			errors[key] = error;
		}
	});

	return errors;
};
