import React from 'react';
import ChainLeft from '../inline/chain-left.svg';
import BindersLeft from '../inline/binders-left.svg';
import './ChainsBlock.styl';

export default function ChainsBlock(props) {
	return (
		<div className={`chain-img chain-img_left ${props.type}`}>
			<ChainLeft className="chain-img__icon" />
			<BindersLeft />
		</div>
	);
}
