import React from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import IconIgamingTrigger from './inline/igaming-trigger.svg';
import IconIgamingTriggerMobile from './inline/igaming-trigger-mobile.svg';
import './igaming-trigger.styl';

export default function IgamingTrigger({title, subtitle, items}) {
	return (
		<section className="trigger">
			<Container>
				<Heading level="title-medium" color="blue">
					{title}
				</Heading>
				<p className="trigger__subheader">
					{subtitle}
				</p>
				<div className="mobile-show">
					<ul className="trigger__list">
						{items.map(({item, id}) => (
							<li className="trigger__item" key={id}>
								{item}
							</li>
						))}
					</ul>
					<figure className="trigger__image 6">
						<IconIgamingTriggerMobile className="trigger__icon" />
					</figure>
				</div>
				<figure className="mobile-hide trigger__image">
					<IconIgamingTrigger className="trigger__icon" />
				</figure>
			</Container>
		</section>
	);
}
