import React from 'react';
import {useBreakpoint} from 'gatsby-plugin-breakpoints';
import Accordion from 'modules/accordion/components/Accordion';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import MenuList from './MenuList';
import './FooterMenu.styl';

export function FooterMenu(props) {
	const breakpoints = useBreakpoint();
	const {
		item,
	} = props;

	return (
		<ErrorBoundary>
			{!breakpoints.md
				? (
					<Accordion modificator="footer">
						{item.map((group) => (
							<>
								<h5 className="footer-menu-title">
									{group.title}
								</h5>
								<MenuList items={group.children} modificator="accordion" />
							</>
						))}
					</Accordion>
				)
				: <MenuList items={item} modificator="footer" />
			}
		</ErrorBoundary>
	);
}
