import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import './CardsList.styl';

export default function CardsList(props) {
	const {
		type,
		items,
	} = props;
	return (
		<ul className="cards-list">
			{items.map(({id, title, description, iconSrc, link}) => (
				<li className={`cards-list__item cards-list__item_${type}`} key={id}>
					{iconSrc && (
						<img
							src={iconSrc.publicURL}
							className="cards-list__icon"
							alt={title}
						/>
					)}
					<Heading className="cards-list__title" withIndented>
						{title}
					</Heading>
					<p className="cards-list__text">
						{description}
					</p>
					{link && (
						<Link
							to={link.to}
							appearance={link.appearance}
							className="cards-list__link"
							alignCenter
						>
							{link.text}
						</Link>
					)}
				</li>
			))}
		</ul>
	);
}
