import React from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import Link from 'ui/controls/Link/Link';
import FeaturesList from './FeaturesList';
import './Features.styl';

export default function Features(props) {
	const {
		title,
		subtitle,
		link,
		linkTo,
		linkAppearance,
		items,
		marksColor,
		boxModificator,
		isInversed,
		postfix,
	} = props;
	return (
		<Box modificator={boxModificator}>
			<Container className="features">
				<Heading
					color={isInversed ? 'white' : ''}
					level="title-medium"
					isCentered
					className="features__title"
					withIndented={!subtitle}
				>
					{title}
				</Heading>
				{subtitle && <p className="features__subtitle">{subtitle}</p>}
				{items && (
					<FeaturesList
						items={items}
						link={link}
						marksColor={marksColor}
						isInversed={isInversed}
					/>
				)}
				{link && (
					<Link
						to={linkTo}
						appearance={linkAppearance}
						alignCenter
					>
						{link}
					</Link>
				)}
				{postfix && (
					<Heading
						level="title-small"
						isCentered
						className="features__postfix"
						color={isInversed ? 'white' : null}
					>
						{postfix}
					</Heading>
				)}
			</Container>
		</Box>
	);
}
