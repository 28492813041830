import {
	ipScreening,
	deviceScreening,
} from '../constants/demoFlow';

export const getItemTypeByType = (type) => {
	switch (type) {
		case ipScreening:
			return 'ip';
		case deviceScreening:
			return 'device_fingerprint';
	}
};
