import React from 'react';
import Tooltip from './Tooltip';
import LinkFinder from './LinkFinder';
import 'modules/layouts/components/Pricing.styl';

export default function PlanItems(props) {
	const {features, items} = props;
	return (
		<>
			{features.options.map((item) => (
				<div key={item.id} className="pricing-page-plans__row pricing-page-plans__row_mobile">
					<div className="pricing-page-plans__subtitle">
						<LinkFinder options={item} />
						{item.titleDescription
							&& <Tooltip description={item.titleDescription} />
						}
						{item.subtitles
							&& <ul className="pricing-page-features-list">
								{item.subtitles.map((item) => (
									<li className="pricing-page-features-list__item" key={item.id}>
										<p className="pricing-page-features-list__text">{item.subtitle}</p>
										{item.subtitleDescription
											&& <Tooltip description={item.subtitleDescription} />
										}
									</li>
								))}
          </ul>
						}
					</div>
					{items.id === 0
					&& <>
						{item.startupPrice === '+'
							? <span className="pricing-page-features__mark" />
							: <p className="pricing-page-prices__price">{item.startupPrice}</p>
						}
        </>
					}
					{items.id === 1
					&& <>
						{item.smallPrice === '+'
							? <span className="pricing-page-features__mark" />
							: <p className="pricing-page-prices__price">{item.smallPrice}</p>
						}
        </>
					}
					{items.id === 2
					&& <>
						{item.mediumPrice === '+'
							? <span className="pricing-page-features__mark" />
							: <p className="pricing-page-prices__price">{item.mediumPrice}</p>
						}
        </>
					}
				</div>
			))}
		</>
	);
}
