import React, {Fragment} from 'react';
import Accordion from 'modules/accordion/components/Accordion';
import Heading from 'ui/typography/Heading/Heading';
import {Container} from 'ui/container/Container';
import FaqList from './FaqList';
import './Faq.styl';

export default function Faq({title, items}) {
	return (
		<Container>
			<Heading
				color="white"
				level="title-medium"
				isCentered
				withIndented
			>
				{title}
			</Heading>
			<Accordion modificator="faq">
				{items.map((item) => (
					<Fragment key={item.title}>
						<Heading
							className="faq__title"
							key={item.title}
							level="title-small"
							modificator="margin"
						>
							{item.title}
						</Heading>
						<FaqList items={item.paragraphs} />
					</Fragment>
				))}
			</Accordion>
		</Container>
	);
}
