import React, {PureComponent} from 'react';
import Box from 'ui/box/Box';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';

export default class ErrorBoundary extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, ErrorInfo) {
		this.setState({
			hasError: true,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<Box>
					<Container>
						<Heading level="title-huge" isCentered>Something went wrong</Heading>
					</Container>
				</Box>
			);
		}

		return this.props.children;
	}
}
