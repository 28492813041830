import {
	ipScreening,
	trustchain,
	deviceScreening,
} from '../constants/demoFlow';

const getTrustchainData = (data) => {
	const {trustchain} = data;
	const {global, industries} = trustchain;
	return {global, industries};
};

const getIpScreeningData = (data) => {
	const {ipScreening} = data;
	const {reputation} = ipScreening;
	const {global, industries} = reputation;
	return {global, industries};
};

const getDeviceScreeningData = (data) => {
	const {deviceScreening} = data;
	const {ipReputation} = deviceScreening;
	const {global, industries} = ipReputation;
	return {global, industries};
};

export const getResponseDataByType = (data, type) => {
	switch (type) {
		case trustchain:
			return getTrustchainData(data);
		case ipScreening:
			return getIpScreeningData(data);
		case deviceScreening:
			return getDeviceScreeningData(data);
	}
};
