import React from 'react';
import './analyze-list.styl';

export default function AnalyzeList({items}) {
	return (
		<ul className="analyze-list">
			{items.map(({item, id}) => (
				<li className="analyze-list__item" key={id}>
					{item}
					<span className="analyze-list__item-point" />
				</li>
			))}
		</ul>
	);
}
