import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import {Stats} from 'modules/industries/components/Stats';
import Slider from 'ui/slider/SliderReviews';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {BaseLayout} from 'modules/layouts/components/Base';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import AboutCovery from 'modules/aboutCovery/components/AboutCovery';
import Tiles from 'modules/tiles/components/Tiles';
import Pricing from 'modules/pricing/components/Pricing';
import {homePageTiles} from 'modules/tiles/tiles.mocks';
import CustomizablePlatform from 'components/CustomizablePlatform/CustomizablePlatform';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconIntro from 'modules/intro/inline/intro-illustation.svg';
import IconStats from 'modules/industries/inline/stats-man.svg';
import {Form} from 'modules/forms';
import 'ui/stylus/base/tmp.styl';

export default function Home({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo />
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper type="home" {...data?.introHomeJson}>
				<IconIntro />
			</IntroWrapper>
			<ErrorBoundary>
				<AboutCovery {...data?.aboutHomeJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Stats {...data?.statHomeJson} type="home" boxModificator="darker-blue">
					<IconStats />
				</Stats>
			</ErrorBoundary>
			<ErrorBoundary>
				<CustomizablePlatform {...data?.customizablePlatformJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Slider {...data?.reviewsSliderHomeJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Tiles {...homePageTiles} />
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.pricingHomeJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const homeDetails = graphql`
	query {
		introHomeJson {
			title
			subtitle {
				text
				color
			}
			isWide
			icon
		}
		aboutHomeJson {
			title
			description
			link
			linkTo
			linkAppearance
			items {
				title
				id
				image {
					source {
						publicURL
					}
					description
				}
				backgroundColor
				isReversed
				paragraphs {
					id
					text
				}
			}
		}
		statHomeJson {
			title
			isInversed
			subtitle
			icon
			itemsTitleColor
			leftItems {
				id
				amount
				title
				description
			}
			rightItems{
				id
				title
				description
			}
		}
		customizablePlatformJson {
			title
			description
		}
		reviewsSliderHomeJson {
			title
			slidesData {
				id
				name
				text
				position
			}
		}
		pricingHomeJson {
			title
			subtitle
		}
	}
`;
