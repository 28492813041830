import React from 'react';
import './FaqList.styl';

export default function FaqList({items}) {
	return (
		<ul className="faq-list">
			{items.map(({description, priority}) => (
				<li className="faq-list__item" key={priority}>{description}</li>
			))}
		</ul>
	);
}
