import React from 'react';
import {Link} from 'react-scroll';
import {OFFSET_TOP} from '../legal.constants';
import './Sidebar.styl';

export default function Sidebar({headings}) {
	return (
		<nav className="legal-nav">
			{headings.map((item) => (
				<Link
					className="legal-nav__link"
					activeClass="legal-nav__link_active"
					to={`${item.id}`}
					href={`#${item.id}`}
					offset={-OFFSET_TOP}
					spy
					hashSpy
					key={item.id}
				>
					{item.value}
				</Link>
			))}
		</nav>
	);
}
