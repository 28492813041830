import React, {useCallback, cloneElement} from 'react';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';
import VipRadarList from './VipRadarList';
import './VipRadar.styl';

export default function Showcase(props) {
	const {
		title,
		subtitle,
		withTopHeading,
		isInversed,
		isReversed,
		children,
	} = props;

	const renderHeading = useCallback(() => (
		<div className="vip-radar__heading">
			<Heading color={isInversed ? 'white' : ''} level="title-medium">{title}</Heading>
			<p className="vip-radar__subtitle">{subtitle}</p>
		</div>
	), [isInversed, title, subtitle]);

	return (
		<div className={cx('vip-radar container', {'vip-radar_inversed': isInversed})}>
			<div className="vip-radar__content">
				<div
					className={cx('vip-radar__inner', {
						'vip-radar__inner_reversed': isReversed,
					})}
				>
					{!withTopHeading && renderHeading()}
					<VipRadarList data={props} />
				</div>
				{cloneElement(children, {className: 'vip-radar__icon'})}
			</div>
		</div>
	);
}
