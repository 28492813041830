import {isBrowser} from 'helpers/isBrowser';

export const createLinkedinScripts = () => {
	if (!isBrowser() || window.location.hostname === 'localhost') return;
	const scriptInline = document.createElement('script');
	const noscript = document.createElement('noscript');
	const id = process.env.GATSBY_LINKEDIN_INSIGHT_ID;

	document.head.prepend(scriptInline);
	scriptInline.innerHTML = `_linkedin_partner_id = "${id}"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id); (function(){var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})();`;
	scriptInline.type = 'text/javascript';
	document.body.prepend(noscript);
	noscript.innerHTML = `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${id}&fmt=gif" />`;
};
