import {trustchainValidator} from '../validators/trustchain';
import {ipScreeningValidator} from '../validators/ipScreening';
import {deviceScreeningValidator} from '../validators/deviceScreening';
import {
	ipScreening,
	trustchain,
	deviceScreening,
} from '../constants/demoFlow';

export const getValidatorByType = (type) => {
	switch (type) {
		case trustchain:
			return trustchainValidator;
		case ipScreening:
			return ipScreeningValidator;
		case deviceScreening:
			return deviceScreeningValidator;
	}
};
