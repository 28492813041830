import {useEffect} from 'react';
import {loadFingerprint, handleCoveryFpKey} from 'helpers/deviceFingerprint';
import {isBrowser} from 'helpers/isBrowser';

export const useDeviceFingerprint = () => {
	useEffect(() => {
		const {sessionStorage} = window;
		if (isBrowser()) {
			window.handleCoveryFpKey = handleCoveryFpKey;
		}
		if (!sessionStorage.getItem('deviceFingerprint')) {
			loadFingerprint();
		}
	}, []);
};
