import React from 'react';
import Link from 'ui/controls/Link/Link';
import Input from './Input';
import './CheckboxesList.styl';

export function CheckboxList({form, checkboxesList}) {
	const {
		checkboxes,
		link,
		text,
	} = checkboxesList;

	return (
		<form onSubmit={form.handleSubmit}>
			<ul className="popup-cookie-checkbox-list">
				{checkboxes.map(({name, value, isDisabled, isChecked, id}) => (
					<li className="popup-cookie-checkbox-list__item" key={id}>
						<Input
							name={name}
							value={value}
							onChange={form.handleChange}
							isDisabled={isDisabled}
							isChecked={isChecked}
						/>
					</li>
				))}
				<li className="popup-cookie-checkbox-list__item">
					{text}
					<Link to={link.to} className="popup-cookie-list__link"> {link.text} </Link>.
				</li>
			</ul>
		</form>
	);
}
