import React from 'react';
import Box from 'ui/box/color/Dating';
import Background from './inline/dating-wrapper-background.svg';
import IconDatingHearts from './inline/dating-hearts.svg';
import './DatingWrapper.styl';

export default function DatingWrapper({children}) {
	return (
		<Box>
			{children}
			<figure>
				<Background className="dating-wrapper-background" />
			</figure>
			<figure>
				<IconDatingHearts className="dating-wrapper-hearts" />
			</figure>
		</Box>
	);
}
