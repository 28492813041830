export const HOME = '/';
export const CONTACT = '/contact/';
export const SIGN_UP = '/signup/';
export const PRIVACY = '/privacy/';
export const RESOURCES = '/resources/';
export const TERMS = '/terms/';
export const COOKIE_POLICY = '/cookie-policy/';
export const PRODUCTS_TRUSTCHAIN = '/products/trustchain/';
export const PRODUCTS_IP_SCREENING = '/products/ip-screening/';
export const PRODUCTS_DEVICE_SCREENING = '/products/device-screening/';
export const PRODUCTS_DEVICE_FINGERPRINTING = '/products/device-fingerprinting/';
export const PRODUCTS_KYC = '/products/kyc/';
export const PRODUCTS_VMPI = '/products/vmpi-chargeback-prevention/';
export const PRODUCTS_ETHOCA = '/products/ethoca-chargeback-prevention/';
export const SOLUTIONS_ACCOUNT_TAKEOVER = '/solutions/account-takeover-protection/';
export const SOLUTIONS_CARD_NOT_PRESENT = '/solutions/card-not-present-fraud-protection/';
export const SOLUTIONS_SYNTHETIC_IDENTITY_PROTECTION = '/solutions/synthetic-identity-theft-protection/';
export const SOLUTIONS_VIP_RADAR = '/solutions/vip-radar/';
export const INDUSTRIES_ONLINE_SERVICES = '/industries/online-services/';
export const INDUSTRIES_DATING = '/industries/dating/';
export const INDUSTRIES_IGAMING = '/industries/igaming/';
export const INDUSTRIES_ECOMMERCE = '/industries/ecommerce/';
export const INDUSTRIES_PSP = '/industries/payment-providers/';
export const INDUSTRIES_EMI = '/industries/emi/';
export const PRICING = '/pricing/';
export const PRICING_ENTERPRISES = '/pricing/anti-fraud-enterprises/';
export const PRICING_DF = '/pricing/device-fingerprinting/';
export const PRICING_TRUSTCHAIN = '/pricing/trustchain/';
export const PRICING_IP_SCREENING = '/pricing/ip-screening/';
export const PRICING_DEVICE_SCREENING = '/pricing/device-screening/';
export const PRICING_KYC_AUTOMATION = '/pricing/kyc-automation/';
export const COMICS = '/comics/';
export const COMICS_INTRO = '/comics/intro/';
export const COMICS_CASINO = '/comics/casino/';
export const COMICS_ZAMAZON = '/comics/zamazon/';
export const COMICS_PAYTIO = '/comics/paytio/';
export const COMICS_VILLAIN = '/comics/villain-edition/';
export const CASE_STUDY = '/case-study/';
export const PARTNERS = '/partners/';
export const FACEBOOK = 'https://www.facebook.com/coveryai/';
export const TWITTER = 'https://twitter.com/CoveryAi/';
export const LINKEDIN = 'https://www.linkedin.com/company/covery-ai/';
export const BLOG_COVERY = 'https://blog.covery.ai/';
export const BLOG_COVERY_ARTICLE = `${BLOG_COVERY}covery-in-the-list-of-top-fraud-solution-providers/`;
export const BLOG_COVERY_GENOME_KYC = `${BLOG_COVERY}genome-automates-kyc-procedure-with-covery/`;
export const BLOG_COVERY_MAXPAY_MERCHANTS = `${BLOG_COVERY}individual-risk-logic-for-maxpay-merchants/`;
export const COVERY_ZENDESK = 'https://covery.zendesk.com/hc/en-us';
export const COVERY_DOCS = '/docs/';
export const COVERY_DOCS_DEVICE_FINGERPRINTING = `${COVERY_DOCS}#device-fingerprint`;
export const COVERY_STATUS = 'https://status.covery.ai/';
export const MY_COVERY_CONTACT_US = `${process.env.GATSBY_COVERY_PORTAL}/contactUs`;
export const MY_COVERY_LOGIN = `${process.env.GATSBY_COVERY_PORTAL}/login`;
export const ABOUT_COOKIES = 'https://www.aboutcookies.org/';
export const MAXPAY_CONTACT = 'https://maxpay.com/contact.html';
export const MAXPAY_PRIVACY = 'https://maxpay.com/privacy/';
export const GOOGLE_ADS_BLOCK = 'https://support.google.com/ads/answer/2662922?hl=en';
export const GOOGLE_ANALYTICS_BLOCK = 'https://tools.google.com/dlpage/gaoptout/';
export const GOOGLE_ADSSETTINGS = 'https://adssettings.google.com/';
export const GOOGLE_MANAGE_COOKIES = 'https://support.google.com/chrome/answer/95647?hl=en';
export const APPLE_COOKIES_DEPRECATED_LINK = 'https://support.apple.com/ru-ru/guide/mdm/mdmf7d5714d4/web';
export const FIREFOX_COOKIES_DEPRECATED_LINK = 'https://support.mozilla.org/en-US/kb/disable-third-party-cookies';
export const OPERA_COOKIES_DEPRECATED_LINK = 'http://www.opera.com/help/tutorials/security/cookies';
export const MICROSOFT_WINDOWS_10_DEPRECATED_LINK = 'https://support.microsoft.com/en-us/products/windows?os=windows-10';
export const COOKIES_SECURITY = 'http://www.opera.com/help/tutorials/security/cookies/';
