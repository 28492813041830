import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {BaseLayout} from 'modules/layouts/components/Base';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import OurPartners from 'modules/partners/components/OurPartners';
import PartnersFraud from 'modules/partners/components/PartnersFraud';
import PartnersReviews from 'modules/partners/components/PartnersReviews';
import PartnersStats from 'modules/partners/components/PartnersStats';
import Box from 'ui/box/Box';
import {Form} from 'modules/forms';
import Pricing from 'modules/pricing/components/Pricing';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

export default function Partners({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.partnersSeoJson.title}
					description={data?.partnersSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.intro} />
			<ErrorBoundary>
				<PartnersFraud {...data?.fraundPartnersJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<OurPartners {...data?.ourPartnersJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<PartnersStats {...data?.statsPartnersJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<PartnersReviews {...data?.reviewsPartnersJson} />
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.pricingPartnersJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const partnersDetails = graphql`
	query {
		partnersSeoJson {
			title
			description
		}
		intro: introPartnersJson {
			title
			subtitle {
				text
				color
			}
			link
			linkTo
			linkAppearance
			isCentered
		}
		fraundPartnersJson {
			title
			subtitle
			items {
				id
				text
			}
		}
		ourPartnersJson {
			title
			subtitle
			logos {
				id
				image {
					source {
						publicURL
					}
					description
				}
				websiteUrl
			}
		}
		statsPartnersJson {
			title
			subtitle
			statsData {
				id
				number
				text
				description
				withCurrency
			}
		}
		reviewsPartnersJson {
			title
			subtitle
			slidesData {
				id
				name
				text
				position
			}
		}
		pricingPartnersJson {
			title
			subtitle
		}
	}
`;
