import React from 'react';
import * as URL from 'constants/url.constants';
import {comicsIntroData} from '../mocks/intro';
import {slides} from '../mocks/slides';
import {villainPreview} from '../mocks/villain';
import Main from '../components/Main';

export default function MainSection() {
	return (
		<Main
			slides={slides}
			preview={villainPreview}
			intro={comicsIntroData}
			url={URL.CONTACT}
		/>
	);
}
