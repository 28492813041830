import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {BaseLayout} from 'modules/layouts/components/Base';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import Form from 'modules/forms/containers/FormSignup';

export default function Signup({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo title={data?.title} />
			</ErrorBoundary>
			<IntroWrapper isReversed isWide>
				<ErrorBoundary>
					<ScrollToTop />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form
						path={location.pathname}
						message={data?.message}
						form={data?.form}
					/>
				</ErrorBoundary>
			</IntroWrapper>
		</BaseLayout>
	);
}

export const signUpDetails = graphql`
	query {
		seoSignupJson {
			title
		}
		message: messageSuccessSignupJson {
					welcome
					bodyMessages {
						id
						text
					}
					footerMessages {
						id
						text
					}
					aboutMessage {
						text
						link
						linkTo
					}
				}
		form: formLayoutSignupJson {
				title
				text
				link {
					to
					text
				}
			}
	}
`;
