import React from 'react';
import Comics from '../components/Comics';
import {pages, contact} from '../mocks/paytio';
import pdf from '../pdf/paytio-comics.pdf';
import ComicsContact from '../components/ComicsContact';

export default function PaytioContainer() {
	return (
		<Comics
			type="fourteen"
			pages={pages}
			pdf={pdf}
		>
			<ComicsContact {...contact} />
		</Comics>
	);
}
