import React from 'react';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';
import StatsIcon from '../inline/partners-stats.svg';
import './PartnersStats.styl';

export default function PartnersStats(props) {
	const {
		title,
		subtitle,
		statsData,
	} = props;
	return (
		<section className="partners-stats">
			<Container className="partners-stats__inner">
				<Heading level="title-medium" isCentered color="white">
					{title}
				</Heading>
				<p className="partners-stats__text">
					{subtitle}
				</p>

				<div className="partners-stats__content">
					<StatsIcon className="partners-stats__icon" />
					<ul className="partners-stats-list">
						{statsData.map((item) => (
							<li className="partners-stats-list__item" key={item.id}>
								<p className={cx('partners-stats-list__count',
									{'partners-stats-list__count_with-currency': item.withCurrency})}
								>
									<span className="partners-stats-list__num">{item.number} </span>
									{item.text}
								</p>
								<p>{item.description}</p>
							</li>
						))}
					</ul>
				</div>
			</Container>
		</section>
	);
}
