import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {Notfound} from 'components/Notfound/Notfound';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {Page} from 'modules/layouts/components/Page';

export default function NoMatch({data}) {
	return (
		<Page>
			<ErrorBoundary>
				<Seo title={data?.notFoundJson.title} />
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<ErrorBoundary>
				<Notfound data={data?.notFoundJson} />
			</ErrorBoundary>
		</Page>
	);
}

export const notFoundDetails = graphql`
	query {
		notFoundJson {
			title
			description
			link {
				text
				to
				appearance
			}
		}
	}
`;
