export const toSentenceCase = (text) => (
	[...text].map((t, i) => {
		if (i === 0) {
			return t.toUpperCase();
		}
		if (t.toUpperCase() === t) {
			return ` ${t.toLowerCase()}`;
		}
		return t;
	}).join('')
);
