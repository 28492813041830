import React, {memo} from 'react';
import {Container} from 'ui/container/Container';
import Button from 'ui/controls/Button/Button';
import Heading from 'ui/typography/Heading/Heading';
import {generateUniqKey} from 'helpers/uniq';
import {handlerPagination} from './helpers/base';
import {useSlider} from './hooks';
import IconArrowLeft from './inline/arrow-left.svg';
import IconArrowRight from './inline/arrow-right.svg';
import './Slider.styl';
import './SliderDating.styl';

const parentClasses = ['slider-pagination', 'slider-pagination_dating'];
const itemClasses = ['slider-pagination-item', 'slider-pagination-item_dating'];

function SliderDating({slides}) {
	const options = {
		selector: '.siema-dating',
		loop: true,
		perPage: {
			'1024': 3,
		},
		onChange: handlerPagination(itemClasses[0]),
	};
	const {
		onNext,
		onPrev,
	} = useSlider(options, [parentClasses, itemClasses]);

	return (
		<section className="dating-features">
			<Container>
				<div className="slider-wrap slider-wrap-dating">
					<div className="slider slider-dating">
						<Heading level="title-medium" color="blue" isCentered>
							Our features
						</Heading>
						<div className="siema-dating">
							{slides.map((item) => (
								<div className="slider-item slider-item_dating" key={item.id}>
									<Heading color="coral" className="slider-title_dating">
										{item.title}
									</Heading>
									<ul className="slider-list slider-list_dating">
										{item.descriptions.map((description) => (
											<li
												key={generateUniqKey()}
												className="slider-list-item slider-list-item_dating"
											>
												{description}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
						<Button
							type="button"
							appearance="arrow"
							className="slider-button-prev slider-button-prev_dating"
							onClick={onPrev}
						>
							<IconArrowLeft />
						</Button>
						<Button
							type="button"
							appearance="arrow"
							className="slider-button-next slider-button-next_dating"
							onClick={onNext}
						>
							<IconArrowRight />
						</Button>
					</div>
				</div>
			</Container>
		</section>
	);
}

export default memo(SliderDating);
