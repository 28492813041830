import React, {useEffect, useState, useRef} from 'react';

export function Counter({seconds, callback, children}) {
	const timerRef = useRef(seconds);
	const [count, setCount] = useState(seconds);

	useEffect(() => {
		timerRef.current = setInterval(() => {
			setCount((counter) => counter - 1);
		}, 1000);
		return () => clearInterval(timerRef.current);
	}, [setCount]);

	useEffect(() => {
		if (count === 0) callback();
	}, [count, callback]);

	return children(count);
}
