import React, {useState, useCallback, useContext} from 'react';
import {useFormik} from 'formik';
import {isBrowser} from 'helpers/isBrowser';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {CookieContext} from '../cookie.context';
import {setCookie} from '../helpers/setCookie';
import {hasCookie} from '../helpers/hasCookie';
import {COOKIE_POLICY_ACCEPTED, IS_COUNTER_EXPIRED} from '../cookie.constants';
import {PopupCookie as PopupCookieView} from '../components/PopupCookie';
import {PopupWrapper} from '../components/PopupWrapper';
import {CheckboxList} from '../components/CheckboxesList';
import {ButtonsContainer} from '../components/ButtonsContainer';

export function PopupCookie(props) {
	const {
		list,
		checkboxes,
	} = props;

	const hasCookiePolicy = isBrowser() ? hasCookie(COOKIE_POLICY_ACCEPTED) : true;
	const {setStatistics, setAdvertising} = useContext(CookieContext);
	const [shouldHideCookies, setHideCookies] = useState(hasCookiePolicy);
	const [isCounterExpired, setCounterExpired] = useState(hasCookie(IS_COUNTER_EXPIRED));
	const [isSettingsOpen, setSettingsOpen] = useState(false);

	const setEssentialScripts = useCallback(() => {
		const expirationDate = new Date();
		expirationDate.setFullYear(expirationDate.getFullYear() + 1);
		setCookie(COOKIE_POLICY_ACCEPTED, JSON.stringify(true), expirationDate);
	}, []);

	const setAllScripts = useCallback(() => {
		setStatistics();
		setAdvertising();
		setEssentialScripts();
	}, [setAdvertising, setStatistics, setEssentialScripts]);

	const handleCounterExpired = useCallback(() => {
		setCounterExpired(true);
		setCookie(IS_COUNTER_EXPIRED, JSON.stringify(true));
	}, []);

	const handleToggleSettings = useCallback(() => {
		setSettingsOpen((isSettingsOpen) => !isSettingsOpen);
	}, []);

	const handleAcceptAllCookie = useCallback(() => {
		setHideCookies(true);
		setAllScripts();
	}, [setAllScripts]);

	const formik = useFormik({
		initialValues: {
			cookie: ['essential'],
		},
		onSubmit: (values) => {
			values.cookie.forEach((name) => {
				switch (name) {
					case 'essential': setEssentialScripts(); break;
					case 'advertising': setAdvertising(); break;
					case 'statistics': setStatistics(); break;
				}
			});
		},
	});

	const handleAcceptSelected = useCallback(async () => {
		setHideCookies(true);
		await formik.submitForm();
	}, [formik]);

	if (shouldHideCookies) return null;
	return (
		<PopupWrapper shouldDisplayBackdrop={!isCounterExpired}>
			<PopupCookieView
				isSettingsOpen={isSettingsOpen}
				isCounterExpired={isCounterExpired}
				handleToggleSettings={handleToggleSettings}
				handleCounterExpired={handleCounterExpired}
				list={list}
			>
				{isSettingsOpen && <CheckboxList checkboxesList={checkboxes} form={formik} />}
			</PopupCookieView>
			<ErrorBoundary>
				<ButtonsContainer
					isSettingsOpen={isSettingsOpen}
					onToggleSettings={handleToggleSettings}
					onAcceptAllCookie={handleAcceptAllCookie}
					onAcceptSelected={handleAcceptSelected}
				/>
			</ErrorBoundary>
		</PopupWrapper>
	);
}
