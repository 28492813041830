import React, {Component} from 'react';
import {Formik} from 'formik';
import Box from 'ui/box/Box';
import Button from 'ui/controls/Button/Button';
import Heading from 'ui/typography/Heading/Heading';
import {Container} from 'ui/container/Container';
import {validator} from '../validators/signup';
import LayoutSignUp from '../components/LayoutSignUp';
import FormComponent from '../components/FormSignUp';
import {sendToAnalytics} from '../helpers/analytics';
import MessageSuccessSignup from '../components/Message/MessageSuccessSignup';

export default class FormSignup extends Component {
	state = {
		firstName: '',
		formShow: true,
		errorMessage: undefined,
		hasError: false,
	};

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	refreshPage() {
		this.setState({
			firstName: '',
			formShow: true,
			errorMessage: undefined,
			hasError: false,
		});
	}

	handleSend = (values, { setSubmitting }) => {
		this.setState({errorMessage: undefined, firstName: values.first_name});
		const formData = new FormData();
		Object.keys(values).forEach((key) => {
			if (key !== 'checkbox' && key !== 'confirmPassword') {
				formData.append(key, values[key]);
			}
		});

		fetch(`${process.env.GATSBY_COVERY_PORTAL}/signup`, {
			method: 'POST',
			body: formData,
			mode: 'cors',
		})
			.then((response) => response.json())
			.then(this.fetchStatusHandler)
			.then((data) => {
				sendToAnalytics(this.props.path);
			})
			.catch((response) => {
				sendToAnalytics(this.props.path, true);
				this.setState({hasError: true});
				this.setState({errorMessage: response.message});
			})
			.finally(() => {
				setSubmitting(false);
				this.setState({formShow: false});
			});
	};

	fetchStatusHandler = (response) => {
		if (response.hasOwnProperty('error')) {
			throw new Error(response.error);
		} else {
			return response;
		}
	};

	render() {
		const {hasError, formShow, errorMessage} = this.state;
		const initialValues = {
			'g-recaptcha-response': '',
			first_name: '',
			last_name: '',
			email: '',
			company: '',
			website: '',
			industry: '',
			password: '',
			confirmPassword: '',
		};
		const {
			message,
			form,
		} = this.props;

		if (hasError) {
			return (
				<Box>
					<Container>
						<Heading isCentered level="title-huge" color="white">Oops something went wrong...</Heading>
						<Button className="link link_button" onClick={() => this.refreshPage()} type="button">BACK TO SIGN UP</Button>
					</Container>
				</Box>
			);
		}
		return (
			<div className="form-signup">
				{formShow
					? (
						<LayoutSignUp form={form}>
							<Formik
								initialValues={initialValues}
								onSubmit={this.handleSend}
								validate={validator}
								component={FormComponent}
							/>
						</LayoutSignUp>
					)
					: <MessageSuccessSignup message={message} firstName={this.state.firstName} />
				}
			</div>
		);
	}
}
