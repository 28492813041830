import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import Showcase from 'modules/solutions/components/Showcase';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Features from 'modules/features/components/Features';
import Cards from 'modules/cards/components/Cards';
import Pricing from 'modules/pricing/components/Pricing';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconShowcase from 'modules/solutions/inline/showcase-synthetic-identity.svg';
import IconSynthetic from 'modules/cards/inline/card-synthetic-identity.svg';

export default function SyntheticIdentityTheftProtection({data, location}) {
	const type = 'synthetic-identity';
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.syntheticIdentityTheftProtectionSeoJson.title}
					description={data?.syntheticIdentityTheftProtectionSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.syntheticIdentityTheftProtectionIntroJson} />
			<Box modificator="very-dark-blue">
				<ErrorBoundary>
					<Showcase {...data?.syntheticIdentityTheftProtectionShowcaseJson}>
						<IconShowcase />
					</Showcase>
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Features {...data?.syntheticIdentityTheftProtectionFeaturesJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Cards {...data?.accountTakeoverProtectionCardsJson} type={type}>
					<IconSynthetic />
				</Cards>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.syntheticIdentityTheftProtectionPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const syntheticIdentityTheftProtectionDetails = graphql`
	query {
		syntheticIdentityTheftProtectionIntroJson {
			title
			subtitle {
				color
				text
			}
			link
			linkTo
			linkAppearance
			isCentered
		}
		syntheticIdentityTheftProtectionShowcaseJson {
			title
			subtitle
			marksColor
			isInversed
			isReversed
			items {
				id
				text
			}
		}
		syntheticIdentityTheftProtectionFeaturesJson {
			title
			subtitle
			link
			linkTo
			linkAppearance
			marksColor
			items {
				title
				description
				id
			}
		}
		accountTakeoverProtectionCardsJson {
			title
			isInversed
			items {
				id
				description
				title
			}
		}
		syntheticIdentityTheftProtectionPricingJson {
			title
			subtitle
		}
		syntheticIdentityTheftProtectionSeoJson {
			title
			description
		}
	}
`;
