import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import Scale from 'modules/industries/components/Scale';
import {Stats} from 'modules/industries/components/Stats';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Fraud from 'modules/integrations/components/Fraud';
import Features from 'modules/features/components/Features';
import Pricing from 'modules/pricing/components/Pricing';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import IconStats from 'modules/industries/inline/stats-man.svg';

export default function OnlineServices({data, location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.onlineServicesSeoJson.title}
					description={data?.onlineServicesSeoJson.description}
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<ScrollToTop />
			</ErrorBoundary>
			<IntroWrapper {...data?.onlineServicesIntroJson} />
			<ErrorBoundary>
				<Fraud {...data?.onlineServicesFraudJson} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Features {...data?.onlineServicesFeaturesJson} />
			</ErrorBoundary>
			<Box modificator="integrations-scale">
				<ErrorBoundary>
					<Scale type="integrations" {...data?.onlineServicesScaleJson} />
				</ErrorBoundary>
			</Box>
			<ErrorBoundary>
				<Stats {...data?.onlineServicesStatsJson} type="integrations" boxModificator="darker-blue">
					<IconStats />
				</Stats>
			</ErrorBoundary>
			<Box>
				<ErrorBoundary>
					<Pricing {...data?.onlineServicesPricingJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Form path={location.pathname} />
				</ErrorBoundary>
			</Box>
		</BaseLayout>
	);
}

export const onlineServicesDetails = graphql`
	query {
		onlineServicesIntroJson {
			title
			linkTo
			linkAppearance
			link
			isReversed
			isCentered
			subtitle {
				color
				text
			}
		}
		onlineServicesFraudJson {
			title
			subtitle
			items {
				id
				text
			}
		}
		onlineServicesFeaturesJson {
			title
			subtitle
			marksColor
			linkTo
			linkAppearance
			link
			items {
				description
				title
				id
			}
		}
		onlineServicesScaleJson {
			title
			subtitle
			description
			items {
				id
				number
			}
		}
		onlineServicesStatsJson {
			title
			subtitle
			isInversed
			itemsTitleColor
			leftItems {
				amount
				description
				id
				title
			}
			rightItems {
				amount
				description
				id
				title
			}
		}
		onlineServicesPricingJson {
			title
			subtitle
		}
		onlineServicesSeoJson {
			title
			description
		}
	}
`;
