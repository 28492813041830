import React from 'react';
import cx from 'classnames';
import HelpBlock from './HelpBlock';
import Label from './Label';
import './FieldTextarea.styl';

export default function FieldTextarea(props) {
	const {
		field,
		form: { touched, errors },
		label,
		...rest
	} = props;

	return (
		<>
			<Label htmlFor={field.name}>
				{label}
			</Label>
			<textarea
				id={field.name}
				className={cx('field-textarea', {
					'field-textarea_error': touched[field.name] && errors[field.name],
				})}
				{...field}
				{...rest}
			/>
			{touched[field.name] && errors[field.name] && (
				<HelpBlock>
					{errors[field.name]}
				</HelpBlock>
			)}
		</>
	);
}
