import React from 'react';
import Link from 'ui/controls/Link/Link';
import './LabelCheckboxCustom.styl';

export function LabelCheckboxCustom(props) {
	const {
		linkTerms,
		linkPrivacy,
	} = props;

	return (
		<>By clicking Create account button you agree to our <Link to={linkTerms.to} className="field-checkbox__link">{linkTerms.text} </Link>
			and <Link to={linkPrivacy.to} className="field-checkbox__link">{linkPrivacy.text}</Link> (including Cookie policy)
			and confirm that you have read carefully and fully understand its provisions.
		</>
	);
}
