import * as React from 'react';
import cx from 'classnames';
import './Button.styl';

export default function Button(props) {
	const {
		type,
		onClick,
		children,
		className,
		isLoading,
		isDisabled,
		isSmall,
		appearance = '',
	} = props;

	return (
		<button
			// eslint-disable-next-line react/button-has-type
			type={type}
			onClick={onClick}
			disabled={isDisabled}
			className={cx('button', className, {
				'button_transparent-text': isLoading,
				button_small: isSmall,
				[`button_${appearance}`]: appearance,
				button_disabled: isDisabled,
			})}
		>
			{children}
		</button>
	);
}
