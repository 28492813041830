import * as React from 'react';
import cx from 'classnames';
import './Heading.styl';

Heading.defaultProps = {
	level: 'title-default',
	className: undefined,
	isInversed: false,
	isCentered: false,
	isCoral: false,
	isBlue: false,
	isCyan: false,
	isBrightCyan: false,
	withIndented: false,
	tag: false,
};

export default function Heading(props) {
	const {
		children,
		className,
		level,
		color,
		isCentered,
		withIndented,
		tag,
		modificator,
	} = props;

	const modificators = {
		title: (!!level || !!color || isCentered || withIndented),
		title_huge: level === 'title-huge',
		title_medium: level === 'title-medium',
		title_default: level === 'title-default',
		title_small: level === 'title-small',
		title_color_white: color === 'white',
		title_color_blue: color === 'blue',
		title_color_coral: color === 'coral',
		title_color_cyan: color === 'cyan',
		'title_color_bright-cyan': color === 'bright-cyan',
		title_indented: withIndented,
		title_centered: isCentered,
		title_margin_less: modificator === 'margin',
	};

	if (tag) {
		return React.createElement(tag, {className: cx(modificators, className)}, children);
	}
	switch (level) {
		case 'title-huge':
			return <h1 className={cx(modificators, className)}>{children}</h1>;
		case 'title-medium':
			return <h2 className={cx(modificators, className)}>{children}</h2>;
		case 'title-small':
			return <h4 className={cx(modificators, className)}>{children}</h4>;
		default:
			return <h3 className={cx(modificators, className)}>{children}</h3>;
	}
}
