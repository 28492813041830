/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {CookieProvider} from './src/modules/cookies/containers/CookieProvider';

export const wrapRootElement = (props) => (
	<CookieProvider>
		{props.element}
	</CookieProvider>
);
/* eslint-enable */
