import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import IconIgamingFraudman from './inline/dating-fraud-man.svg';
import './fraud.styl';

export default function IgamingFraud() {
	const {igamingFraudJson} = useStaticQuery(graphql`
		query {
			igamingFraudJson {
				title
				subtitle
				items {
					id
					item
				}
			}
		}
	`);

	return (
		<div className="fraud__wrapper fraud__wrapper_dark fraud__wrapper_with-palm">
			<Container>
				<section className="fraud fraud_igaming">
					<figure className="fraud__image">
						<IconIgamingFraudman className="fraud__icon" />
					</figure>
					<div className="fraud__content fraud__content_wide">
						<div className="tooltip tooltip_without-background">
							<Heading level="title-medium" color="blue">
								{igamingFraudJson.title}
							</Heading>
							<p className="tooltip__subtext">{igamingFraudJson.subtitle}</p>
						</div>
						<ul className="fraud-list">
							{igamingFraudJson.items.map(({id, item}) => (
								<li className="fraud-list__item fraud-list__item_light" key={id}>
									{item}
								</li>
							))}
						</ul>
					</div>
				</section>
			</Container>
		</div>
	);
}
