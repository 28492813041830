import * as URL from 'constants/url.constants';
import Casino from '../inline/casino/casino-01.svg';
import Intro from '../inline/intro/intro-01.svg';
import Paytio from '../inline/paytio/paytio-01.svg';
import Villain from '../inline/villain/villain-01.svg';
import Zamazon from '../inline/zamazon/zamazon-01.svg';

export const slides = [
	{
		id: 1,
		name: 'INTRO',
		href: URL.COMICS_INTRO,
		date: '10 Dec 2018',
		component: Intro,
	},
	{
		id: 2,
		name: 'King’s Casino',
		href: URL.COMICS_CASINO,
		date: '18 March 2019',
		component: Casino,
	},
	{
		id: 3,
		name: 'Once upon a time in Zamazon',
		href: URL.COMICS_ZAMAZON,
		date: '03 September 2019',
		component: Zamazon,
	},
	{
		id: 4,
		name: 'Saving Paytio',
		href: URL.COMICS_PAYTIO,
		date: '14 March 2020',
		component: Paytio,
	},
	{
		id: 5,
		name: 'Villain Edition',
		href: URL.COMICS_VILLAIN,
		date: '20 July 2020',
		component: Villain,
	},
];
