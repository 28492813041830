import React, {PureComponent} from 'react';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';

export default class CaseStudyCard extends PureComponent {
	render() {
		const {item} = this.props;
		return (
			<span className={`case-study-box case-study-box_${item.modifier}`} key={item.id}>
				<Heading
					level="title-huge"
					color="white"
					tag="span"
					className={cx('case-study-box__title', {
						'case-study-box__title_without-margin': !item.text,
					})}
				>
					{item.title}
				</Heading>
				{item.text && <span>{item.text}</span>}
			</span>
		);
	}
}
