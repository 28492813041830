import React from 'react';
import {graphql} from 'gatsby';
import Seo from 'modules/seo/containers/SeoContainer';
import Box from 'ui/box/Box';
import {BaseLayout} from 'modules/layouts/components/Base';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import Showcase from 'modules/solutions/components/Showcase';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Cards from 'modules/cards/components/Cards';
import Pricing from 'modules/pricing/components/Pricing';
import Features from 'modules/features/components/Features';
import HowItWorks from 'components/HowItWorks/HowItWorks';
import {Form} from 'modules/forms';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import {PageKyc} from 'modules/layouts/components/PageKyc';
import IconKyc from 'modules/cards/inline/card-kyc.svg';
import IconShowcase from 'modules/solutions/inline/kyc-tablet.svg';

export default function Kyc({data, location}) {
	const type = 'kyc';
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo
					title={data?.kycSeoJson.title}
					description={data?.kycSeoJson.description}
				/>
			</ErrorBoundary>
			<PageKyc>
				<ErrorBoundary>
					<ScrollToTop />
				</ErrorBoundary>
				<IntroWrapper {...data?.kycIntroJson} />
				<ErrorBoundary>
					<Features {...data?.features} />
				</ErrorBoundary>
				<ErrorBoundary>
					<HowItWorks steps={data?.kycHowItWorksJson} />
				</ErrorBoundary>
				<ErrorBoundary>
					<Cards {...data?.kycCardsJson} type={type}>
						<IconKyc />
					</Cards>
				</ErrorBoundary>
				<Box>
					<ErrorBoundary>
						<Showcase {...data?.showcase}>
							<IconShowcase />
						</Showcase>
					</ErrorBoundary>
				</Box>
				<Box>
					<ErrorBoundary>
						<Pricing {...data?.kycPricingJson} />
					</ErrorBoundary>
					<ErrorBoundary>
						<Form path={location.pathname} />
					</ErrorBoundary>
				</Box>
			</PageKyc>
		</BaseLayout>
	);
}

export const kycDetails = graphql`
	query {
		kycSeoJson {
			title
			description
		}
		kycIntroJson {
			title
			subtitle {
				text
				color
			}
			link
			linkTo
			linkAppearance
			isCentered
		}
		features: kycFeaturesJson {
			title
			subtitle
			items {
				id
				image {
					source {
						publicURL
					}
					alt
				}
				title
				description
			}
		}
		kycHowItWorksJson {
			title
			list {
				id
				description
				image {
					source {
						publicURL
					}
					alt
				}
				isWide
			}
			link {
				text
				to
				appearance
			}
		}
		kycCardsJson {
			title
			subtitle
			items {
				title
				description
				id
			}
		}
		showcase: kycShowcaseAutomationJson {
			title
			subtitle
			marksColor
			withTopHeading
			items {
				id
				text
			}
		}
		kycPricingJson {
			title
			subtitle
		}
	}
`;
