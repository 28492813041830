import React from 'react';
import Select from 'react-select';
import HelpBlock from './HelpBlock';
import Label from './Label';
import './FieldSelect.styl';

export const customStyles = {
	control: (base, state) => ({
		...base,
		background: 'transparent',
		borderColor: state.isFocused
			? '#c7c7c7'
			: '#ddd',
		boxShadow: 'none',
		':hover': {
			borderColor: '#ddd',
		},
		minHeight: '46px',
	}),
	indicatorSeparator: () => ({
		background: 'none',
	}),
	input: (base) => ({
		...base,
		color: '#092238',
		fontSize: '14',
	}),
	option: (base) => ({
		...base,
		color: '#092238',
		fontSize: '14',
	}),
};

export const theme = (theme) => ({
	...theme,
	colors: {
		...theme.colors,
		primary: '#c7c7c7',
		primary25: '#018aed',
		primary50: '#c7c7c7',
	},
});

export default function FieldSelect(props) {
	const {
		form,
		field,
		form: { errors, touched },
		options,
		label,
		placeholder,
		...rest
	} = props;

	return (
		<>
			<Label htmlFor={field.name}>
				{label}
			</Label>
			<Select
				{...field}
				{...rest}
				id={field.name}
				placeholder={placeholder || ''}
				options={options}
				styles={customStyles}
				value={options ? options.find((option) => option.value === field.value) : ''}
				onChange={(option) => {
					form.setFieldValue(field.name, option.value);
				}}
				onBlur={() => {
					form.setFieldTouched(field.name, true);
				}}
				theme={theme}
			/>
			{touched[field.name] && errors[field.name] && (
				<HelpBlock>{errors[field.name]}</HelpBlock>
			)}
		</>
	);
}
