import React from 'react';
import './PageKyc.styl';

export function PageKyc({children}) {
	return (
		<div className="page-kyc">
			{children}
		</div>
	);
}
