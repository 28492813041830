import React from 'react';
import './DarkBlue.styl';

export default function DarkBlue({children}) {
	return (
		<section className="box box_very-dark-blue">
			{children}
		</section>
	);
}
