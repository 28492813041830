import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import cx from 'classnames';
import './FeaturesList.styl';

export default function Features(props) {
	const {
		link,
		items,
		marksColor,
		isInversed,
	} = props;
	return (
		<ul
			className={
				cx(
					'features-list',
					{'features-list_indented': link},
				)
			}
		>
			{items.map(({id, image, title, description}) => (
				<li className="features-list__item" key={id}>
					{image && (
						<img
							src={image.source.publicURL}
							className="features-list__icon"
							alt={image.alt}
						/>
					)}
					<Heading
						level="title-small"
						color={isInversed ? 'white' : ''}
						className={cx('features-list__title', {
							[`features-list__title_with-marks
								features-list__title_with-marks_${marksColor}`]: marksColor,
						})}
					>
						{title}
					</Heading>
					<p
						className={cx('features-list__text', {
							'features-list__text_inversed': isInversed,
						})}
					>
						{description}
					</p>
				</li>
			))}
		</ul>
	);
}
