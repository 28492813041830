import React from 'react';
import cx from 'classnames';
import {Container} from 'ui/container/Container';
import ComicsFooter from './ComicsFooter';
import './Comics.styl';

export default function Comics(props) {
	const {
		pages,
		pdf,
		children,
		type,
	} = props;
	return (
		<main className="comics-page">
			<Container>
				<article>
					<section
						className={cx('comics-page__section', {
							'comics-page__section_twenty': type === 'twenty',
							'comics-page__section_fourteen': type === 'fourteen',
							'comics-page__section_fifteen': type === 'fifteen',
						})}
					>
						{pages.map((item) => (
							<item.page key={item.id} className="comics-page__image" />
						))}
						{children}
					</section>
					<section className="comics-page__section">
						<ComicsFooter pdf={pdf} />
					</section>
				</article>
			</Container>
		</main>
	);
}
