import React, {Component} from 'react';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import PageUp from './PageUp';
import './Compact.styl';

export default class Compact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageUpActive: false,
			pageEnd: false,
			scrollTop: 0,
		};
	}

	handleClick = () => {
		this.setState({scrollTop: 0});
	}

	handlePageEnd = (isAttached) => {
		this.setState({
			pageEnd: isAttached,
		});
	}

	handleScroll = (container) => {
		this.setState(() => ({
			pageUpActive: container.scrollTop > 15,
			scrollTop: container.scrollTop,
		}));
	}

	render() {
		const {children} = this.props;
		const {pageEnd, pageUpActive} = this.state;
		let pageUpButton = null;
		if (pageUpActive) {
			pageUpButton = (
				<PageUp
					className={`page-up ml-auto ${pageEnd ? 'page-end' : 'page-up_fixed'}`}
					onClick={this.handleClick}
				/>
			);
		}
		const extraProps = {
			scrollTop: this.state.scrollTop,
			onScroll: this.handleScroll,
			onPageEnd: this.handlePageEnd,
			pageUpButton,
		};
		return (
			<ErrorBoundary>
				{React.cloneElement(children, extraProps)}
			</ErrorBoundary>
		);
	}
}
