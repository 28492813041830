import * as URL from 'constants/url.constants';
import villainPdf from '../pdf/villain-comics.pdf';
import Villain01 from '../inline/villain/villain-01.svg';
import Villain02 from '../inline/villain/villain-02.svg';
import Villain03 from '../inline/villain/villain-03.svg';
import Villain04 from '../inline/villain/villain-04.svg';
import Villain05 from '../inline/villain/villain-05.svg';
import Villain06 from '../inline/villain/villain-06.svg';
import Villain07 from '../inline/villain/villain-07.svg';
import Villain08 from '../inline/villain/villain-08.svg';
import Villain09 from '../inline/villain/villain-09.svg';
import Villain10 from '../inline/villain/villain-10.svg';
import Villain11 from '../inline/villain/villain-11.svg';
import VillainQr from '../inline/villain/villain-qr.svg';

export const pages = [
	{id: 1, page: Villain01},
	{id: 2, page: Villain02},
	{id: 3, page: Villain03},
	{id: 4, page: Villain04},
	{id: 5, page: Villain05},
	{id: 6, page: Villain06},
	{id: 7, page: Villain07},
	{id: 8, page: Villain08},
	{id: 9, page: Villain09},
	{id: 10, page: Villain10},
	{id: 11, page: Villain11},
];

export const introContent = {
	title: 'Villain Edition',
	subtitle: {
		text: 'Frodus got tired of his villainous loneliness...Thus, he is working his angle to gather a team of '
			+ 'pro fraudsters who will help him takeover the world.',
		color: 'blue',
	},
	isCentered: true,
};

export const contact = {
	qr: VillainQr,
	url: URL.HOME,
	type: 'villain',
	texts: [
		{id: 0, text: 'Prevent Fraud and get the highest conversion from the very start.'},
		{id: 1, text: 'Spot all types of fraud, protect business from chargebacks, exclude risk liability with our complex risk assessment platform.'},
	],
};

export const villainPreview = {
	id: 5,
	date: '20 July 2020',
	title: 'Villain Edition',
	texts: [
		{id: 0, content: 'Frodus got tired of his villainous loneliness... Thus, he is working his angle to gather a team of pro fraudsters who will help him take over the world.'},
		{id: 1, content: 'Who are they, these new faces?'},
	],
	link: {
		url: URL.COMICS_VILLAIN,
		text: 'Read now',
	},
	pdf: villainPdf,
	comics: Villain01,
};
