import React from 'react';
import Seo from 'modules/seo/containers/SeoContainer';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {BaseLayout} from 'modules/layouts/components/Base';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import FormContact from 'modules/forms/containers/FormContact';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

export default function Contact({location}) {
	return (
		<BaseLayout>
			<ErrorBoundary>
				<Seo title="Contact us" />
			</ErrorBoundary>
			<IntroWrapper isReversed isWide>
				<ErrorBoundary>
					<ScrollToTop />
				</ErrorBoundary>
				<ErrorBoundary>
					<FormContact path={location.pathname} />
				</ErrorBoundary>
			</IntroWrapper>
		</BaseLayout>
	);
}
