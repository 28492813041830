import {createValidator} from '../rules/createValidator';
import {required} from '../rules/required';
import {email} from '../rules/email';
import {name} from '../rules/name';
import {phone} from '../rules/phone';
import {website} from '../rules/website';
import {message} from '../rules/message';
import {max} from '../rules/max';

export const validator = createValidator({
	email: [required, email],
	name: [required, name],
	phone: [required, phone],
	website: [required, website],
	message: [required, message],
	promoCode: [max(15)],
	'g-recaptcha-response': [required],
});
