import React from 'react';
import Tooltip from './Tooltip';
import LinkFinder from './LinkFinder';
import 'modules/layouts/components/Pricing.styl';

export default function FeaturesTitles(props) {
	const {title} = props;
	return (
		<>
			<div className="pricing-page-prices__box">
				<div className="pricing-page-prices__inner">
					<p className="pricing-page-prices__title">
						<LinkFinder options={title} />
					</p>
					{title.titleDescription
						&& <Tooltip description={title.titleDescription} />
					}
				</div>
				{title.subtitles
					&& <ul className="pricing-page-features-list">
						{title.subtitles && title.subtitles.map((item) => (
							<li className="pricing-page-features-list__item" key={item.id}>
								<p className="pricing-page-features-list__text">{item.subtitle}</p>
								{item.subtitleDescription
									&& <Tooltip description={item.subtitleDescription} />
								}
							</li>
						))}
        </ul>
				}
			</div>
		</>
	);
}
