import React from 'react';
import Heading from 'ui/typography/Heading/Heading';
import Link from 'ui/controls/Link/Link';
import IconDownload from '../inline/common/download.svg';
import './Preview.styl';

export default function Preview(props) {
	const {
		id,
		date,
		title,
		texts,
		link,
		pdf,
		comics: PreviewComics,
	} = props;
	return (
		<article className="active-comics">
			<PreviewComics className="active-comics__image" />
			<div className="active-comics__content">
				<p className="active-comics__name">{title}</p>
				<Heading level="title-medium">
					{title}
				</Heading>
				<p className="comics-listing__date"><span>#{id}</span> | <span>{date}</span></p>
				{texts.map((item) => (
					<p key={item.id} className="active-comics__text">
						{item.content}
					</p>
				))}
				<div className="active-comics-container">
					<Link to={link.url} appearance="button-primary">
						{link.text}
					</Link>
					<Link to={pdf} className="active-download-link" isExternal download>
						<IconDownload />
					</Link>
				</div>
			</div>
		</article>
	);
}
