import React, {
	Children,
	useState,
	useCallback,
} from 'react';
import cx from 'classnames';
import AccordionItem from './AccordionItem';
import './Accordion.styl';

export default function Accordion({children, modificator}) {
	const [activeItem, setActiveItem] = useState(undefined);

	const toggleExpand = useCallback((itemId) => {
		setActiveItem(activeItem === itemId ? undefined : itemId);
	}, [activeItem]);

	return (
		<div
			className={cx('accordion', {
				[`accordion_${modificator}`]: modificator,
			})}
		>
			{Children.map(children, (child, index) => {
				const {key} = child;
				const [header, content] = child.props.children;
				const itemId = key || index;

				return (
					<AccordionItem
						onClick={toggleExpand}
						itemId={itemId}
						isActiveItem={activeItem === itemId}
						modificator={modificator}
					>
						{header}
						{content}
					</AccordionItem>
				);
			})}
		</div>
	);
}
