import React from 'react';
import './FieldCheckbox.styl';

export default function FieldCheckboxCustom(props) {
	const {
		field,
		label,
		type,
		onChange,
		checked,
	} = props;

	return (
		<>
			<input
				id={field.name}
				className="field-checkbox"
				type={type}
				{...field}
				onChange={onChange}
				checked={checked}
			/>
			<label className="field-checkbox-content" htmlFor={field.name}>{label}</label>
		</>
	);
}
