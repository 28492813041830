import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import ErrorBoundary from 'modules/boundary/components/ErrorBoundary';
import {HeaderWrapper} from 'modules/header/components/HeaderWrapper';
import {Footer} from 'modules/footer/components/Footer';
import {Page} from 'modules/layouts/components/Page';
import {PopupCookie} from 'modules/cookies/containers/PopupCookie';

export function BaseLayout({children, withoutCookie}) {
	const {headerMenu, footerMenu, checkboxes, list} = useStaticQuery(graphql`
		query {
			headerMenu: headerMenuJson {
				homeLink
				links {
					id
					to
					text
					responsive {
						desktop {
							appearance
						}
						mobile {
							appearance
						}
					}
				}
				headerMenuItems {
					id
					title
					children {
						id
						title
						isExternal
						isBlank
						url
					}
				}
			}
			footerMenu: footerMenuJson {
				homeLink
				link {
					to
					text
					appearance
					}
				socialLinks {
					id
					to
					image {
						source {
							publicURL
						}
						alt
					}
				}
				menuItems {
					id
					title
					children {
						id
						title
						isExternal
						isBlank
						url
					}
				}
			}
			checkboxes: cookieCheckboxesListJson {
				checkboxes {
					id
					name
					value
					isDisabled
					isChecked
				}
				text
				link {
					to
					text
				}
			}
			list: cookieListJson {
				list {
					id
					text
				}
				listSecond {
					id
					text
				}
			}
		}
	`);

	return (
		<Page>
			<ErrorBoundary>
				<HeaderWrapper menu={headerMenu} />
			</ErrorBoundary>
			{children}
			<ErrorBoundary>
				<Footer menu={footerMenu} />
			</ErrorBoundary>
			{!withoutCookie && (
				<ErrorBoundary>
					<PopupCookie checkboxes={checkboxes} list={list} />
				</ErrorBoundary>
			)}
		</Page>
	);
}
