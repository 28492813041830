export const options = [
	{ value: 1, label: 'Airlines' },
	{ value: 2, label: 'Charity' },
	{ value: 3, label: 'Crowdfunding' },
	{ value: 4, label: 'Dating' },
	{ value: 5, label: 'Digital' },
	{ value: 6, label: 'Education' },
	{ value: 7, label: 'Finance' },
	{ value: 8, label: 'Food' },
	{ value: 9, label: 'Gambling' },
	{ value: 10, label: 'Gaming' },
	{ value: 11, label: 'Hospitality' },
	{ value: 12, label: 'Insurance' },
	{ value: 13, label: 'Luxury' },
	{ value: 14, label: 'Manufacturing' },
	{ value: 15, label: 'Nutra' },
	{ value: 16, label: 'Other' },
	{ value: 17, label: 'Payment aggregators' },
	{ value: 18, label: 'Pharma' },
	{ value: 19, label: 'Retail' },
	{ value: 20, label: 'Telecommunications' },
	{ value: 21, label: 'Ticketing' },
	{ value: 22, label: 'Transportation' },
	{ value: 23, label: 'Travel' },
	{ value: 24, label: 'Utilities' },
];
