import React from 'react';
import './Label.styl';

function Label({children, ...rest}) {
	return (
		<label className="form-label" {...rest}>
			{children}
		</label>
	);
}

export default Label;
