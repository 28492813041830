import React from 'react';
import Select from 'react-select';
import {
	customStyles,
	theme,
} from '../Fields/FieldSelect';
import './ResponseSelect.styl';

export default function ResponseSelect(props) {
	const {
		options,
		handleChange,
		value,
	} = props;

	return (
		<Select
			className="response-trustchain__select"
			placeholder={options[0].label}
			isSearchable={false}
			options={options}
			styles={customStyles}
			onChange={handleChange}
			value={value}
			theme={theme}
		/>
	);
}
