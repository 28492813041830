import React from 'react';
import {Field} from 'formik';
import cx from 'classnames';
import Button from 'ui/controls/Button/Button';
import {LazyLoadRecaptcha} from './LazyLoadRecaptcha';
import FormGroup from './Fields/FormGroup';
import FieldCaptcha from './Fields/FieldCaptcha';
import FieldText from './Fields/FieldText';
import {recaptchaConfig} from '../constants/recaptcha';
import './FormIpScreening.styl';

export default function FormIpScreening(props) {
	const {
		handleSubmit,
		isSubmitting,
		isValid,
		isValidating,
		dirty,
	} = props;

	return (
		<form onSubmit={handleSubmit} className="form-ip-screening-container">
			<FormGroup appearance="ip-screening">
				<Field
					type="text"
					name="itemValue"
					component={FieldText}
					label="Enter IP address"
				/>
			</FormGroup>
			<FormGroup appearance="captcha-ip-screening">
				<LazyLoadRecaptcha shouldUsePlaceholder>
					<Field
						name="reCaptcha"
						component={FieldCaptcha}
						config={recaptchaConfig}
					/>
				</LazyLoadRecaptcha>
			</FormGroup>
			<FormGroup appearance="ip-screening-button">
				<Button
					type="submit"
					className={cx({'has-loading': isSubmitting})}
					isDisabled={isSubmitting || isValidating || !isValid || !dirty}
					isSmall
				>
					Validate an IP address
				</Button>
			</FormGroup>
		</form>
	);
}
