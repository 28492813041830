import React, {memo} from 'react';
import {Container} from 'ui/container/Container';
import Link from 'ui/controls/Link/Link';
import Button from 'ui/controls/Button/Button';
import Box from 'ui/box/Box';
import {handlerPagination} from './helpers/base';
import {useSlider} from './hooks';
import IconArrowLeft from './inline/arrow-left.svg';
import IconArrowRight from './inline/arrow-right.svg';
import './Slider.styl';
import './SliderComics.styl';

const parentClasses = ['slider-pagination', 'slider-pagination_comics'];
const itemClasses = ['slider-pagination-item', 'slider-pagination-item_comics'];

function SliderComics({slides}) {
	const options = {
		selector: '.siema-comics',
		loop: true,
		perPage: {
			'1024': 3,
		},
		onChange: handlerPagination(itemClasses[0]),
	};
	const {
		onNext,
		onPrev,
	} = useSlider(options, [parentClasses, itemClasses]);
	return (
		<Box modificator="lighten-grey">
			<Container>
				<div className="comics-listing__inner">
					<Button
						type="button"
						className="comics-listing-button comics-listing-button_prev"
						onClick={onPrev}
					>
						<IconArrowLeft className="comics-listing__icon" />
					</Button>
					<Button
						type="button"
						className="comics-listing-button comics-listing-button_next"
						onClick={onNext}
					>
						<IconArrowRight className="comics-listing__icon" />
					</Button>
					<div className="siema-comics comics-listing__slider">
						{slides.map((slide) => (
							<div className="comics-listing__box" key={slide.id}>
								<Link to={slide.href} className="comics-listing__link">
									<slide.component className="comics-listing__image" height="440px" />
								</Link>
								<p className="comics-listing__name">{slide.name}</p>
								<p className="comics-listing__date">
									<span>#{slide.id}</span> | <span>{slide.date}</span>
								</p>
							</div>
						))}
					</div>
				</div>
			</Container>
		</Box>
	);
}

export default memo(SliderComics);
