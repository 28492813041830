import React from 'react';
import cx from 'classnames';
import './Container.styl';

export function Container(props) {
	const {className} = props;

	return (
		<div className={cx('container', className)}>
			{props.children}
		</div>
	);
}
