import React from 'react';
import {IntroWrapper} from 'modules/intro/components/IntroWrapper';
import Link from 'ui/controls/Link/Link';
import {Container} from 'ui/container/Container';
import Heading from 'ui/typography/Heading/Heading';
import Box from 'ui/box/Box';
import Slider from 'ui/slider/SliderComics';
import Preview from './Preview';
import './Main.styl';

export default function MainComics(props) {
	const {
		intro,
		preview,
		slides,
		url,
	} = props;
	return (
		<>
			<IntroWrapper {...intro} />
			<Container>
				<Preview {...preview} />
			</Container>
			<Slider type="comics" slides={slides} />
			<Box>
				<Container className="comics-main-contact">
					<Heading level="title-medium">
						Learn how to stop fraud now
					</Heading>
					<Link to={url} appearance="button-primary" alignCenter>
						Contact us
					</Link>
				</Container>
			</Box>
		</>
	);
}
